import styled from 'styled-components';

import LineWork from 'svgs/questions/eye/lineWork.svg';
import media from 'utils/styles/mediaQueries';
import {
  mobileQuestionStyles,
  splitQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';
import theme from 'utils/styles/theme';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import FadeInOut from '1_components/FadeInOut/FadeInOut';
import Typography from '1_components/Typography/Typography';

export const EyeQuestionWrapper = styled(QuestionWrapper)<{ $height: number }>`
  position: relative;
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  background: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.black};
`;

export const EyeQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles}
`;

export const EyeQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const InteractionWrapper = styled.div`
  position: absolute;
  inset: 0;

  display: grid;
  place-items: center;

  ${media.desktop} {
    inset: 0 0 0 50vw;
  }
`;

export const Inner = styled.div`
  position: absolute;
  width: 100%;

  display: grid;
  place-items: center;
  height: fit-content;
`;

export const SvgLineWork = styled(LineWork)`
  position: absolute;

  height: 150vh;

  ${media.desktop} {
    transform: translateY(-10vh);
  }
`;

export const RingWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;

  max-width: min(75%, 40vh);

  ${media.tablet} {
    max-width: min(63%, 40vh);
  }

  ${media.desktop} {
    max-width: min(65%, 60vh);
    transform: translateY(-10vh);
  }
`;

export const EyeWrapper = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;

  transform: translateY(-5%) scale(0.9);
`;

export const AnswerWrapper = styled(FadeInOut)`
  position: fixed;
  height: min-content;
  width: 100%;

  left: 0;
  bottom: ${({ theme }) =>
    `calc(${theme.sharedValues.mobileQuizSubmitBottomOffset}px + 1.6rem)`};

  ${media.tablet} {
    bottom: ${({ theme }) =>
      `calc(${theme.sharedValues.mobileQuizSubmitBottomOffset}px + 5.6rem)`};
  }

  ${media.desktop} {
    position: absolute;
    bottom: 0;
    transform: translateY(140%);
  }
`;

export const AnswerText = styled(Typography)`
  line-height: 110%;
  font-size: min(3.4rem, 5vh);
  color: ${theme.colors.black};
  z-index: ${zIndex('overlay')};

  ${media.desktop} {
    font-size: min(4.4rem, 7vh);
  }
`;

export const EyeBackground = styled(FadeInOut)`
  position: absolute;
  inset: 0;

  display: grid;
  place-items: center;

  transform: scale(0.85);

  svg {
    width: 100%;
  }
`;
