import { motion } from 'framer-motion';
import styled from 'styled-components';

import Head from 'svgs/questions/multiColorHead/head.svg';
import media from 'utils/styles/mediaQueries';
import { mobileQuestionStyles, splitQuestionStyles } from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import RotationWheel from '1_components/RotationWheel/RotationWheel';
import Typography from '1_components/Typography/Typography';

export const MulticolorHeadQuestionWrapper = styled(QuestionWrapper)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
`;

export const MulticolorHeadQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles}
`;

export const MulticolorHeadQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const InteractionWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;

  padding: 8vw;

  transform: translateY(-5vh);

  ${media.tablet} {
    transform: unset;
  }

  ${media.desktop} {
    inset: 0 0 0 50vw;

    padding: 12vw;
  }
`;

export const AnswerWrapper = styled(motion.div)`
  position: absolute;
`;

export const Answer = styled(Typography)`
  position: relative;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.2;

  ${media.desktop} {
    font-size: 3.2rem;
  }
`;

export const HeadWrapper = styled(motion.div)`
  display: grid;
  place-items: center;
  position: absolute;

  height: 100%;
  width: 100%;
  mix-blend-mode: multiply;
`;

export const SvgHead = styled(Head)`
  position: absolute;
  bottom: 0;
  height: 60vh;

  ${media.desktop} {
    height: 65vh;
  }
`;

export const RotationWheelComp = styled(RotationWheel)`
  max-width: 350px;

  ${media.tablet} {
    max-width: 450px;
  }

  ${media.desktop} {
    max-width: initial;
  }
`;

export const IconsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;

  height: 100%;
  width: 100%;

  svg {
    position: absolute;

    &:first-child {
      top: -20%;
      transform: translateY(-100%);
    }
    &:last-child {
      bottom: -20%;
      transform: scale(-1) translateY(-100%);
    }
  }
`;
