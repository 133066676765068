import { Personalities } from '../utils/quizAnswer/personality';

type PersonalityImagesTypes = {
  [K in Personalities]?: {
    [K in Personalities]?: {
      [K in Personalities]?: string;
    };
  };
};

export const PERSONALITY_IMAGES: PersonalityImagesTypes = {
  reliable: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0000_Reliably-Curious-Adventurer-min.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0001_Reliably-Curious-Creator-min.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0003_Reliably-Curious-Daydreamer-min.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0004_Reliably-Curious-Go-Getter-min.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0005_Reliably-Curious-People-Person-min.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0002_Reliably-Curious-thrill-seeker-min.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0006_Reliably-Fun-_-Adventurer-min.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0007_Reliably-Fun-Creator-min.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0009_Reliably-Fun-Daydreamer-min.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0010_Reliably-Fun-Go-Getter-min.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0011_Reliably-Fun-People-Person-min.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0008_Reliably-Fun-thrill-seeker-min.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0012_Reliably-Chill-_-Adventurer-min.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0013_Reliably-Chill-Creator-min.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0015_Reliably-Chill-Daydreamer-min.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0016_Reliably-Chill-Go-Getter-min.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0017_Reliably-Chill-People-Person-min.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0014_Reliably-Chill-thrill-seeker-min.png',
    },
  },
  wild: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0018_Wildly-Curious--Adventurer-min.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0019_Wildly-Curious--Creator-min.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0021_Wildly-Curious--Daydreamer-min.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0022_Wildly-Curious--Go-Getter-min.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0023_Wildly-Curious--People-Person-min.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0020_Wildly-Curious--thrill-seeker-min.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0024_Wildly-Fun-Adventurer-min.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0025_Wildly-Fun-Creator-min.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0027_Wildly-Fun-Daydreamer-min.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0028_Wildly-Fun-Go-Getter-min.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0029_Wildly-Fun-People-Person-min.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0026_Wildly-Fun-thrill-seeker-min.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0030_Wildly-Chill--Adventurer-min.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0031_Wildly-Chill--Creator-min.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0033_Wildly-Chill--Daydreamer-min.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0034_Wildly-Chill--Go-Getter-min.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0035_Wildly-Chill--People-Person-min.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0032_Wildly-Chill--thrill-seeker-min.png',
    },
  },
  unique: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0036_Uniquely-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0037_Uniquely-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0039_Uniquely-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0040_Uniquely-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0041_Uniquely-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0038_Uniquely-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0042_Uniquely-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0043_Uniquely-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0045_Uniquely-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0046_Uniquely-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0047_Uniquely-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0044_Uniquely-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0048_Uniquely-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0049_Uniquely-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0051_Uniquely-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0052_Uniquely-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0053_Uniquely-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/desktop/Booksonas-FINAL_9x16-Web_0050_Uniquely-Chill-thrill-seeker.png',
    },
  },
};

export const PERSONALITY_IMAGES_MOBILE: PersonalityImagesTypes = {
  reliable: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0000_Reliably-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0001_Reliably-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0003_Reliably-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0004_Reliably-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0005_Reliably-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0002_Reliably-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0006_Reliably-Fun-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0007_Reliably-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0009_Reliably-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0010_Reliably-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0011_Reliably-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0008_Reliably-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0012_Reliably-Chill-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0013_Reliably-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0015_Reliably-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0016_Reliably-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0017_Reliably-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0014_Reliably-Chill-thrill-seeker.png',
    },
  },
  wild: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0018_Wildly-Curious+-+Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0019_Wildly-Curious+-+Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0021_Wildly-Curious+-+Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0022_Wildly-Curious+-+Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0023_Wildly-Curious+-+People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0020_Wildly-Curious+-+thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0024_Wildly-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0025_Wildly-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0027_Wildly-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0028_Wildly-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0029_Wildly-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0026_Wildly-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0030_Wildly-Chill+-+Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0031_Wildly-Chill+-+Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0033_Wildly-Chill+-+Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0034_Wildly-Chill+-+Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0035_Wildly-Chill+-+People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0032_Wildly-Chill+-+thrill-seeker.png',
    },
  },
  unique: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0036_Uniquely-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0037_Uniquely-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0039_Uniquely-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0040_Uniquely-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0041_Uniquely-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0038_Uniquely-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0042_Uniquely-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0043_Uniquely-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0045_Uniquely-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0046_Uniquely-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0047_Uniquely-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0044_Uniquely-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0048_Uniquely-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0049_Uniquely-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0051_Uniquely-Chill-Dreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0052_Uniquely-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0053_Uniquely-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/booksona/mobile/Booksona_Profiles_9x16_0050_Uniquely-Chill-thrill-seeker.png',
    },
  },
};
