export const LeavesQuestionVariants = {
  leavesWrapper: [
    { initial: { color: '#6AC8C2' } },
    {
      initial: { opacity: 0, color: '#F285B5', rotate: 0 },
      animate: { opacity: 1, rotate: [null, -22.5] },
    },
    {
      initial: {
        opacity: 0,
        color: '#F8B836',
        rotate: [null, -22.5],
      },
      animate: { opacity: 1, rotate: [null, -45] },
    },
  ],
};
