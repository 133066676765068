import { motion } from 'framer-motion';
import styled from 'styled-components';

import { zIndex } from 'utils/styles/mixins';

import {
  PreloaderVariant,
  preloaderVariantSettings,
} from './PreloaderOverlay.types';

import NoiseLayer from '1_components/NoiseLayer/NoiseLayer';

export const PreloaderOverlayWrapper = styled(motion.div)<{
  $variant: PreloaderVariant;
}>`
  position: fixed;
  inset: 0;

  z-index: ${zIndex('absoluteTop') - 1};

  background: ${({ $variant }) =>
    preloaderVariantSettings[$variant].background};
  width: 100vw;
  height: 100vh;
`;

export const InnerWrapper = styled.div<{ $height: number }>`
  position: relative;
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : '100vh')};
`;

export const Noise = styled(NoiseLayer)``;

export const AnimationWrapper = styled.div`
  display: grid;
  place-items: center;

  width: 100%;
  height: 100%;

  svg {
    max-width: 80px;
  }
`;
