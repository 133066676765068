import { motion } from 'framer-motion';
import styled from 'styled-components';

import Ring from 'svgs/questions/rotationWheel/ring.svg';
import media from 'utils/styles/mediaQueries';

import { RotationWheelColorVariant } from './RotationWheel';

export const RotationWheelWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;

export const RotationWheelRingWrapper = styled.div<{
  $ringColorVariant: RotationWheelColorVariant;
}>`
  width: 92%;
  color: ${({ $ringColorVariant, theme }) =>
    $ringColorVariant === 'light'
      ? theme.colors.white
      : theme.colors.lightGrey};
`;

export const SvgRotationWheelRing = styled(Ring)`
  width: 100%;
`;

export const KnobWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: none;
`;

export const Knob = styled(motion.div)`
  width: 6.2rem;
  height: 6.2rem;
  max-width: 62px;
  max-height: 62px;
  pointer-events: all;
  cursor: grab;

  ${media.desktop} {
    width: 7.2rem;
    height: 7.2rem;
    max-width: 72px;
    max-height: 72px;
  }
`;

export const DotsWrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
`;

export const Dot = styled(motion.button)<{
  $colorVariant: RotationWheelColorVariant;
}>`
  position: absolute;
  width: 4%;
  height: 4.5%;
  top: 47.75%;
  left: 48%;
  background-color: ${({ theme, $colorVariant }) =>
    $colorVariant === 'light' ? theme.colors.white : theme.colors.black};
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
`;
