import React, { FC } from 'react';

import SvgKnotShortUnderline from 'svgs/markUnderlines/knotShortUnderline.svg';
import SvgLongUnderline from 'svgs/markUnderlines/longUnderline.svg';
import SvgOvalUnderline from 'svgs/markUnderlines/ovalUnderline.svg';
import SvgScribbleUnderline from 'svgs/markUnderlines/scribbleUnderline.svg';
import SvgShortUnderline from 'svgs/markUnderlines/shortUnderline.svg';
import SvgTornadoUnderline from 'svgs/markUnderlines/tornadoUnderline.svg';
import SvgZShapeUnderline from 'svgs/markUnderlines/zShapeUnderline.svg';

import * as S from './CustomMark.styled';
import { CustomMarkVariant } from './CustomMark.types';

export interface CustomMarkProps {
  variant: CustomMarkVariant;
  color: string;
  underlineBetweenTextLines?: boolean;
}

export const CustomMark: FC<CustomMarkProps> = ({
  children,
  variant,
  color,
  underlineBetweenTextLines,
  ...rest
}) => {
  const renderMark = () => {
    switch (variant) {
      case 'long':
        return <SvgLongUnderline />;
      case 'short':
        return <SvgShortUnderline />;
      case 'scribble':
        return <SvgScribbleUnderline />;
      case 'knotShort':
        return <SvgKnotShortUnderline />;
      case 'knotLong':
        return <S.SvgKnotLongUnderline />;
      case 'tornado':
        return <SvgTornadoUnderline />;
      case 'oval':
        return <SvgOvalUnderline />;
      case 'zShape':
        return <SvgZShapeUnderline />;
      case 'circleAround':
        return <S.SvgCircleAroundUnderline />;
    }
  };

  return (
    <S.CustomMarkWrapper
      $color={color}
      $markBetweenLines={underlineBetweenTextLines}
      {...rest}
    >
      {children}
      {renderMark()}
    </S.CustomMarkWrapper>
  );
};

export default CustomMark;
