import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useEffectOnce, useWindowSize } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { leavesQuestionData } from './LeavesQuestion.data';
import { LeavesQuestionVariants } from './LeavesQuestion.motion';
import * as S from './LeavesQuestion.styled';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface LeavesQuestionProps {}

const LeavesQuestion: React.FC<LeavesQuestionProps> = () => {
  const [selectedOption, setSelectedOption] = useState(0);
  const { setAnswer } = useQuiz();
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    setAnswer({ leavesQuestion: selectedOption });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.amazonInk);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'dark', desktop: 'dark' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'rotate',
      desktop: 'rotate',
    });
  });

  const questionTitle = (
    <>
      <S.LeavesQuestionTitle
        fontFamily="amazonEmber"
        markdown={leavesQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h1"
      />
      <S.LeavesQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={leavesQuestionData.question.mobile}
        underline={{ variant: 'long', color: theme.colors.orange }}
        renderAs="h1"
      />
    </>
  );

  const leavesLayers = [...Array(3)].map((_, index) => (
    <S.LeavesWrapper
      key={`leavesWrapper ${index}`}
      variants={LeavesQuestionVariants.leavesWrapper[index]}
      initial="initial"
      animate={
        index === 0
          ? selectedOption > index
            ? 'animate'
            : 'initial'
          : selectedOption >= index
          ? 'animate'
          : 'initial'
      }
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <S.SvgLeaves />
    </S.LeavesWrapper>
  ));

  return (
    <S.LeavesQuestionWrapper $height={windowHeight}>
      <PaperRips
        primaryColor={theme.colors.orange}
        secondaryColor={theme.colors.white}
      />

      {questionTitle}

      {leavesLayers}

      <S.InteractionWrapper>
        <AnimatePresence initial={false}>
          <S.AnswerWrapper
            key={leavesQuestionData.answers[selectedOption].value}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.2, ease: 'easeInOut', delay: 0.3 },
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <S.Answer
              text={leavesQuestionData.answers[selectedOption].text}
              fontFamily="soap"
              renderAs="h2"
            />
          </S.AnswerWrapper>
        </AnimatePresence>

        <S.RotationWheelComp
          numberOfOptions={3}
          colorVariant="dark"
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </S.InteractionWrapper>
    </S.LeavesQuestionWrapper>
  );
};

LeavesQuestion.displayName = 'LeavesQuestion';

export default LeavesQuestion;
