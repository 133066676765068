import PreloaderDark from 'assets/preloader/preloaderDark.json';
import PreloaderLight from 'assets/preloader/preloaderLight.json';
import theme from 'utils/styles/theme';

export type PreloaderVariant = 'light' | 'dark';

export const preloaderVariantSettings = {
  light: {
    background: theme.colors.white,
    animationData: PreloaderDark,
  },
  dark: {
    background: theme.colors.black,
    animationData: PreloaderLight,
  },
};
