import { AnimatePresence } from 'framer-motion';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useEffectOnce, useMediaQuery, useWindowSize } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import { useLottieFiles } from 'hooks/useLottieFiles';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { gardenQuestionData } from './GardenQuestion.data';
import { layerVariants, uiVariants } from './GardenQuestion.motion';
import * as S from './GardenQuestion.styled';

import HorizontalSlider from '1_components/HorizontalSlider/HorizontalSlider';
import PaperRips from '1_components/PaperRips/PaperRips';

const BASE_BLUR_STRENGTH = 6;

const BLUR_STRENGTHS = {
  sky: [BASE_BLUR_STRENGTH * 2, BASE_BLUR_STRENGTH, 0],
  birds: [BASE_BLUR_STRENGTH, 0, BASE_BLUR_STRENGTH * 2],
  trees: [BASE_BLUR_STRENGTH, 0, BASE_BLUR_STRENGTH * 2],
  plants: [0, BASE_BLUR_STRENGTH, BASE_BLUR_STRENGTH * 2],
};

export interface GardenQuestionProps {}

const GardenQuestion: React.FC<GardenQuestionProps> = () => {
  const [currentAnswer, setCurrentAnswer] = useState(0);
  const [showUI, setShowUI] = useState(false);
  const [plantsIntroFinished, setPlantsIntroFinished] = useState(false);
  const [treesIntroFinished, setTreesIntroFinished] = useState(false);
  const [startPlantsIntro, setStartPlantsIntro] = useState(false);
  const [startTreesIntro, setStartTreesIntro] = useState(false);
  const {
    files: { garden },
  } = useLottieFiles();

  const answerSliderWrapperRef = useRef(null);

  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktop}px)`
  );

  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const { setAnswer } = useQuiz();

  useEffect(() => {
    setAnswer({ gardenQuestion: currentAnswer });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswer]);

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'light', desktop: 'light' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'dragVertical',
      desktop: 'dragHorizontal',
    });
  });

  useEffect(() => {
    if (startTreesIntro) return;

    const timeout = setTimeout(() => {
      setStartTreesIntro(true);
    }, 900);

    return () => clearTimeout(timeout);
  }, [startTreesIntro]);

  useEffect(() => {
    if (startPlantsIntro) return;

    const timeout = setTimeout(() => {
      setStartPlantsIntro(true);
    }, 1200);

    return () => clearTimeout(timeout);
  }, [startPlantsIntro]);

  useEffect(() => {
    if (showUI) return;

    const timeout = setTimeout(() => {
      setShowUI(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showUI]);

  const questionTitle = (
    <>
      <S.GardenQuestionTitle
        fontFamily="amazonEmber"
        markdown={gardenQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h1"
      />
      <S.GardenQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={gardenQuestionData.question.mobile}
        underline={{ variant: 'tornado', color: theme.colors.white }}
        renderAs="h1"
      />
    </>
  );

  const skyLayer = (
    <S.FadeInWrapper
      key={'sky-layer'}
      initial="hidden"
      animate="visible"
      variants={layerVariants}
      custom={0.3}
    >
      <S.LottieLayer
        animationData={
          windowHeight > windowWidth
            ? garden.vertical.sky.loop
            : garden.horizontal.sky.loop
        }
        play
        loop
        $blurStrength={showUI ? BLUR_STRENGTHS['sky'][currentAnswer] : 0}
        rendererSettings={{ progressiveLoad: true }}
        onLoadStart={() => alert('loading')}
      />
    </S.FadeInWrapper>
  );

  const birdsLayer = (
    <S.FadeInWrapper
      key={'birds-layer'}
      initial="hidden"
      animate={startPlantsIntro && 'visible'}
      variants={layerVariants}
      custom={0.3}
    >
      <S.LottieLayer
        animationData={
          windowHeight > windowWidth
            ? garden.vertical.birds.loop
            : garden.horizontal.birds.loop
        }
        play
        loop
        $blurStrength={showUI ? BLUR_STRENGTHS['birds'][currentAnswer] : 0}
        rendererSettings={{ progressiveLoad: true }}
      />
    </S.FadeInWrapper>
  );

  const treesLayer = (
    <Fragment key={'trees-layer'}>
      {!treesIntroFinished && (
        <S.FadeInWrapper
          initial="hidden"
          animate={startTreesIntro && 'visible'}
          variants={layerVariants}
        >
          <S.LottieLayer
            animationData={
              windowHeight > windowWidth
                ? garden.vertical.trees.intro
                : garden.horizontal.trees.intro
            }
            play={startTreesIntro}
            loop={false}
            $blurStrength={showUI ? BLUR_STRENGTHS['trees'][currentAnswer] : 0}
            onComplete={() => setTreesIntroFinished(true)}
            $hidden={!startTreesIntro}
            rendererSettings={{ progressiveLoad: true }}
            $moveUp
          />
        </S.FadeInWrapper>
      )}
      <S.LottieLayer
        animationData={
          windowHeight > windowWidth
            ? garden.vertical.trees.loop
            : garden.horizontal.trees.loop
        }
        play={treesIntroFinished}
        loop
        $blurStrength={showUI ? BLUR_STRENGTHS['trees'][currentAnswer] : 0}
        $hidden={!treesIntroFinished}
        rendererSettings={{ progressiveLoad: true }}
        $moveUp
      />
    </Fragment>
  );

  const plantsLayer = (
    <Fragment key={'plants-layer'}>
      {!plantsIntroFinished && (
        <S.FadeInWrapper
          initial="hidden"
          animate={startPlantsIntro && 'visible'}
          variants={layerVariants}
        >
          <S.LottieLayer
            animationData={
              windowHeight > windowWidth
                ? garden.vertical.plants.intro
                : garden.horizontal.plants.intro
            }
            play={startPlantsIntro}
            loop={false}
            $blurStrength={showUI ? BLUR_STRENGTHS['plants'][currentAnswer] : 0}
            onComplete={() => setPlantsIntroFinished(true)}
            $hidden={!startPlantsIntro}
            rendererSettings={{ progressiveLoad: true }}
          />
        </S.FadeInWrapper>
      )}
      <S.LottieLayer
        animationData={
          windowHeight > windowWidth
            ? garden.vertical.plants.loop
            : garden.horizontal.plants.loop
        }
        play={plantsIntroFinished}
        loop
        $blurStrength={showUI ? BLUR_STRENGTHS['plants'][currentAnswer] : 0}
        $hidden={!plantsIntroFinished}
        rendererSettings={{ progressiveLoad: true }}
      />
    </Fragment>
  );

  const answers = gardenQuestionData.answers.map(({ text, value }, index) => {
    return (
      index === currentAnswer && (
        <S.AnswerWrapper
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={uiVariants}
          key={value}
        >
          <S.AnswerText
            text={
              typeof text === 'string'
                ? text
                : isDesktop
                ? text.desktop
                : text.mobile
            }
            fontFamily="texGyreAdventor"
            renderAs="p"
          />
        </S.AnswerWrapper>
      )
    );
  });

  const answerSlider = (
    <S.AnswerSliderWrapper
      initial="hidden"
      animate="visible"
      variants={uiVariants}
      ref={answerSliderWrapperRef}
    >
      <HorizontalSlider
        direction={isDesktop ? 'x' : 'y'}
        value={currentAnswer}
        setValue={setCurrentAnswer}
        ref={answerSliderWrapperRef}
      />
    </S.AnswerSliderWrapper>
  );

  return (
    <S.GardenQuestionWrapper>
      <PaperRips
        primaryColor={theme.colors.darkBlue}
        secondaryColor={theme.colors.lightBlue}
      />

      {questionTitle}

      <S.GardenQuestionContentWrapper $height={windowHeight}>
        <S.InnerWrapper $height={windowHeight}>
          <AnimatePresence initial>
            {skyLayer}
            {birdsLayer}
            {treesLayer}
            {plantsLayer}
          </AnimatePresence>
        </S.InnerWrapper>

        <AnimatePresence initial>{showUI && answerSlider}</AnimatePresence>

        <AnimatePresence exitBeforeEnter>{showUI && answers}</AnimatePresence>
      </S.GardenQuestionContentWrapper>
    </S.GardenQuestionWrapper>
  );
};

GardenQuestion.displayName = 'GardenQuestion';

export default GardenQuestion;
