import React, { ElementType, FC } from 'react';

import { FontFamily } from './Typography.types';

import * as S from './Typography.styles';

export type TypographyProps = {
  fontFamily: FontFamily;
  renderAs: ElementType;
  text?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};
export const Typography: FC<TypographyProps> = React.forwardRef(
  ({ children, renderAs, fontFamily = 'amazonEmber', text, ...rest }, ref) => {
    return (
      <S.StyledTypography
        ref={ref}
        as={renderAs}
        $fontFamily={fontFamily}
        {...rest}
      >
        {text ? text : children}
      </S.StyledTypography>
    );
  }
);
Typography.displayName = 'Typography';
export default Typography;
