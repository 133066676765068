import styled from 'styled-components';

import RotateIcon from 'svgs/rotateDevice/rotateIcon.svg';
import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

import Typography from '1_components/Typography/Typography';

export const RotateDeviceWrapper = styled.section`
  display: none;

  @media (orientation: landscape) {
    position: fixed;
    inset: 0;
    display: grid;
    place-items: center;

    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    z-index: ${zIndex('absoluteTop')};
  }

  ${media.desktop} {
    display: none;
  }
`;

export const MessageWrapper = styled.figure`
  display: grid;
  place-items: center;
  row-gap: 8px;

  text-align: center;
`;

export const SvgRotateIcon = styled(RotateIcon)`
  color: ${({ theme }) => theme.colors.orange};
  margin: 8px;
  height: 40px;
`;

export const Title = styled(Typography)`
  font-size: 3.6rem;
`;

export const Message = styled(Typography)`
  font-size: 1.8rem;
`;
