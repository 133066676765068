import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import DottedLineHorizontal from 'svgs/answerSlider/answerSliderLineHorizontal.svg';
import DottedLineVertical from 'svgs/answerSlider/answerSliderLineVertical.svg';
import media from 'utils/styles/mediaQueries';

import KnobIcon from '1_components/KnobIcon/KnobIcon';

export const HorizontalSliderWrapper = styled(motion.div)<{
  $direction: 'x' | 'y';
}>`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: ${({ $direction }) =>
    $direction === 'x' ? 'row' : 'column'};
`;

const Box = styled(motion.div)<{
  $direction: 'x' | 'y';
}>`
  aspect-ratio: 1/1;

  ${({ $direction }) =>
    $direction === 'x'
      ? css`
          height: 100%;
          width: auto;
        `
      : css`
          height: auto;
          width: 100%;
        `}
`;

export const KnobWrapper = styled(Box)`
  display: grid;
  place-items: center;
  position: absolute;
  cursor: grab;

  ${({ $direction }) =>
    $direction === 'x'
      ? css`
          top: 0;
          left: 0;
          bottom: unset;
        `
      : css`
          top: unset;
          left: 0;
          bottom: 0;
        `}
`;

export const KnobIconComp = styled(KnobIcon)<{ $direction: 'x' | 'y' }>`
  transform: ${({ $direction }) =>
    $direction === 'y' ? 'rotate(-90deg) scale(0.8)' : 'scale(0.8)'};

  ${media.tablet} {
    transform: ${({ $direction }) =>
      $direction === 'y' ? 'rotate(-90deg)' : 'scale(0.5)'};
  }

  ${media.desktop} {
    transform: scale(0.75);
  }
`;

export const DotWrapper = styled(Box)`
  display: grid;
  place-items: center;
`;

export const Dot = styled.span`
  border-radius: 50%;
  border: 8px solid;
  border-color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  cursor: pointer;
`;

export const SvgDottedLineHorizontal = styled(DottedLineHorizontal)`
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translateY(-50%);

  width: 86%;
  height: 3px;
  pointer-events: none;
  user-select: none;
`;

export const SvgDottedLineVertical = styled(DottedLineVertical)`
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);

  height: 86%;
  width: 2px;
  pointer-events: none;
  user-select: none;
`;
