import React from 'react';
import { useEffectOnce, useWindowSize } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import theme from 'utils/styles/theme';

import { spaceshipQuestionData } from './SpaceshipQuestion.data';
import * as S from './SpaceshipQuestion.styled';
import Teleport from './Teleport/Teleport';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface SpaceshipQuestionProps {}

const SpaceshipQuestion: React.FC<SpaceshipQuestionProps> = () => {
  const { height: windowHeight } = useWindowSize();

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.white);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'light');
    setGlobalState('quizHintCardTheme', { mobile: 'dark', desktop: 'dark' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'dragHorizontal',
      desktop: 'dragHorizontal',
    });
  });

  const questionTitle = (
    <>
      <S.SpaceshipQuestionTitle
        fontFamily="amazonEmber"
        markdown={spaceshipQuestionData.question.desktop}
        underline={{ variant: 'knotShort', color: theme.colors.limeGreen }}
        renderAs="h1"
      />
      <S.SpaceshipQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={spaceshipQuestionData.question.mobile}
        underline={{ variant: 'zShape', color: theme.colors.limeGreen }}
        renderAs="h1"
      />
    </>
  );

  return (
    <S.SpaceshipQuestionWrapper $height={windowHeight}>
      <PaperRips
        primaryColor={theme.colors.darkPurple}
        secondaryColor={theme.colors.limeGreen}
      />

      <S.MobileBackgroundWrapper showFor="mobile" fullScreen>
        <S.SvgMobileBackground data-depth="0.2" />
        <S.SvgMobileGreenLines data-depth="0.6" />
      </S.MobileBackgroundWrapper>

      <S.TeleportWrapper>
        <Teleport />
      </S.TeleportWrapper>

      {questionTitle}
    </S.SpaceshipQuestionWrapper>
  );
};

SpaceshipQuestion.displayName = 'SpaceshipQuestion';

export default SpaceshipQuestion;
