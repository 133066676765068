export const keyholeAnswerVariants = {
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};
