import { useRouter } from 'next/router';
import React, { FC, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import useDisableScroll from 'hooks/useDisableScroll';
import pixels from 'utils/pixels';
import useQuiz, {
  LOCAL_STORAGE_PERSONALITIES_KEY,
} from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import GraphicResults from './GraphicResults/GraphicResults';
import * as S from './QuizResults.styled';
import RecommendedBooks from './RecommendedBooks/RecommendedBooks';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface QuizResultsProps {}

const QuizResults: FC<QuizResultsProps> = () => {
  const router = useRouter();
  const [loaded, setLoaded] = useState(false);
  const personality = (router.query.subpath as string)?.split('-');
  const [hasAccess, setHasAccess] = useState(false);
  const { resetQuiz } = useQuiz();

  useEffectOnce(() => {
    resetQuiz();
    pixels();
    setGlobalState('headerColor', theme.colors.black);
    setLoaded(true);

    const localAccessData = localStorage.getItem(
      LOCAL_STORAGE_PERSONALITIES_KEY
    );
    if (localAccessData) {
      if (
        (JSON.parse(localAccessData) as Array<string>).includes(
          personality.join('-')
        )
      ) {
        setHasAccess(true);
      } else {
        router.push('/');
      }
    } else {
      router.push('/');
    }
  });

  useDisableScroll(false);

  return (
    <S.QuizResultsWrapper>
      <S.Noise />

      {hasAccess && (
        <>
          <PaperRips primaryColor={theme.colors.amazonInk} variant="result" />

          {personality && loaded && hasAccess && (
            <S.Content>
              <GraphicResults personality={personality} />
              <RecommendedBooks personality={personality} />
            </S.Content>
          )}
        </>
      )}
    </S.QuizResultsWrapper>
  );
};
export default QuizResults;
