import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import Car from 'svgs/questions/car/car.svg';
import DottedLineHorizontal from 'svgs/questions/car/carSliderLineHorizontal.svg';
import DottedLineVertical from 'svgs/questions/car/carSliderLineVertical.svg';

export const AnswerSliderWrapper = styled(motion.div)<{
  $direction: 'x' | 'y';
}>`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: ${({ $direction }) =>
    $direction === 'x' ? 'row' : 'column'};
`;

const Rectangle = styled(motion.div)<{
  $direction: 'x' | 'y';
}>`
  ${({ $direction }) =>
    $direction === 'x'
      ? css`
          aspect-ratio: 176/84;
          height: 100%;
          width: auto;
        `
      : css`
          aspect-ratio: 84/176;
          height: auto;
          width: 100%;
        `}
`;

const Box = styled(motion.div)<{
  $direction: 'x' | 'y';
}>`
  aspect-ratio: 1/1;

  ${({ $direction }) =>
    $direction === 'x'
      ? css`
          height: 100%;
          width: auto;
        `
      : css`
          height: auto;
          width: 100%;
        `}
`;

export const KnobWrapper = styled(Rectangle)`
  position: absolute;
  cursor: grab;

  ${({ $direction }) =>
    $direction === 'x'
      ? css`
          top: 0;
          left: 0;
          bottom: unset;
        `
      : css`
          top: unset;
          left: 0;
          bottom: 0;
        `}
`;

const commonSvgStyles = css`
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
`;

export const SvgCar = styled(Car)`
  ${commonSvgStyles};
  transform: translateY(8px);
`;

export const SvgCarVertical = styled(Car)`
  ${commonSvgStyles};
  transform: translateX(15%) rotate(-90deg) scale(2.5);
`;

export const DotWrapper = styled(Rectangle)`
  display: grid;
  place-items: center;
`;

export const Dot = styled.span`
  border-radius: 50%;
  border: 8px solid;
  border-color: ${({ theme }) => theme.colors.amazonInk};
  border-radius: 50%;
  cursor: pointer;
`;

export const DottedLineWrapper = styled.div<{
  $offset: number;
  $direction: 'x' | 'y';
}>`
  position: absolute;
  pointer-events: none;
  user-select: none;

  ${({ $direction, $offset }) =>
    $direction === 'x'
      ? css`
          left: ${$offset}px;
          right: ${$offset}px;
          height: 16px;
        `
      : css`
          top: ${$offset}px;
          bottom: ${$offset}px;
          width: 16px;
        `}
`;

export const SvgDottedLineHorizontal = styled(DottedLineHorizontal)`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
`;

export const SvgDottedLineVertical = styled(DottedLineVertical)`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
`;

export const SignsWrapper = styled.div<{
  $direction: 'x' | 'y';
  $offset: number;
}>`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ $direction, $offset }) =>
    $direction === 'x'
      ? css`
          top: -120%;
          left: 0;
          flex-direction: row;
          padding: 0 ${$offset}px;
        `
      : css`
          top: 0;
          left: -300%;
          width: 200%;
          flex-direction: column-reverse;
        `};
`;

const SignWrapper = styled(Box)<{ $hidden: boolean }>`
  display: grid;
  justify-items: center;
  align-items: end;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};

  svg {
    max-height: 10vh;
    width: 100%;
    height: 100%;
  }
`;

export const StopSignWrapper = styled(SignWrapper)``;

export const SlowSignWrapper = styled(SignWrapper)`
  ${({ $direction }) =>
    $direction === 'x' &&
    css`
      svg {
        transform: scale(1.25);
        transform-origin: bottom;
      }
    `}
`;

export const FreewaySignWrapper = styled(SignWrapper)`
  svg {
    transform-origin: ${({ $direction }) =>
      $direction === 'y' ? 'right' : 'auto'};
    transform: scale(1.5);
  }
`;
