import { DefaultTheme } from 'styled-components';

/*
Type new properties here: src/types/styled.d.ts
*/

const theme: DefaultTheme = {
  colors: {
    white: '#E9ECED',
    black: '#030504',
    darkGrey: '#1F1F1F',
    lightGrey: '#6A645E',

    amazonInk: '#24323E',
    limeGreen: '#A2D653',
    midGreen: '#3BA067',
    darkGreen: '#608F7F',
    sapGreen: '#4B7318',

    red: '#FF5C5D',
    pink: '#FF97CA',
    lightPurple: '#C56DFB',
    darkPurple: '#42316F',

    orange: '#FF955B',
    yellow: '#FFD538',
    darkBlue: '#7187FE',
    lightBlue: '#9CCEFF',

    instagram: '#C13684',
    facebook: '#1877F2',
    twitter: '#1DA1F2',
  },

  sharedValues: {
    headerHeight: 64,
    mobileIndent: 20,
    mobileQuizSubmitBottomOffset: 70,
  },

  breakpoints: {
    tablet: 640,
    desktop: 1024,
    desktopLarge: 1280,
    desktopWide: 2560,
  },
};

export default theme;
