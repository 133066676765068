import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import { useLottieFiles } from 'hooks/useLottieFiles';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { paperRipsDotsQuestionData } from './PaperRipsDotsQuestion.data';
import * as S from './PaperRipsDotsQuestion.styled';

import PaperRips from '1_components/PaperRips/PaperRips';
import PaperRipSlider from '1_components/PaperRipSlider/PaperRipSlider';

export interface PaperRipsDotsQuestionProps {}

const PaperRipsDotsQuestion: React.FC<PaperRipsDotsQuestionProps> = ({
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const { setAnswer, getAnswer } = useQuiz();
  const {
    files: {
      paperRipsQuestions: { dots },
    },
  } = useLottieFiles();

  useEffect(() => {
    setGlobalState('allowSubmit', selectedOption !== -1);
    setAnswer({
      paperRipsDotsQuestion: selectedOption,
    });

    return () => {
      getAnswer('paperRipsDotsQuestion') === -1 &&
        setAnswer({ paperRipsDotsQuestion: 0 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.white);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'light');
    setGlobalState('quizHintCardTheme', { mobile: 'dark', desktop: 'dark' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'dragHorizontal',
      desktop: 'dragHorizontal',
    });
    setGlobalState('allowSubmit', false);
  });

  const questionTitleLeft = (
    <>
      <S.Caption
        fontFamily="amazonEmber"
        markdown={paperRipsDotsQuestionData.question.desktop}
        underline={{ variant: 'scribble', color: theme.colors.white }}
        renderAs="h3"
      />
      <S.CaptionMobile
        fontFamily="amazonEmber"
        markdown={paperRipsDotsQuestionData.question.mobile}
        underline={{ variant: 'tornado', color: theme.colors.white }}
        renderAs="h3"
      />
    </>
  );

  const questionTitleRight = (
    <>
      <S.Caption
        fontFamily="amazonEmber"
        markdown={paperRipsDotsQuestionData.question.desktop}
        underline={{ variant: 'scribble', color: theme.colors.darkPurple }}
        renderAs="h3"
      />
      <S.CaptionMobile
        fontFamily="amazonEmber"
        markdown={paperRipsDotsQuestionData.question.mobile}
        underline={{ variant: 'tornado', color: theme.colors.white }}
        renderAs="h3"
      />
    </>
  );

  const leftSide = {
    value: paperRipsDotsQuestionData.answers[0].value,
    component: (
      <S.LeftSideWrapper>
        {questionTitleLeft}
        <S.LeftLottie
          animationData={dots}
          play
          preserveAspectRatio="xMidYMid slice"
        />
        <PaperRips
          variant="paperSplit"
          primaryColor={theme.colors.lightPurple}
        />
        <S.LeftTitle
          text={paperRipsDotsQuestionData.answers[0].text}
          fontFamily="leagueGothic"
          renderAs="h2"
        />
      </S.LeftSideWrapper>
    ),
  };

  const rightSide = {
    value: paperRipsDotsQuestionData.answers[1].value,
    component: (
      <S.RightSideWrapper>
        {questionTitleRight}
        <S.RightLottie
          animationData={dots}
          play
          preserveAspectRatio="xMidYMid slice"
        />
        <PaperRips
          variant="paperSplit"
          primaryColor={theme.colors.darkPurple}
        />
        <S.RightTitle
          text={paperRipsDotsQuestionData.answers[1].text}
          fontFamily="leagueGothic"
          renderAs="h2"
        />
      </S.RightSideWrapper>
    ),
  };

  return (
    <S.DotsQuestionWrapper {...rest}>
      <PaperRipSlider
        answers={{ leftAnswer: leftSide, rightAnswer: rightSide }}
        paperRips={paperRipsDotsQuestionData.paperRips}
        onSelect={u =>
          setSelectedOption(
            paperRipsDotsQuestionData.answers.indexOf(
              paperRipsDotsQuestionData.answers.find(({ value }) => value === u)
            )
          )
        }
      />
    </S.DotsQuestionWrapper>
  );
};

PaperRipsDotsQuestion.displayName = 'PaperRipsDotsQuestion';

export default PaperRipsDotsQuestion;
