import { ANSWERS_LOGIC_WEIGHT } from 'utils/quizAnswer/logicWeights';

export const filteredAnswers = (logics: string[], answersData: any[]) => {
  const filteredQuestions = [];

  for (const logic of logics) {
    const filterAnswersData = answersData.filter(
      answer => answer.logic === logic
    );

    filteredQuestions.push(filterAnswersData[0]);
  }
  return filteredQuestions;
};

export const logicIndex = (logicFromQuestion, currentOption) =>
  Object.values(logicFromQuestion).map(
    (logic, index) => logic === currentOption && { key: index, logic }
  );

export const getLogicKey = (answers, selectedOption: number) => {
  const logicFromQuestion = ANSWERS_LOGIC_WEIGHT['multicolorHeadQuestion'];
  const currentOption = answers[selectedOption].logic;

  const option = logicIndex(logicFromQuestion, currentOption).filter(
    item => typeof item === 'object'
  )[0];

  return option.key || selectedOption;
};
