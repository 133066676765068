import styled, { css } from 'styled-components';

import media from 'utils/styles/mediaQueries';

import Lottie from '1_components/Lottie/Lottie';
import Typography from '1_components/Typography/Typography';

export const AnswerWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
`;

export const Answer = styled(Typography)`
  font-size: 3.6rem;
  text-align: center;
  line-height: 0.9;
  z-index: 1;

  ${media.desktop} {
    font-size: 5.6rem;
  }
`;
const scribbleCommonStyle = css`
  position: absolute;
  inset: -250% -250%;

  width: 600%;
  height: 600%;
`;

export const SquareScribble = styled(Lottie)`
  ${scribbleCommonStyle}
  transform: scaleX(0.29) scaleY(1.3) translateY(16%);
  pointer-events: none;
`;

export const LinesScribble = styled(Lottie)`
  ${scribbleCommonStyle}
  transform: scaleX(0.25) scaleY(1.2) translateY(-4%) translateX(-5%);
  pointer-events: none;
`;

export const RoundScribble = styled(Lottie)`
  ${scribbleCommonStyle}
  transform: scaleX(0.25) scaleY(2.3) translateY(-26%);
  pointer-events: none;
`;
