import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

import ParallaxWrapper from '1_components/ParallaxWrapper/ParallaxWrapper';

const animateEyeBlink = (step: number) => keyframes`
  0%, 100% {
    opacity: 1;
    transform: scaleY(0);
  }
  ${`${step}%`}, 100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

export const BlinkEyeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

export const SvgEyeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  animation: ${animateEyeBlink(2)} 8s ease infinite;
  animation-delay: 2s;
`;

export const ParallaxBox = styled(ParallaxWrapper)`
  position: absolute;
  inset: 0;
`;

export const EyeWrapper = styled(motion.div)`
  position: absolute;
  inset: 0;
`;

export const Svg = styled(motion.svg)``;

export const SvgEye = styled(Svg)`
  position: absolute;

  inset: 0;
`;

export const G = styled(motion.g)``;

export const Path = styled(motion.path)``;
