import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { useGlobalState } from 'context/globalState';
import useDisableScroll from 'hooks/useDisableScroll';
import { Questions } from 'types/questions';
import { ANSWERS_LOGIC_WEIGHT } from 'utils/quizAnswer/logicWeights';
import Personality from 'utils/quizAnswer/personality';
import useQuiz, {
  LOCAL_STORAGE_PERSONALITIES_KEY,
  LOCAL_STORAGE_QUESTION_ID_KEY,
  LOCAL_STORAGE_START_QUIZ_KEY,
} from 'utils/quizAnswer/useQuiz';

import { quizData } from './Quiz.data';
import { orderedQuestions as initialOrderedQuestions } from './Quiz.questions';
import * as S from './Quiz.styled';
import { isQuestionAnswersRepeat, questionLeave } from './Quiz.utils';

import FadeInOut from '1_components/FadeInOut/FadeInOut';
import EyeQuestion from '2_sections/questions/EyeQuestion/EyeQuestion';
import GardenQuestion from '2_sections/questions/GardenQuestion/GardenQuestion';
import KeyholeQuestion from '2_sections/questions/KeyholeQuestion/KeyholeQuestion';
import MulticolorHeadQuestion from '2_sections/questions/MulticolorHeadQuestion/MulticolorHeadQuestion';
import ReorderQuestion from '2_sections/questions/ReorderQuestion/ReorderQuestion';

export interface QuizProps {}

declare const gtag: (x: string, y: string, z: any) => void;

const Quiz: FC<QuizProps> = ({ ...rest }) => {
  const orderedQuestions = useMemo(() => initialOrderedQuestions.slice(), []);

  const tieBreakers = [
    EyeQuestion.displayName,
    GardenQuestion.displayName,
    MulticolorHeadQuestion.displayName,
  ];
  const router = useRouter();

  useEffectOnce(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_START_QUIZ_KEY)) {
      router.push('/');
      return;
    }
  });

  const { getAnswers, getAnswer, setQuestionId } = useQuiz();
  const answers = useMemo(() => {
    return { quizAnswers: getAnswers() };
  }, [getAnswers]);

  const personality = useMemo(() => {
    return new Personality(answers);
  }, [answers]);

  const [blockSubmit, setBlockSubmit] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(() => {
    return Number(localStorage.getItem(LOCAL_STORAGE_QUESTION_ID_KEY)) || 0;
  });

  const results = useMemo(() => {
    personality.calculatePersonality();
    return personality.results;
  }, [personality]);

  const lastQuestionData = useMemo(() => {
    return personality.lastQuestionChecker();
  }, [personality]);

  const [progressBarColor] = useGlobalState('progressBarColor');
  const [quizSubmitVariant] = useGlobalState('quizSubmitVariant');
  const [quizHintCardVariant] = useGlobalState('quizHintCardVariant');
  const [quizHintCardTheme] = useGlobalState('quizHintCardTheme');
  const [allowSubmit, setAllowSubmit] = useGlobalState('allowSubmit');

  const path = useMemo(() => {
    return `${quizData.button.href}/${Object.keys(results).join('-')}`;
  }, [results]);

  useDisableScroll();

  useEffectOnce(() => {
    setAllowSubmit(true);
    const onHashChange = () => {
      const cleanHash = window.location.hash.replace(/[^0-9]/g, '');
      const hashValue = Number(cleanHash ? cleanHash : -1);
      const localStorageQuestionId =
        Number(localStorage.getItem(LOCAL_STORAGE_QUESTION_ID_KEY)) || 0;

      if (hashValue < localStorageQuestionId && hashValue >= 0) {
        setQuestionIndex(hashValue);
      }
    };

    addEventListener('hashchange', onHashChange);

    return () => {
      removeEventListener('hashchange', onHashChange);
    };
  });

  useEffect(() => {
    setAllowSubmit(true);
    setQuestionId(questionIndex);
    window.location.hash = `${questionIndex}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionIndex]);

  const CurrentQuestion =
    questionIndex < orderedQuestions.length &&
    questionIndex > -1 &&
    orderedQuestions[questionIndex];

  useEffect(() => {
    if (CurrentQuestion) {
      return;
    }
    setQuestionIndex(0);
  }, [CurrentQuestion]);

  const hintCard = (
    <>
      <S.HintCardMobileComp
        cardTheme={quizHintCardTheme.mobile}
        variant={quizHintCardVariant.mobile}
        questionIndex={questionIndex}
      />
      <S.HintCardDesktopComp
        cardTheme={quizHintCardTheme.desktop}
        variant={quizHintCardVariant.desktop}
        questionIndex={questionIndex}
      />
    </>
  );

  const handleNextPage = async () => {
    if (!CurrentQuestion) {
      return;
    }
    setBlockSubmit(true);

    const savePersonalityResultToLocalStorage = async (
      personalityResult: string
    ) => {
      const localSavedPersonalities = localStorage.getItem(
        LOCAL_STORAGE_PERSONALITIES_KEY
      );

      if (localSavedPersonalities) {
        const parsed: Array<string> = JSON.parse(localSavedPersonalities);
        if (parsed.indexOf(personalityResult) === -1) {
          parsed.push(personalityResult);

          localStorage.setItem(
            LOCAL_STORAGE_PERSONALITIES_KEY,
            JSON.stringify(parsed)
          );
        }
      } else {
        localStorage.setItem(
          LOCAL_STORAGE_PERSONALITIES_KEY,
          JSON.stringify([personalityResult])
        );
      }
    };

    if (CurrentQuestion.displayName === 'SmileyQuestion') {
      if (
        !isQuestionAnswersRepeat(getAnswers(), orderedQuestions, {
          questionIndex: 2,
          questionsToCut: 2,
        })
      ) {
        orderedQuestions[3].displayName === tieBreakers[0] &&
          orderedQuestions.splice(3, 1);
      } else if (orderedQuestions[3].displayName !== tieBreakers[0])
        orderedQuestions.splice(3, 0, EyeQuestion);

      questionIndex + 1 < orderedQuestions.length &&
        setQuestionIndex(prev => prev + 1);
    } else if (CurrentQuestion.displayName === 'ReorderQuestion') {
      await questionLeave(orderedQuestions, questionIndex, {
        prevQuestion: ReorderQuestion,
        question: GardenQuestion,
        tieBreaker: tieBreakers[1],
        getAnswers,
        questionsToCut: 2,
      });

      questionIndex + 1 < orderedQuestions.length &&
        setQuestionIndex(prev => prev + 1);
    } else if (CurrentQuestion.displayName === 'KeyholeQuestion') {
      await questionLeave(orderedQuestions, questionIndex, {
        prevQuestion: KeyholeQuestion,
        question: MulticolorHeadQuestion,
        tieBreaker: tieBreakers[2],
        getAnswers,
        questionsToCut: 3,
      });

      if (questionIndex < orderedQuestions.length - 1) {
        setQuestionIndex(prev => prev + 1);
      } else {
        await savePersonalityResultToLocalStorage(
          Object.keys(results).join('-')
        );
        router.push(path).then(() => {
          gtag('event', 'quiz_finished', {
            personality: Object.keys(results).join('-'),
          });
        });
      }
    } else {
      if (questionIndex < orderedQuestions.length - 1) {
        setQuestionIndex(prev => prev + 1);
      } else {
        await savePersonalityResultToLocalStorage(
          Object.keys(results).join('-')
        );

        router.push(path).then(() => {
          gtag('event', 'quiz_finished', {
            personality: Object.keys(results).join('-'),
          });
        });
      }
    }
  };

  const submitButtonDisabledVariant = allowSubmit
    ? quizSubmitVariant
    : 'disabled';

  useEffectOnce(() => {
    if (
      !localStorage.getItem(LOCAL_STORAGE_QUESTION_ID_KEY) ||
      !localStorage.getItem(LOCAL_STORAGE_START_QUIZ_KEY)
    ) {
      setShouldRender(false);
      return;
    }
    if (questionIndex <= 2) {
      setShouldRender(true);
      return;
    }
    if (shouldRender) {
      return;
    }

    const checkTies = async () => {
      const questionIndexSets = [
        [0, 1, 2],
        [4, 5, 6],
        [8, 9, 10, 11],
      ];
      const tiebreakerQuestionsDisplayNames = [
        'EyeQuestion',
        'GardenQuestion',
        'MulticolorHeadQuestion',
      ];

      const questionComponentsDisplayNames = orderedQuestions.map(
        questionComponent =>
          `${
            questionComponent.displayName.charAt(0).toLowerCase() +
            questionComponent.displayName.slice(1)
          }`
      );
      const realAnswers = questionComponentsDisplayNames.map(
        (displayName: Questions) => {
          const key = getAnswer(displayName) + 1;
          return ANSWERS_LOGIC_WEIGHT[displayName][key];
        }
      );

      const tiebreakerFlags = questionIndexSets.map(questionIndexSet => {
        const resultsForThisIndexSet = questionIndexSet.map(
          questionIndex => realAnswers[questionIndex]
        );
        return (
          resultsForThisIndexSet.length === new Set(resultsForThisIndexSet).size
        );
      });

      tiebreakerFlags.forEach((tiebreakerFlag, index) => {
        if (!tiebreakerFlag) {
          const displayNames = orderedQuestions.map(
            questionComponent => questionComponent.displayName
          );
          const tiebreakerIndex = displayNames.indexOf(
            tiebreakerQuestionsDisplayNames[index]
          );
          orderedQuestions.splice(tiebreakerIndex, 1);
        }
      });

      setShouldRender(true);
    };

    checkTies();

    return () => setShouldRender(false);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setBlockSubmit(false);
    }, 500);
    return () => clearTimeout(timer);
  });

  return (
    <S.QuizWrapper {...rest}>
      {shouldRender && (
        <>
          <S.NoiseLayerComp />
          <S.QuizProgress
            currentStep={questionIndex + 1}
            stepsLength={orderedQuestions.length + 1}
            color={progressBarColor}
          />

          <AnimatePresence exitBeforeEnter>
            <FadeInOut key={`transition ${questionIndex}`}>
              {CurrentQuestion?.displayName === tieBreakers[2] ? (
                <MulticolorHeadQuestion logics={lastQuestionData} />
              ) : (
                CurrentQuestion && <CurrentQuestion />
              )}

              {hintCard}
            </FadeInOut>
          </AnimatePresence>

          <S.SubmitButton
            disabled={!allowSubmit || blockSubmit}
            variant={submitButtonDisabledVariant}
            href={null}
            text={quizData.button.label}
            onClick={handleNextPage}
            style={{
              pointerEvents: blockSubmit ? 'none' : 'all',
              userSelect: blockSubmit ? 'none' : 'auto',
            }}
          />
        </>
      )}
    </S.QuizWrapper>
  );
};
export default Quiz;
