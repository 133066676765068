import React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { setGlobalState, useGlobalState } from 'context/globalState';
import theme from 'utils/styles/theme';

import { signsQuestionData } from './SignsQuestion.data';
import * as S from './SignsQuestion.styled';
import SignsQuestionSelector from './SignsQuestionSelector';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface SignsQuestionProps {}

const SignsQuestion: React.FC<SignsQuestionProps> = () => {
  const [motionAllowed] = useGlobalState('deviceMotionAllowed');

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.white);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'light');
    setGlobalState('quizHintCardTheme', { mobile: 'dark', desktop: 'dark' });
    setGlobalState('quizHintCardVariant', {
      mobile: motionAllowed ? 'gyroscope' : 'dragHorizontal',
      desktop: 'dragHorizontal',
    });
  });

  const questionTitle = (
    <>
      <S.SignsQuestionTitle
        fontFamily="amazonEmber"
        markdown={signsQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h1"
      />
      <S.SignsQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={signsQuestionData.question.mobile}
        underline={{ variant: 'knotLong', color: theme.colors.pink }}
        renderAs="h1"
      />
    </>
  );

  return (
    <S.SignsQuestionWrapper>
      <PaperRips
        primaryColor={theme.colors.pink}
        secondaryColor={theme.colors.red}
      />

      {questionTitle}

      <SignsQuestionSelector />
    </S.SignsQuestionWrapper>
  );
};

SignsQuestion.displayName = 'SignsQuestion';

export default SignsQuestion;
