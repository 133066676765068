export const getUrl = (personalities: string[], shareThrough: string) =>
  `https://${window.location.hostname}/${shareThrough}/${personalities.join(
    '-'
  )}`;

export const convertPersonalityName = (name: string) => {
  if (name === 'reliable') return 'reliably';
  if (name === 'unique') return 'uniquely';
  if (name === 'wild') return 'wildly';

  return name || '';
};
