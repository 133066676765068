export const leavesQuestionData = {
  question: {
    desktop:
      'Look inside.<br>Which of these best describes <br>your *<mark>inner landscape</mark>?*',
    mobile:
      'Look inside.<br>Which of these best describes <br>your *<mark>inner landscape</mark>?*',
  },
  answers: [
    { text: 'the\norderly\ngarden', value: 'garden' },
    { text: 'the\nmeandering\nmeadow', value: 'meadow' },
    { text: 'the\nuntamed\nwoods', value: 'woods' },
  ],
};
