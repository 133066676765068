import { Questions } from 'types/questions';

type LogicTypes =
  | 'reliably'
  | 'uniquely'
  | 'wildly'
  | 'chill'
  | 'curious'
  | 'fun'
  | 'daydreamer'
  | 'peoplePerson'
  | 'adventurer'
  | 'goGetter'
  | 'thrillSeeker'
  | 'creator';

export const ANSWERS_LOGIC_WEIGHT: {
  [K in Questions]: { [key: string]: LogicTypes };
} = {
  // STYLE
  leavesQuestion: {
    1: 'reliably',
    2: 'uniquely',
    3: 'wildly',
  },
  carQuestion: {
    1: 'reliably',
    2: 'uniquely',
    3: 'wildly',
  },
  smileyQuestion: {
    1: 'reliably',
    2: 'uniquely',
    3: 'wildly',
  },
  eyeQuestion: {
    1: 'reliably',
    2: 'uniquely',
    3: 'wildly',
  },
  // MOTIVATION
  spaceshipQuestion: {
    1: 'chill',
    2: 'fun',
    3: 'curious',
  },
  signsQuestion: {
    1: 'chill',
    2: 'curious',
    3: 'fun',
  },
  reorderQuestion: {
    1: 'curious',
    2: 'fun',
    3: 'chill',
  },
  gardenQuestion: {
    1: 'curious',
    2: 'fun',
    3: 'chill',
  },
  // PASSION
  paperRipsBarsQuestion: {
    1: 'daydreamer',
    2: 'peoplePerson',
  },
  paperRipsDotsQuestion: {
    1: 'adventurer',
    2: 'creator',
  },
  paperRipsWavesQuestion: {
    1: 'goGetter',
    2: 'thrillSeeker',
  },
  keyholeQuestion: {
    1: 'daydreamer',
    2: 'peoplePerson',
    3: 'adventurer',
    4: 'creator',
    5: 'goGetter',
    6: 'thrillSeeker',
  },
  multicolorHeadQuestion: {
    1: 'daydreamer',
    2: 'adventurer',
    3: 'thrillSeeker',
    4: 'peoplePerson',
    5: 'goGetter',
    6: 'creator',
  },
};
