import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';
import {
  largeHeadingStyles,
  middleQuestionStyles,
  mobileQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import Lottie from '1_components/Lottie/Lottie';
import Typography from '1_components/Typography/Typography';

export const WavesQuestionWrapper = styled(QuestionWrapper)``;

export const Caption = styled(QuestionTitle)`
  ${middleQuestionStyles}
  font-size: 2.8rem;
  z-index: ${zIndex('aboveOverlay')};
  pointer-events: none;
`;

export const CaptionMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
  z-index: ${zIndex('aboveOverlay')};
  pointer-events: none;
`;

///////////////////////////////////////////////
// Left
//////////////////////////////////////////////

export const LeftSideWrapper = styled(QuestionWrapper)`
  display: grid;
  place-items: center;

  background: ${({ theme }) => theme.colors.limeGreen};
  pointer-events: none;
`;

export const LeftTitle = styled(Typography)`
  position: absolute;
  ${largeHeadingStyles}
  color: ${({ theme }) => theme.colors.black};
  pointer-events: none;
`;

export const LeftLottie = styled(Lottie)`
  position: absolute;
  inset: -40% -5%;
  pointer-events: none;
  will-change: auto;

  transform: scaleY(-1);
  opacity: 0.3;
`;

///////////////////////////////////////////////
// RightSide
//////////////////////////////////////////////

export const RightSideWrapper = styled(LeftSideWrapper)`
  background: ${({ theme }) => theme.colors.darkBlue};
`;

export const RightTitle = styled(LeftTitle)`
  color: ${({ theme }) => theme.colors.white};
`;

export const RightLottie = styled(LeftLottie)`
  transform: scaleY(1) rotate(180deg);
`;
