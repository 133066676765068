import styled, { css } from 'styled-components';

import BackgroundDesktopLeft from 'svgs/landing/landingBackgroundDesktopLeft.svg';
import BackgroundDesktopRight from 'svgs/landing/landingBackgroundDesktopRight.svg';
import BackgroundDesktopRightBack from 'svgs/landing/landingBackgroundDesktopRightBack.svg';
import BackgroundMobileLeft from 'svgs/landing/landingBackgroundMobileLeft.svg';
import BackgroundMobileRight from 'svgs/landing/landingBackgroundMobileRight.svg';
import BackgroundMobileRightBack from 'svgs/landing/landingBackgroundMobileRightBack.svg';
import Underline from 'svgs/landing/underline.svg';
import media from 'utils/styles/mediaQueries';
import { vh, zIndex } from 'utils/styles/mixins';

import PrimaryButton from '1_components/Buttons/PrimaryButton/PrimaryButton';
import LinkTypography from '1_components/LinkTypography/LinkTypography';
import NoiseLayer from '1_components/NoiseLayer/NoiseLayer';
import ParallaxWrapper from '1_components/ParallaxWrapper/ParallaxWrapper';
import Typography from '1_components/Typography/Typography';

const rowGap = 56;

export const LandingWrapper = styled.section<{ $height: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: ${rowGap}px;
  height: ${({ $height }) => ($height ? `${$height}px` : vh(100))};

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  background: ${({ theme }) => theme.colors.black};
  touch-action: none;
`;

export const Noise = styled(NoiseLayer)`
  z-index: ${zIndex('aboveContent')};
`;

const background = css`
  height: 100%;
  top: 0;
  pointer-events: none;
`;

export const SvgBackgroundDesktopLeft = styled(BackgroundDesktopLeft)`
  ${background};
  left: 0;
`;

export const SvgBackgroundDesktopRight = styled(BackgroundDesktopRight)`
  ${background};
  right: 0;
  margin-left: auto;
`;

export const SvgBackgroundDesktopRightBack = styled(BackgroundDesktopRightBack)`
  ${background};
  right: 0;
  margin-left: auto;
`;

export const SvgBackgroundMobileLeft = styled(BackgroundMobileLeft)`
  ${background};
  left: 0;
`;
export const SvgBackgroundMobileRight = styled(BackgroundMobileRight)`
  ${background};
  right: 0;
  margin-left: auto;
`;

export const SvgBackgroundMobileRightBack = styled(BackgroundMobileRightBack)`
  ${background};
  right: 0;
  margin-left: auto;
`;

export const Inner = styled.section`
  position: relative;
  display: grid;
  justify-items: center;
  text-align: center;

  color: ${({ theme }) => theme.colors.white};

  width: min-content;
`;

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 3.4rem;
  line-height: 90%;

  ${media.tablet} {
    font-size: 6rem;
  }
`;

export const SvgUnderline = styled(Underline)`
  width: 80%;
  margin-bottom: ${rowGap / 2}px;
  transform: translateY(4px);
`;

export const Description = styled(Typography)`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 120%;

  ${media.tablet} {
    font-size: 2.4rem;
  }
`;

export const StartButton = styled(PrimaryButton)`
  display: none;

  ${media.desktop} {
    display: initial;
    padding: 18px 72px;
  }
`;

export const StartButtonMobile = styled(PrimaryButton)`
  max-width: ${({ theme }) =>
    `calc(100vw - 2 * ${theme.sharedValues.mobileIndent}px)`};

  ${media.desktop} {
    display: none;
  }
`;

export const ParallaxBox = styled(ParallaxWrapper)`
  pointer-events: none;
`;

export const DesktopFooterWrapper = styled.div`
  display: none;

  ${media.desktop} {
    position: absolute;
    bottom: 0;

    display: grid;
    place-items: center;
    padding: 24px 0;

    width: 100%;
  }
`;

export const MobileFooterWrapper = styled.div`
  position: absolute;
  inset: 0;

  padding-bottom: 34px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  row-gap: 24px;

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: 0;
    width: 100%;
    height: 64px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  ${media.desktop} {
    display: none;
  }
`;

export const LegalLinksWrapper = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const LegalLink = styled(LinkTypography)`
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.1em;
`;
