import styled, { css } from 'styled-components';

import ArrowIcon from 'svgs/interactives/arrow.svg';
import media from 'utils/styles/mediaQueries';
import {
  mobileQuestionStyles,
  splitQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import BaseButton from '1_components/Buttons/BaseButton/BaseButton';
import SwiperSelector from '1_components/SwiperSelector/SwiperSelector';

export const SignsQuestionWrapper = styled(QuestionWrapper)`
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop} {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SignsQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles}
`;

export const SignsQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const SignsSwiperWrapper = styled.div`
  position: absolute;
  overflow: visible;
  display: flex;
  inset: 0;

  ${media.desktop} {
    margin: 0 0 0 50%;
  }
`;

export const SignsSwiperSelector = styled(SwiperSelector)`
  overflow: visible;
  height: 100%;
  width: 100%;
`;

export const SignsNavigation = styled.div`
  display: none;

  ${media.desktop} {
    position: absolute;
    display: flex;
    justify-content: space-around;

    height: fit-content;
    z-index: ${zIndex('aboveContent')};

    width: 50vw;
    inset: 50% 0;
    pointer-events: none;
  }
`;

export const ArrowButton = styled(BaseButton)<{ disabled: boolean }>`
  cursor: pointer;
  pointer-events: all;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      cursor: auto;
    `}
`;

export const SvgArrowLeft = styled(ArrowIcon)`
  color: ${({ theme }) => theme.colors.white};
  transform: rotate(180deg);
`;

export const SvgArrowRight = styled(ArrowIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Sign = styled.img`
  pointer-events: none;
  user-select: none;

  width: 25rem;
  max-width: 300px;

  ${media.desktop} {
    width: 20vw;
    max-width: initial;
  }
`;
