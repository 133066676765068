import styled from 'styled-components';

import RoadsMobile from 'svgs/questions/car/roads/roads9x16.svg';
import RoadsDesktop from 'svgs/questions/car/roads/roads16x9.svg';
import media from 'utils/styles/mediaQueries';
import {
  middleQuestionStyles,
  mobileQuestionStyles,
  vh,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';
import CarSlider from './CarSlider/CarSlider';

const roadWidth = 52;

export const CarQuestionWrapper = styled(QuestionWrapper)<{
  $backgroundColor: string;
}>`
  position: relative;

  @supports (height: 100dvh) {
    height: 100dvh;
    align-self: flex-start;
  }

  color: ${({ theme }) => theme.colors.black};

  background: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : theme.colors.red};

  transition-property: background;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
`;

export const CarQuestionTitle = styled(QuestionTitle)`
  ${middleQuestionStyles}
  pointer-events: none;
  user-select: none;
`;

export const CarQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
  pointer-events: none;
`;

export const SvgRoadsMobile = styled(RoadsMobile)`
  position: absolute;
  inset: 0 0 0 0;

  width: 100%;
  height: 100%;
  pointer-events: none;

  ${media.desktop} {
    display: none;
  }
`;

export const SvgRoadsDesktop = styled(RoadsDesktop)`
  display: none;

  ${media.desktop} {
    position: absolute;
    inset: 0;
    display: block;

    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

export const RoadWrapper = styled.div<{ $windowHeight: number }>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};

  top: unset;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: ${roadWidth}px;
  height: ${({ $windowHeight }) =>
    $windowHeight ? `${($windowHeight * 40) / 100}px` : vh(40)};
  bottom: ${({ theme, $windowHeight }) =>
    `calc(${theme.sharedValues.mobileQuizSubmitBottomOffset}px + 16px + 100vh - ${$windowHeight}px)`};

  @supports (height: 40dvh) {
    bottom: ${({ theme }) =>
      theme.sharedValues.mobileQuizSubmitBottomOffset + 16}px;
    height: 40dvh;
  }

  ${media.desktop} {
    top: 60%;
    left: unset;
    bottom: unset;
    width: 100%;
    height: ${roadWidth * 2}px;
    transform: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    height: ${vh(100)};

    @supports (height: 100dvh) {
      height: 100dvh;
    }

    width: ${roadWidth}px;
    background: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    user-select: none;

    ${media.desktop} {
      display: none;
    }
  }
`;

export const RoadTurn = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0px;
  width: 100vw;
  height: ${roadWidth * 2}px;
  ${media.desktop} {
    display: none;
  }
`;

export const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Turn = styled.div<{ $backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 208px;
  height: 208px;
  border-radius: 50% 0 0 0;
  background: ${({ theme }) => theme.colors.white};

  // line

  &::before {
    position: absolute;
    content: '';
    width: 100vw;
    height: ${roadWidth}px;
    background: ${({ theme }) => theme.colors.white};
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: ${roadWidth}px;
    left: ${roadWidth}px;
    border-radius: 25% 0 0 0;
    transition: background 0.6s ease-in-out;
    background: ${({ $backgroundColor }) => $backgroundColor};
    pointer-events: none;
    user-select: none;
  }
`;

export const CarSliderWrapper = styled.div<{ $direction: 'x' | 'y' }>`
  position: absolute;
  top: 0;
  left: 50%;
  width: 75%;
  height: ${({ $direction }) => ($direction === 'x' ? '100%' : '100%')};
  transform: translateX(-50%);
`;

export const CarSliderComp = styled(CarSlider)``;
