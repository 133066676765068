import { createGlobalStyle } from 'styled-components';

import media from './mediaQueries';

const styled = { createGlobalStyle };

export default styled.createGlobalStyle`
  :root {
    --vh: 1vh;
  }

  #root,
  #__next {
    isolation: isolate;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  html,
  body {
    -webkit-overflow-scrolling: touch;
  }

  html {
    font-size: calc(100vw / 390 * 10);

    ${media.tablet} {
      font-size: calc(100vw / 768 * 10);
    }

    ${media.desktop} {
      font-size: calc(100vw / 1512 * 10);
    }

    ${media.desktopLarge} {
      font-size: 62.5%;
    }
  }

  body {
    overscroll-behavior-y: none;
    background-color: ${({ theme }) => theme.colors.black};
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  button,
  input,
  textarea,
  select {
    -webkit-appearance: none;
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    outline: none;
    border-radius: 0;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
    font-weight: normal;
  }

  svg {
    height: auto;
    width: auto;
    display: block;
    max-width: unset;
  }

  a {
    all: unset;
    cursor: pointer;
  }

  mark {
    color: inherit;
  }
`;
