import React, { FC } from 'react';

import { BaseButtonProps } from '../BaseButton/BaseButton';

import * as S from './PrimaryButton.styles';

export interface PrimaryButtonProps {
  text: string;
  variant?: S.PrimaryButtonVariant;
  disabled?: boolean;
}

const PrimaryButton: FC<PrimaryButtonProps & BaseButtonProps> = ({
  children,
  text,
  variant = 'light',
  ...rest
}) => {
  return (
    <S.PrimaryButtonWrapper $variant={variant} {...rest}>
      {children}
      <S.Text fontFamily="amazonEmber" text={text} renderAs="span" />
    </S.PrimaryButtonWrapper>
  );
};
export default PrimaryButton;
