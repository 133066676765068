import React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { setGlobalState, useGlobalState } from 'context/globalState';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { keyholeQuestionData } from './KeyholeQuestion.data';
import * as S from './KeyholeQuestion.styled';
import KeyholeQuestionSelector from './KeyholeQuestionSelector';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface KeyholeQuestionProps {}

const KeyholeQuestion: React.FC<KeyholeQuestionProps> = () => {
  const [motionAllowed] = useGlobalState('deviceMotionAllowed');
  const { setAnswer } = useQuiz();

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.white);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'light');
    setGlobalState('quizHintCardTheme', { mobile: 'dark', desktop: 'dark' });
    setGlobalState('quizHintCardVariant', {
      mobile: motionAllowed ? 'gyroscope' : 'dragHorizontal',
      desktop: 'dragHorizontal',
    });
  });

  useEffectOnce(() => {
    setAnswer({ multicolorHeadQuestion: -1 });
  });

  const questionTitle = (
    <>
      <S.KeyholeQuestionTitle
        fontFamily="amazonEmber"
        markdown={keyholeQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.lightBlue }}
        underlineBetweenTextLines
        renderAs="h1"
      />
      <S.KeyholeQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={keyholeQuestionData.question.mobile}
        underline={{ variant: 'knotLong', color: theme.colors.limeGreen }}
        underlineBetweenTextLines
        renderAs="h1"
      />
    </>
  );

  return (
    <S.KeyholeQuestionWrapper>
      <S.KeyholeClip />

      <PaperRips
        primaryColor={theme.colors.darkPurple}
        secondaryColor={theme.colors.lightPurple}
      />

      {questionTitle}

      <KeyholeQuestionSelector />
    </S.KeyholeQuestionWrapper>
  );
};

KeyholeQuestion.displayName = 'KeyholeQuestion';

export default KeyholeQuestion;
