import { Questions } from 'types/questions';

import CountAnswers from './countAnswers';

export type Personalities =
  | 'reliable'
  | 'unique'
  | 'wild'
  | 'curious'
  | 'fun'
  | 'chill'
  | 'daydreamer'
  | 'peoplePerson'
  | 'adventurer'
  | 'creator'
  | 'goGetter'
  | 'thrillSeeker';

interface Constructor {
  quizAnswers: { [K in Questions]: number };
}

interface PersonalityTypes {
  _personalities: {
    [K in Personalities]: number;
  };
  _personality: Personalities;
  results: {
    [K in Personalities]?: number;
  };
  calculatePersonality: () => void;
}

class Personality extends CountAnswers implements PersonalityTypes {
  _personalities = {
    // Style
    reliable: 0,
    unique: 0,
    wild: 0,
    // Motivation
    curious: 0,
    fun: 0,
    chill: 0,
    // Passion
    daydreamer: 0,
    peoplePerson: 0,
    adventurer: 0,
    creator: 0,
    goGetter: 0,
    thrillSeeker: 0,
  };
  _personality = null;

  results = { reliable: 0, curious: 0, daydreamer: 0 };

  private resultsSections = {
    style: ['reliable', 'unique', 'wild'],
    motivation: ['curious', 'fun', 'chill'],
    passion: [
      'daydreamer',
      'peoplePerson',
      'adventurer',
      'creator',
      'goGetter',
      'thrillSeeker',
    ],
  };

  constructor({ quizAnswers }: Constructor) {
    super({ quizAnswers });
  }

  calculatePersonality = () => {
    this.setPoints();
    Object.keys(this._personalities).forEach(key => {
      if (key === 'reliable')
        return (this._personalities[key] += this._logics['reliably']);
      if (key === 'unique')
        return (this._personalities[key] += this._logics['uniquely']);
      if (key === 'wild')
        return (this._personalities[key] += this._logics['wildly']);
      this._logics[key] && (this._personalities[key] += this._logics[key]);
    });

    const outerArr = [];

    Object.keys(this.resultsSections).forEach(key => {
      const innerArr = [];
      for (const [index, itemKey] of this.resultsSections[key].entries()) {
        innerArr.push({ [itemKey]: this._personalities[itemKey] });
        if (index + 1 === this.resultsSections[key].length) {
          const sorted = innerArr.sort(
            (a, b) =>
              (Object.values(b)[0] as number) - (Object.values(a)[0] as number)
          );
          outerArr.push(sorted[0]);
        }
      }
    });

    const finalObj = Object.assign({}, ...outerArr);

    const totalPoints = Object.values(finalObj).reduce(
      (prev: number, curr: number) => prev + curr
    );

    const percentageResults = Object.assign(
      {},
      ...Object.keys(finalObj).map(key => {
        return {
          [key]: this._calculatePercentages(totalPoints, finalObj[key]),
        };
      })
    );

    this.results = percentageResults;
    this._personality = Object.keys(finalObj)[0];
  };

  getPersonality = () => {
    return {
      personalities: this._personalities,
      personality: this._personality,
    };
  };

  _calculatePercentages = (totalValue, number) => {
    if (totalValue === 0) return 0;
    return Math.round((100 * number) / totalValue);
  };
}

export default Personality;
