import { motion } from 'framer-motion';
import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';

import Lottie from '1_components/Lottie/Lottie';

export const HintCardWrapper = styled(motion.dialog)`
  all: unset;
  pointer-events: none;
`;

export const LottieComp = styled(Lottie)`
  transform-origin: bottom;
  transform: scale(0.7);
  height: 12.4rem;

  ${media.desktop} {
    transform: none;
  }
`;
