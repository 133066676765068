import { useGlobalState } from 'context/globalState';
import { Questions } from 'types/questions';

type Answers = {
  [K in Questions]: number;
};

type Answer = {
  [K in Questions]?: number;
};

export const LOCAL_STORAGE_KEY = 'BOOKSONA_QUIZ';
export const LOCAL_STORAGE_QUESTION_ID_KEY = 'BOOKSONA_QUIZ_QID';
export const LOCAL_STORAGE_START_QUIZ_KEY = 'BOOKSONA_QUIZ_STATE';
export const LOCAL_STORAGE_PERSONALITIES_KEY = 'BOOKSONA_PERSONALITIES';

const INITIAL_ANSWERS = {
  leavesQuestion: 0,
  carQuestion: 0,
  smileyQuestion: 0,
  eyeQuestion: -1,
  spaceshipQuestion: 0,
  signsQuestion: 0,
  reorderQuestion: 0,
  gardenQuestion: -1,
  paperRipsBarsQuestion: 0,
  paperRipsDotsQuestion: 0,
  paperRipsWavesQuestion: 0,
  keyholeQuestion: 0,
  multicolorHeadQuestion: -1,
};

const useQuiz = () => {
  const [answerRefresh, setAnswerRefresh] = useGlobalState('answerRefresh');
  const allowSubmit = useGlobalState('allowSubmit');

  const getAnswers = (): Answers => {
    const storageItem = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storageItem ? JSON.parse(storageItem) : INITIAL_ANSWERS;
  };

  const setAnswer = (answer: Answer) => {
    const current = getAnswers();
    const final = { ...current, ...answer };

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(final));

    setTimeout(() => {
      setAnswerRefresh(Math.random());
    }, 50);
    //setAllowSubmit(Object.values(answer)[0] >= 0);
  };

  const getAnswer = (answer: Questions) => getAnswers()[answer];

  const resetQuiz = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_QUESTION_ID_KEY);
    localStorage.removeItem(LOCAL_STORAGE_START_QUIZ_KEY);
  };

  const setQuestionId = (questionId: number) => {
    localStorage.setItem(
      LOCAL_STORAGE_QUESTION_ID_KEY,
      JSON.stringify(questionId)
    );
  };

  const startQuiz = () => {
    localStorage.setItem(LOCAL_STORAGE_START_QUIZ_KEY, JSON.stringify(1));
  };

  return {
    setAnswer,
    getAnswer,
    getAnswers,
    allowSubmit,
    resetQuiz,
    setQuestionId,
    answerRefresh,
    startQuiz,
  };
};

export default useQuiz;
