import styled from 'styled-components';

import ArrowIcon from 'svgs/resultPage/arrowIcon.svg';
import media from 'utils/styles/mediaQueries';

import PrimaryButton from '1_components/Buttons/PrimaryButton/PrimaryButton';
import Typography from '1_components/Typography/Typography';

export const RecommendedBooksWrapper = styled.section`
  display: grid;
  place-items: center;
  width: 100%;

  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const ScrollDownMessage = styled.div`
  display: grid;
  place-items: center;
  row-gap: 16px;

  margin-bottom: 64px;

  ${media.tablet} {
    margin-bottom: 40px;
  }
`;

export const Message = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 120%;
`;

export const SvgArrow = styled(ArrowIcon)`
  color: ${({ theme }) => theme.colors.limeGreen};
  width: 24px;
`;

export const BookCovers = styled.div`
  display: grid;
  row-gap: 80px;

  width: 100%;

  ${media.tablet} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-flow: column;
    column-gap: 40px;

    max-width: 1040px;
  }
`;

export const BookItem = styled.figure`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  place-items: center;
  row-gap: 24px;

  height: auto;
  width: 100%;
`;

export const CoverWrapper = styled.div`
  position: relative;

  width: 100%;
  max-width: 240px;
  aspect-ratio: 2/3;
  border-radius: 10px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.darkGrey};
`;

export const BookInfo = styled.figcaption`
  display: grid;
  justify-items: center;
  padding: 0 32px;

  text-align: center;
  text-transform: uppercase;

  font-size: 1.6rem;
  line-height: 120%;
  letter-spacing: 0.05em;

  ${media.desktop} {
    font-size: 1.8rem;
  }
`;

export const BookTitle = styled(Typography)`
  font-weight: 500;
  white-space: pre-wrap;
`;

export const Author = styled(Typography)`
  font-weight: 300;
  white-space: pre-wrap;
`;

export const ShopNowButton = styled(PrimaryButton)`
  width: auto;
  min-width: 150px;

  margin-top: auto;
`;
