import { Questions } from 'types/questions';

import { ANSWERS_LOGIC_WEIGHT } from './logicWeights';

interface Constructor {
  quizAnswers: { [K in Questions]: number };
}

interface CountAnswersTypes {
  _logics: {
    reliably: number;
    uniquely: number;
    wildly: number;
    chill: number;
    curious: number;
    fun: number;
    daydreamer: number;
    peoplePerson: number;
    adventurer: number;
    creator: number;
    goGetter: number;
    thrillSeeker: number;
  };
  answers: { [K in Questions]: number };
  setPoints: () => void;
  getPoints: () => CountAnswersTypes['_logics'];
}

class CountAnswers implements CountAnswersTypes {
  _logics = {
    reliably: 0,
    uniquely: 0,
    wildly: 0,
    chill: 0,
    curious: 0,
    fun: 0,
    daydreamer: 0,
    peoplePerson: 0,
    adventurer: 0,
    creator: 0,
    goGetter: 0,
    thrillSeeker: 0,
  };
  answers = null;

  constructor({ quizAnswers }: Constructor) {
    this.answers = quizAnswers;
  }

  setPoints = () => {
    Object.keys(this.answers).forEach(key => {
      const value = this.answers[key];
      const logic = ANSWERS_LOGIC_WEIGHT[key][value + 1];

      this._logics[logic] += 1;
    });
  };

  getPoints = () => {
    return this._logics;
  };

  lastQuestionChecker = () => {
    const questions = Object.values(
      ANSWERS_LOGIC_WEIGHT['multicolorHeadQuestion']
    );

    const answers = Object.values(questions).filter(
      item => this._logics[item] > 0
    );

    return answers;
  };
}

export default CountAnswers;
