import { isDesktop } from 'u9/utils/platform';

const FACEBOOK_URL = (url: string) =>
  `https://www.facebook.com/sharer.php?u=${url}`;
const TWITTER_URL = (message: string) =>
  `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;

interface ShareProps {
  readonly url: string;
  readonly title: string;
  readonly message: string;
  readonly imageUrl: string;
}

class Share {
  private isDesktop = isDesktop();
  private readonly fileName: string;

  constructor(private properties: ShareProps) {
    this.fileName = `${properties.title}.png`;
  }

  private async getImage() {
    try {
      const response = await fetch(this.properties.imageUrl);
      const blob = await response.blob();
      return new File([blob], this.fileName, {
        type: blob.type || 'image/png',
      });
    } catch (e) {
      console.error(e);
    }
  }

  private async downloadImage() {
    const file = await this.getImage();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file as Blob | MediaSource);
    link.download = this.fileName;

    link.click();
  }

  private async shareImageThroughNavigator() {
    if (typeof navigator === 'undefined' || !navigator.share)
      throw new Error('Cannot shareUrlThroughNavigator. Navigator not found.');

    try {
      const file = await this.getImage();
      await navigator.share({
        files: [file],
      });
    } catch (e) {
      console.error(e);
    }
  }

  public shareImage() {
    if (this.isDesktop) return this.downloadImage();
    this.shareImageThroughNavigator().then();
  }

  public facebook() {
    if (this.isDesktop)
      return window.open(FACEBOOK_URL(this.properties.url), '_blank');
    navigator
      .share({
        url: this.properties.url,
      })
      .then()
      .catch(console.error);
  }

  public twitter() {
    if (this.isDesktop)
      return window.open(
        TWITTER_URL(`${this.properties.message} ${this.properties.url}`),
        '_blank'
      );
    navigator
      .share({
        title: this.properties.title,
        text: this.properties.message,
        url: this.properties.url,
      })
      .then()
      .catch(console.error);
  }
}

export default Share;
