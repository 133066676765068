import { findIndex } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { useGlobalState } from 'context/globalState';
import useDeviceMotionAllowed from 'hooks/useDeviceMotionAllowed';
import useQuiz from 'utils/quizAnswer/useQuiz';

import { signsQuestionData } from './SignsQuestion.data';
import * as S from './SignsQuestion.styled';
import { signsAframeItem } from './SignsQuestionSelectorComponents';

import VRSelector from '1_components/VRSelector/VRSelector';

const SignsQuestionSelector: React.FC = () => {
  const { deviceMotionAllowed } = useDeviceMotionAllowed();
  const [allowSubmit, setAllowSubmit] = useGlobalState('allowSubmit');
  const { setAnswer } = useQuiz();

  const [currentAnswer, setCurrentAnswer] = useState<string | undefined>();
  const [index, setIndex] = useState(0);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (currentAnswer === undefined) {
      setAllowSubmit(false);
    } else {
      setAllowSubmit(true);
    }

    return () => {
      setAllowSubmit(true);
    };
  }, [currentAnswer, setAllowSubmit]);

  useEffect(() => {
    const current = findIndex(
      signsQuestionData.answers,
      item => item.value === currentAnswer
    );
    setIndex(current);
    setAnswer({ signsQuestion: current });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswer]);

  const vrSelectorItems = signsQuestionData.answers.map(
    ({ image, ...rest }) => ({
      component: signsAframeItem(image),
      ...rest,
    })
  );

  const swiperSelectorItems = signsQuestionData.answers.map(
    ({ image, value }) => ({
      component: <S.Sign src={image} />,
      value,
    })
  );

  const navigation = (
    <S.SignsNavigation>
      <S.ArrowButton as="button" ref={prevRef} disabled={index === 0}>
        <S.SvgArrowLeft />
      </S.ArrowButton>

      <S.ArrowButton
        as="button"
        ref={nextRef}
        disabled={index === signsQuestionData.answers.length - 1}
      >
        <S.SvgArrowRight />
      </S.ArrowButton>
    </S.SignsNavigation>
  );

  return deviceMotionAllowed ? (
    <VRSelector
      maxAngle={120}
      items={vrSelectorItems}
      onSelect={x => setCurrentAnswer(x)}
    />
  ) : (
    <S.SignsSwiperWrapper>
      <S.SignsSwiperSelector
        items={swiperSelectorItems}
        onSelected={x => setCurrentAnswer(x)}
        navigation={{ prev: prevRef.current, next: nextRef.current }}
      />

      {navigation}
    </S.SignsSwiperWrapper>
  );
};

export default SignsQuestionSelector;
