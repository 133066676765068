import styled from 'styled-components';

import AmazonLogo from 'svgs/header/amazonLogo.svg';
import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

import BaseLink from '1_components/BaseLink/BaseLink';
import Typography from '1_components/Typography/Typography';

export const HeaderWrapper = styled.header<{ color: string }>`
  position: absolute;
  inset: 0 0 auto 0;
  display: grid;
  place-items: center;

  height: ${({ theme }) => `${theme.sharedValues.headerHeight}px`};
  border-bottom: 1px solid; // This defaults to the currentColor
  transition: color 0.3s ease-in-out;
  color: ${({ color }) => color};
  z-index: ${zIndex('header')};
`;

export const LogoWrapper = styled(BaseLink)`
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  place-items: center;
`;

export const SvgAmazonLogo = styled(AmazonLogo)`
  width: 96px;
  height: 18.25px;
  transform: translateY(3px);
`;

export const PageTitle = styled(Typography)`
  display: none;

  ${media.desktop} {
    display: inline-block;

    font-size: 13px;
    letter-spacing: 0.1em;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    border-left: 1px solid;
    padding-left: 16px;
  }
`;
