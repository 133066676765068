import { GENRES_ASSETS } from 'assets/genresAssets';
import {
  PERSONALITY_IMAGES,
  PERSONALITY_IMAGES_MOBILE,
} from 'assets/personalityImages';
import {
  GENRES_1x1_ASSETS,
  PERSONALITIES_1x1_ASSETS,
  WRITEUPS_1x1_ASSETS,
} from 'assets/shareAssets';
import { WRITEUPS_BACKGROUNDS } from 'assets/writeupsAssets';

export const quizResultsData = {
  caption: 'Your <mark>Booksona</mark> is',
  title: 'The',

  genresMessage: 'Based on your results, try genres like:',
  readOnMessage: 'Read on to explore your\n unique approach to reading…',

  shareLabel: 'SHARE ON',
  shareButton: 'Share your booksona',
  shareTitle: 'Check out my booksona',
  twitterPostMessage:
    'Discover Your Booksona Now\nTake the quiz to meet your unique inner reader.\nDiscoverYourBooksona.com',

  recommendationMessage:
    'Check out these reads\n handpicked by our editors\n specifically for your Booksona:',
};

export const getResultAssets = (
  [style, motivation, passion]: string[],
  isMobile: boolean
) => ({
  profile: {
    image: (isMobile ? PERSONALITY_IMAGES_MOBILE : PERSONALITY_IMAGES)[style][
      motivation
    ][passion],
    shareImage: PERSONALITIES_1x1_ASSETS[style][motivation][passion],
  },
  writeups: {
    image: WRITEUPS_BACKGROUNDS[isMobile ? 'mobile' : 'desktop'][style],
    shareImage: WRITEUPS_1x1_ASSETS[style][motivation][passion],
  },
  genres: {
    image: GENRES_ASSETS[isMobile ? 'mobile' : 'desktop'][style][passion],
    shareImage: GENRES_1x1_ASSETS[style][passion],
  },
});
