import { Personalities } from '../utils/quizAnswer/personality';

type ShareAssetsTypes = {
  [K in Personalities]?: {
    [K in Personalities]?: string;
  };
};

type BooksonaTypes = {
  [K in Personalities]?: {
    [K in Personalities]?: {
      [K in Personalities]?: string;
    };
  };
};

const GENRES_1x1_ASSETS: ShareAssetsTypes = {
  reliable: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0000_-Reliably-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0001_Reliably-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0002_Reliably-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0003_Reliably-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0004_Reliably-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0005_Reliably-People-Person.png',
  },
  wild: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0006_Wildly-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0007_Wildly-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0008_Wildly-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0009_Wildly-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0010_Wildly-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0011_Wildly-People-Person.png',
  },
  unique: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0012_Uniquely-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0013_Uniquely-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0014_Uniquely-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0015_Uniquely-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0016_Uniquely-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/1x1/BooksonaResults_1x1_0017_Uniquely-People-Person.png',
  },
};

const GENRES_9x16_ASSETS: ShareAssetsTypes = {
  reliable: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0000_-Reliably-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0001_Reliably-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0002_Reliably-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0003_Reliably-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0004_Reliably-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0005_Reliably-People-Person.png',
  },
  wild: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0006_Wildly-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0007_Wildly-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0008_Wildly-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0009_Wildly-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0010_Wildly-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0011_Wildly-People-Person.png',
  },
  unique: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0012_Uniquely-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0013_Uniquely-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0014_Uniquely-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0015_Uniquely-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0016_Uniquely-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/9x16/BooksonaResults_9x16_0017_Uniquely-People-Person.png',
  },
};

const GENRES_16x9_ASSETS: ShareAssetsTypes = {
  reliable: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0000_-Reliably-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0001_Reliably-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0002_Reliably-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0003_Reliably-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0004_Reliably-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0005_Reliably-People-Person.png',
  },
  wild: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0006_Wildly-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0007_Wildly-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0008_Wildly-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0009_Wildly-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0010_Wildly-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0011_Wildly-People-Person.png',
  },
  unique: {
    adventurer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0012_Uniquely-Adventurer.png',
    creator:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0013_Uniquely-Creator.png',
    thrillSeeker:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0014_Uniquely-Thrill-Seeker.png',
    daydreamer:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0015_Uniquely-Daydreamer.png',
    goGetter:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0016_Uniquely-Go-Getter.png',
    peoplePerson:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/genres/16x9/Booksona_Genres_16x9_0017_Uniquely-People-Person.png',
  },
};

const WRITEUPS_16x9_ASSETS: BooksonaTypes = {
  reliable: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0000_Reliably-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0001_Reliably-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0003_Reliably-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0004_Reliably-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0005_Reliably-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0002_Reliably-Curious-Thrill-Seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0006_Reliably-Fun-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0007_Reliably-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0009_Reliably-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0010_Reliably-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0011_Reliably-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0008_Reliably-Fun-Thrill-Seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0012_Reliably-Chill-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0013_Reliably-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0015_Reliably-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0016_Reliably-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0017_Reliably-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0014_Reliably-Chill-Thrill-Seeker.png',
    },
  },
  wild: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0018_Wildly-Curious+-+Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0019_Wildly-Curious+-+Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0021_Wildly-Curious+-+Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0022_Wildly-Curious+-+Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0023_Wildly-Curious+-+People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0020_Wildly-Curious+-+Thrill-Seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0024_Wildly-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0025_Wildly-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0027_Wildly-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0028_Wildly-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0029_Wildly-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0026_Wildly-Fun-Thrill-Seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0030_Wildly-Chill+-+Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0031_Wildly-Chill+-+Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0033_Wildly-Chill+-+Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0034_Wildly-Chill+-+Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0035_Wildly-Chill+-+People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0032_Wildly-Chill+-+Thrill-Seeker.png',
    },
  },
  unique: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0036_Uniquely-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0037_Uniquely-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0039_Uniquely-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0040_Uniquely-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0041_Uniquely-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0038_Uniquely-Curious-Thrill-Seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0042_Uniquely-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0043_Uniquely-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0045_Uniquely-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0046_Uniquely-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0047_Uniquely-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0044_Uniquely-Fun-Thrill-Seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0048_Uniquely-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0049_Uniquely-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0051_Uniquely-Chill-Dreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0052_Uniquely-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0053_Uniquely-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/16x9/Booksona-Writeups_16x9_0050_Uniquely-Chill-Thrill-Seeker.png',
    },
  },
};

const WRITEUPS_1x1_ASSETS: BooksonaTypes = {
  reliable: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0000_Reliably-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0001_Reliably-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0003_Reliably-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0004_Reliably-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0005_Reliably-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0002_Reliably-Curious-Thrill-Seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0006_Reliably-Fun-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0007_Reliably-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0009_Reliably-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0010_Reliably-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0011_Reliably-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0008_Reliably-Fun-Daredevil.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0012_Reliably-Chill-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0013_Reliably-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0015_Reliably-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0016_Reliably-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0017_Reliably-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0014_Reliably-Chill-Thrill-Seeker.png',
    },
  },
  wild: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0018_Wildly-Curious--Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0019_Wildly-Curious--Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0021_Wildly-Curious--Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0022_Wildly-Curious--Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0023_Wildly-Curious--People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0020_Wildly-Curious--Thrill-Seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0024_Wildly-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0025_Wildly-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0027_Wildly-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0028_Wildly-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0029_Wildly-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0026_Wildly-Fun-Thrill-Seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0030_Wildly-Chill--Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0031_Wildly-Chill--Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0033_Wildly-Chill--Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0034_Wildly-Chill--Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0035_Wildly-Chill--People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0032_Wildly-Chill--Thrill-Seeker.png',
    },
  },
  unique: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0036_Uniquely-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0037_Uniquely-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0039_Uniquely-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0040_Uniquely-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0041_Uniquely-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0038_Uniquely-Curious-Thrill-Seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0042_Uniquely-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0043_Uniquely-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0045_Uniquely-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0046_Uniquely-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0047_Uniquely-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0044_Uniquely-Fun-Thrill-Seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0048_Uniquely-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0049_Uniquely-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0051_Uniquely-Chill-Dreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0052_Uniquely-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0053_Uniquely-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0050_Uniquely-Chill-Thrill-Seeker.png',
    },
  },
};

const PERSONALITIES_16x9_ASSETS: BooksonaTypes = {
  reliable: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0000_Reliably-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0001_Reliably-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0003_Reliably-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0004_Reliably-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0005_Reliably-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0002_Reliably-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0006_Reliably-Fun-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0007_Reliably-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0009_Reliably-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0010_Reliably-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0011_Reliably-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0008_Reliably-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0012_Reliably-Chill-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0013_Reliably-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0015_Reliably-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0016_Reliably-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0017_Reliably-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0014_Reliably-Chill-thrill-seeker.png',
    },
  },
  wild: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0018_Wildly-Curious+-+Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0019_Wildly-Curious+-+Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0021_Wildly-Curious+-+Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0022_Wildly-Curious+-+Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0023_Wildly-Curious+-+People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0020_Wildly-Curious+-+thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0024_Wildly-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0025_Wildly-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0027_Wildly-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0028_Wildly-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0029_Wildly-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0026_Wildly-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0030_Wildly-Chill+-+Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0031_Wildly-Chill+-+Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0033_Wildly-Chill+-+Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0034_Wildly-Chill+-+Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0035_Wildly-Chill+-+People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0032_Wildly-Chill+-+thrill-seeker.png',
    },
  },
  unique: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0036_Uniquely-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0037_Uniquely-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0039_Uniquely-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0040_Uniquely-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0041_Uniquely-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0038_Uniquely-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0042_Uniquely-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0043_Uniquely-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0045_Uniquely-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0046_Uniquely-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0047_Uniquely-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0044_Uniquely-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0048_Uniquely-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0049_Uniquely-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0051_Uniquely-Chill-DayDreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0052_Uniquely-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0053_Uniquely-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/16x9/Booksona_Profiles_16x9_0050_Uniquely-Chill-thrill-seeker.png',
    },
  },
};

const PERSONALITIES_1x1_ASSETS: BooksonaTypes = {
  reliable: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/writeups/1x1/BooksonaWriteUps_1x1_0006_Reliably-Fun-_-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Reliably-Chill-thrill-seeker.png',
    },
  },
  wild: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Wildly-Chill-thrill-seeker.png',
    },
  },
  unique: {
    curious: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Curious-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Curious-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Curious-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Curious-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Curious-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Curious-thrill-seeker.png',
    },
    fun: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Fun-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Fun-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Fun-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Fun-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Fun-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Fun-thrill-seeker.png',
    },
    chill: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Chill-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Chill-Creator.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Chill-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Chill-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Chill-People-Person.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/share/booksona/1x1/Booksona_Profiles_1x1_Uniquely-Chill-thrill-seeker.png',
    },
  },
};

export {
  GENRES_1x1_ASSETS,
  GENRES_9x16_ASSETS,
  GENRES_16x9_ASSETS,
  PERSONALITIES_1x1_ASSETS,
  PERSONALITIES_16x9_ASSETS,
  WRITEUPS_1x1_ASSETS,
  WRITEUPS_16x9_ASSETS,
};
