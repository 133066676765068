import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useEffectOnce, useWindowSize } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import BlinkEye from './BlinkEye/BlinkEye';
import { eyeQuestionData } from './EyeQuestion.data';
import * as S from './EyeQuestion.styled';

import PaperRips from '1_components/PaperRips/PaperRips';
import RotationWheel from '1_components/RotationWheel/RotationWheel';

export interface EyeQuestionProps {}

const EyeQuestion: React.FC<EyeQuestionProps> = () => {
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const { setAnswer } = useQuiz();
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    setAnswer({ eyeQuestion: selectedOption });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'light', desktop: 'light' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'rotate',
      desktop: 'rotate',
    });
  });

  const questionTitle = (
    <>
      <S.EyeQuestionTitle
        fontFamily="amazonEmber"
        markdown={eyeQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.darkBlue }}
        renderAs="h1"
      />
      <S.EyeQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={eyeQuestionData.question.mobile}
        underline={{ variant: 'long', color: theme.colors.limeGreen }}
        renderAs="h1"
      />
    </>
  );

  const SvgBg = eyeQuestionData.answers[selectedOption].svg;

  return (
    <S.EyeQuestionWrapper $height={windowHeight}>
      <PaperRips
        primaryColor={theme.colors.limeGreen}
        secondaryColor={theme.colors.darkBlue}
      />
      <S.InteractionWrapper>
        <S.SvgLineWork />

        <S.Inner>
          <S.RingWrapper>
            <AnimatePresence>
              <S.EyeBackground key={`eyeBg ${selectedOption}`} duration={0.4}>
                <SvgBg />
              </S.EyeBackground>
            </AnimatePresence>

            <S.EyeWrapper>
              <BlinkEye selectedOption={selectedOption} />
            </S.EyeWrapper>

            <RotationWheel
              numberOfOptions={3}
              colorVariant="light"
              ringColorVariant="light"
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <S.AnswerWrapper key={selectedOption}>
              <S.AnswerText
                fontFamily="soap"
                text={eyeQuestionData.answers[selectedOption].text}
                renderAs="p"
              />
            </S.AnswerWrapper>
          </S.RingWrapper>
        </S.Inner>
      </S.InteractionWrapper>

      {questionTitle}
    </S.EyeQuestionWrapper>
  );
};

EyeQuestion.displayName = 'EyeQuestion';

export default EyeQuestion;
