import { AnimatePresence } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { useLottieFiles } from '../../hooks/useLottieFiles';
import { hintCardMotionVariants } from './HintCard.motion';
import * as S from './HintCard.styled';
import { HintCardTheme, HintCardVariant } from './HintCard.types';

export interface HintCardProps {
  cardTheme: HintCardTheme;
  variant: HintCardVariant;
  questionIndex: number;
}

const HintCard: FC<HintCardProps> = ({
  cardTheme,
  variant,
  questionIndex,
  ...rest
}) => {
  const [show, setShow] = useState(true);
  const {
    files: { hintCards },
  } = useLottieFiles();
  const animationData = hintCards ? hintCards[cardTheme][variant] : null;

  useEffect(() => {
    setShow(true);
  }, [questionIndex]);

  useEffectOnce(() => {
    const hideCard = () => setShow(false);
    window.addEventListener('pointerdown', hideCard);

    return () => {
      window.removeEventListener('pointerdown', hideCard);
    };
  });

  return (
    <AnimatePresence initial exitBeforeEnter>
      <S.HintCardWrapper
        key={`hintCard ${questionIndex}`}
        initial="initial"
        animate={show ? 'visible' : 'hidden'}
        exit="hidden"
        variants={hintCardMotionVariants}
        {...rest}
      >
        {animationData && (
          <S.LottieComp
            animationData={animationData}
            play
            preserveAspectRatio="xMidYMid meet"
          />
        )}
      </S.HintCardWrapper>
    </AnimatePresence>
  );
};
export default HintCard;
