import styled from 'styled-components';

export const NoiseLayerWrapper = styled.div`
  position: fixed;
  inset: 0;

  mix-blend-mode: screen;

  user-select: none;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
  }
`;
