import styled, { css } from 'styled-components';

import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

export const PAPER_RIP_MOBILE_OUTLINE_SELECTOR =
  '#paperRipQuestionRipsMobileOutline';
export const PAPER_RIP_OUTLINE_SELECTOR = '#paperRipQuestionRipsOutline';
export const PAPER_RIP_MOBILE_SELECTOR = '#paperRipQuestionRipsMobile';
export const PAPER_RIP_SELECTOR = '#paperRipQuestionRips';

export const PAPER_RIPS_SELECTORS = [
  PAPER_RIP_MOBILE_OUTLINE_SELECTOR,
  PAPER_RIP_OUTLINE_SELECTOR,
  PAPER_RIP_MOBILE_SELECTOR,
  PAPER_RIP_SELECTOR,
];

export const PaperRipSliderWrapper = styled.div`
  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  * {
    user-select: none;
  }
`;

export const PaperRipSliderBackground = styled.div`
  position: absolute;
  overflow: hidden;

  width: 100%;
  height: 100%;
`;

export const OverlaySlider = styled(PaperRipSliderBackground)`
  z-index: ${zIndex('overlay')};
`;

export const PaperRipBorders = styled.div`
  position: absolute;
  right: 0;

  width: 100vw;
  height: 100vh;

  background: white;

  clip-path: url(${PAPER_RIP_MOBILE_OUTLINE_SELECTOR});

  ${media.tablet} {
    clip-path: url(${PAPER_RIP_OUTLINE_SELECTOR});
  }
`;

export const PaperSliderClipBox = styled.div`
  overflow: hidden;
  position: absolute;
  right: 0;

  width: 100vw;
  height: 100vh;

  clip-path: url(${PAPER_RIP_MOBILE_SELECTOR});

  ${media.tablet} {
    clip-path: url(${PAPER_RIP_SELECTOR});
  }
`;

const thumbStyle = css`
  -webkit-appearance: none;
  pointer-events: auto;

  height: 100vh;
  width: 50vw;
`;
export const SliderInput = styled.input<{ $isGrabbing: boolean }>`
  position: absolute;
  inset: 0;
  z-index: ${zIndex('overlay')};

  width: 100vw;
  height: 100vh;

  pointer-events: none;
  opacity: 0;

  ::-webkit-slider-thumb {
    cursor: ${({ $isGrabbing }) => ($isGrabbing ? 'grabbing' : 'grab')};
    ${thumbStyle}
  }

  ::-moz-range-thumb {
    cursor: ${({ $isGrabbing }) => ($isGrabbing ? 'grabbing' : 'grab')};
    ${thumbStyle}
  }
`;
