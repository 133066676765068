import { Reorder } from 'framer-motion';
import styled from 'styled-components';

export const ReorderableListWrapper = styled(Reorder.Group)`
  list-style: none;
  * {
    user-select: none;
  }
`;

export const ReorderableListItem = styled(Reorder.Item)`
  cursor: grabbing;
`;
