import { BOOKS } from 'assets/books';
import { getRandomItem } from 'utils';

export const getBooks = results => {
  const personality = results[2];
  const booksData = BOOKS[personality];

  return [
    getRandomItem(booksData['a']),
    getRandomItem(booksData['b']),
    getRandomItem(booksData['c']),
  ];
};
