import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

import RichTypography from '1_components/RichTypography/RichTypography';

export const QuestionWrapper = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  touch-action: none;
`;

export const QuestionTitle = styled(RichTypography)`
  line-height: 120%;
  font-size: min(2.2rem, 3vh);
  font-weight: 500;
  z-index: ${zIndex('overlay')};

  ${media.desktop} {
    font-size: min(4rem, 5vh);
  }
`;
