import NextHead from 'next/head';
import React from 'react';

import theme from 'utils/styles/theme';

import { headData } from './Head.data';

export interface HeadProps {
  title?: string;
  description?: string;
  ogType?: string;
  og?: {
    twitter: string;
    ogImage: string;
  };
}

const Head: React.FC<HeadProps> = ({
  title = headData.title,
  description = headData.description,
  og,
}) => (
  <NextHead>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover, shrink-to-fit=no"
    />

    <meta property="og:type" content="website" />
    <meta name="og:title" property="og:title" content={title} />
    <meta
      name="og:description"
      property="og:description"
      content={description}
    />
    <meta property="og:site_name" content={title} />
    <meta property="og:url" content="" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:site" content="" />
    <meta name="twitter:creator" content="" />
    <meta property="og:image" content={og?.ogImage} />
    <meta name="twitter:image" content={og?.twitter} />

    <meta name="theme-color" content={theme.colors.black} />
    <meta name="msapplication-TileColor" content={theme.colors.black} />
    <link rel="icon" href={'/favicon.ico'} />
    <link
      rel="manifest"
      href={'/manifest.json'}
      crossOrigin="use-credentials"
    />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={'/favicon-32x32.png'}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={'/favicon-16x16.png'}
    />
    <link
      rel="mask-icon"
      href={'/safari-pinned-tab.svg'}
      color={theme.colors.black}
    />
    <meta
      name="apple-mobile-web-app-status-bar-style"
      content="black-translucent"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href={'/apple-touch-icon.png'}
    />
  </NextHead>
);

export default Head;
