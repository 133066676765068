import { Variants } from 'framer-motion';

export const layerVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: { type: 'tween', duration: 0 },
  },
  visible: (delay: number) => ({
    opacity: 1,
    transition: { type: 'tween', duration: 0.4, delay: delay ? delay : 0 },
  }),
};

export const uiVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: { type: 'tween', duration: 0.2 },
  },
  visible: {
    opacity: 1,
    transition: { type: 'tween', duration: 0.3 },
  },
};
