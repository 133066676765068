import CarQuestion from '2_sections/questions/CarQuestion/CarQuestion';
import EyeQuestion from '2_sections/questions/EyeQuestion/EyeQuestion';
import GardenQuestion from '2_sections/questions/GardenQuestion/GardenQuestion';
import KeyholeQuestion from '2_sections/questions/KeyholeQuestion/KeyholeQuestion';
import LeavesQuestion from '2_sections/questions/LeavesQuestion/LeavesQuestion';
import MulticolorHeadQuestion from '2_sections/questions/MulticolorHeadQuestion/MulticolorHeadQuestion';
import PaperRipsBarsQuestion from '2_sections/questions/PaperRipsBarsQuestion/PaperRipsBarsQuestion';
import PaperRipsDotsQuestion from '2_sections/questions/PaperRipsDotsQuestion/PaperRipsDotsQuestion';
import PaperRipsWavesQuestion from '2_sections/questions/PaperRipsWavesQuestion/PaperRipsWavesQuestion';
import ReorderQuestion from '2_sections/questions/ReorderQuestion/ReorderQuestion';
import SignsQuestion from '2_sections/questions/SignsQuestion/SignsQuestion';
import SmileyQuestion from '2_sections/questions/SmileyQuestion/SmileyQuestion';
import SpaceshipQuestion from '2_sections/questions/SpaceshipQuestion/SpaceshipQuestion';

export const orderedQuestions = [
  LeavesQuestion,
  CarQuestion,
  SmileyQuestion,
  EyeQuestion,
  SpaceshipQuestion,
  SignsQuestion,
  ReorderQuestion,
  GardenQuestion,
  PaperRipsBarsQuestion,
  PaperRipsDotsQuestion,
  PaperRipsWavesQuestion,
  KeyholeQuestion,
  MulticolorHeadQuestion,
];
