export const blinkEyeData = {};

export const paths = {
  catEye: {
    viewBox: '0 0 263 187',
    top: 'M82 198C140 95 253 95 320 198',
    bottom: 'M82 197C140 310 253 310 320 197',
    eyeTop: 'M142,200 C171,125 231,125 256,200',
    eyeBottom: 'M142,198 C171,270 231,270 256,198',
  },
  rabbitEye: {
    viewBox: '0 0 247 199',
    top: 'M72 198C120 75 273 75 336 198',
    bottom: 'M72 197C120 320 273 320 336 197',
    eyeTop: 'M142,200 C142,125 254,125 256,200',
    eyeBottom: 'M142,198 C142,270 254,270 256,198',
  },
  eagleEye: {
    viewBox: '0 0 200 200',
    top: 'M72,198 C69,21.4 334,21.4 336,198',
    bottom: 'M72,197 C69,374 334,374 336,197',
    eyeTop: 'M142,200 C142,125 254,125 256,200',
    eyeBottom: 'M142,199 C142,270 254,270 256,199',
  },
};
