export const transition = {
  type: 'spring',
  duration: 0.5,
};

export const BlinkEyeVariants = {
  svg: {
    catEye: {
      rotate: 0,
      scale: 1,
    },
    rabbitEye: {
      rotate: '-25deg',
      scale: 0.9,
    },
    eagleEye: {
      scale: 0.8,
    },
  },
  back: {
    catEye: path => ({
      d: path,
    }),
    rabbitEye: path => ({
      d: path,
      fill: '#BC7D22',
    }),
    eagleEye: path => ({
      d: path,
    }),
  },
  // EYE VARIANTS
  svgEye: {
    catEye: {
      rotate: '90deg',
      scaleX: 0.15,
      scaleY: 1.15,
    },
    rabbitEye: {
      rotate: '90deg',
      scaleX: 0.9,
      scaleY: 0.9,
    },
    eagleEye: {
      rotate: '90deg',
      scaleX: 0.8,
      scaleY: 0.8,
    },
  },

  eye: {
    catEye: path => ({
      d: path,
    }),
    rabbitEye: path => ({
      d: path,
    }),
    eagleEye: path => ({
      d: path,
    }),
  },
};
