import React, { FC, useState } from 'react';

import { isMobile } from 'u9/utils/platform';
import Share from 'utils/quizAnswer/share';

import * as S from './ShareWidget.styled';

import BaseButton from '1_components/Buttons/BaseButton/BaseButton';

export interface ShareWidgetProps {
  url: string;
  title: string;
  message: string;
  imageUrl: string;
  shareThrough: string;
}

const ShareWidget: FC<ShareWidgetProps> = ({
  shareThrough,
  ...props
}: ShareWidgetProps) => {
  const [share] = useState(new Share(props));

  return (
    <S.ShareWidgetWrapper>
      <S.ShareText renderAs="p" fontFamily="amazonEmber">
        SHARE
      </S.ShareText>
      <S.ShareWidget>
        <BaseButton
          aria-share-id={`share_${shareThrough}_${
            isMobile() ? 'instagram' : 'download'
          }`}
          onClick={() => share.shareImage()}
          className="share_button"
        >
          {isMobile() ? (
            <S.SvgInstagram
              aria-share-id={`share_${shareThrough}_instagram`}
              className="share_button"
            />
          ) : (
            <S.SvgDownload
              aria-share-id={`share_${shareThrough}_download`}
              className="share_button"
            />
          )}
        </BaseButton>
        <BaseButton
          aria-share-id={`share_${shareThrough}_facebook`}
          onClick={() => share.facebook()}
          className="share_button"
        >
          <S.SvgFacebook
            aria-share-id={`share_${shareThrough}_facebook`}
            className="share_button"
          />
        </BaseButton>
        <BaseButton
          aria-share-id={`share_${shareThrough}_twitter`}
          onClick={() => share.twitter()}
          className="share_button"
        >
          <S.SvgTwitter
            aria-share-id={`share_${shareThrough}_twitter`}
            className="share_button"
          />
        </BaseButton>
      </S.ShareWidget>
    </S.ShareWidgetWrapper>
  );
};

export default ShareWidget;
