import {
  transform,
  useAnimation,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';

import SvgFreewaySign from 'svgs/questions/car/freeway.svg';
import SvgSlowSign from 'svgs/questions/car/slow.svg';
import SvgStopSign from 'svgs/questions/car/stop.svg';

import * as S from './CarSlider.styled';

export interface CarSliderProps {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  direction: 'x' | 'y';
  numberOfAnswers?: number;
  wrapperSize: {
    width: number;
    height: number;
  };
}

const CarSlider: FC<CarSliderProps> = ({
  value,
  setValue,
  numberOfAnswers = 3,
  direction = 'x',
  wrapperSize,
  ...rest
}) => {
  const answerSliderWrapperRef = useRef<HTMLDivElement>(null);
  const [carRef, { width: carWidth, height: carHeight }] = useElementSize();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const carControls = useAnimation();

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const output = [0, 1, 2];
  const inputX = useMemo(
    () => [0, wrapperSize.width / 2, wrapperSize.width - carWidth],
    [carWidth, wrapperSize.width]
  );
  const inputY = useMemo(
    () => [
      0,
      (-1 * wrapperSize.height) / 2,
      -1 * wrapperSize.height + carHeight,
    ],
    [carHeight, wrapperSize.height]
  );

  const stopSignTranslateX = useTransform(
    x,
    [-20, 0, wrapperSize.width / 2 - carWidth],
    [10, 0, -400]
  );
  const stopSignTranslateY = useTransform(
    y,
    [25, 0, (-1 * wrapperSize.height) / 2 + carHeight],
    [-10, 0, 200]
  );
  const slowSignTranslateX = useTransform(
    x,
    [0, wrapperSize.width / 2 - carWidth / 2, wrapperSize.width - carWidth],
    [500, 0, -500]
  );
  const slowSignTranslateY = useTransform(
    y,
    [
      0,
      (-1 * wrapperSize.height) / 2 + carHeight / 2,
      -1 * wrapperSize.height + carHeight,
    ],
    [-250, 0, 250]
  );

  const freewaySignTranslateX = useTransform(
    x,
    [
      wrapperSize.width / 2 + carWidth / 2,
      wrapperSize.width - carWidth,
      wrapperSize.width - carWidth + 20,
    ],
    [400, 0, -10]
  );
  const freewaySignTranslateY = useTransform(
    y,
    [
      (-1 * wrapperSize.height) / 2,
      -1 * wrapperSize.height + carHeight,

      -1 * wrapperSize.height + carHeight - 25,
    ],
    [-250, 0, 10]
  );

  useEffect(() => {
    function updateNearestAnswer() {
      if (direction !== 'x') return;

      const nearestAnswer = Math.round(
        transform(x.get() + carWidth / 4, inputX, output)
      );
      setValue(nearestAnswer);
    }

    const unsubscribeX = x.onChange(updateNearestAnswer);

    return () => unsubscribeX();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputX, x, carWidth, direction, setValue]);

  useEffect(() => {
    function updateNearestAnswer() {
      if (direction !== 'y') return;

      const nearestAnswer = Math.round(
        transform(y.get() - carHeight / 4, inputY, output)
      );
      setValue(nearestAnswer);
    }

    const unsubscribeY = y.onChange(updateNearestAnswer);

    return () => unsubscribeY();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputY, y, carHeight, direction, setValue]);

  const modifyTargetX = target => {
    if (!window) return target;

    const snapValues = [
      0,
      wrapperSize.width / 2 - carWidth / 2,
      wrapperSize.width - carWidth,
    ];

    return snapValues[value];
  };

  const modifyTargetY = target => {
    if (!window) return target;

    const snapValues = [
      0,
      wrapperSize.height / 2 - carHeight / 2,
      wrapperSize.height - carHeight,
    ];

    return -1 * snapValues[value];
  };

  const handleDotClick = (dotIndex: number) => {
    if (!window) return;

    if (direction === 'x') {
      setValue(dotIndex);

      const snapValues = [
        0,
        wrapperSize.width / 2 - carWidth / 2,
        wrapperSize.width - carWidth,
      ];

      carControls.start({
        x: snapValues[dotIndex],
        transition: { type: 'spring', damping: 15 },
      });
    } else if (direction === 'y') {
      setValue(numberOfAnswers - 1 - dotIndex);

      const snapValues = [
        0,
        wrapperSize.height / 2 - carHeight / 2,
        wrapperSize.height - carHeight,
      ];

      carControls.start({
        y: -1 * snapValues[numberOfAnswers - 1 - dotIndex],
        transition: { type: 'spring', damping: 15 },
      });
    }
  };

  useEffect(() => {
    setValue(0);
    carControls.start({
      y: 0,
      x: 0,
      transition: { duration: 0 },
    });
  }, [direction, carControls, setValue]);

  const dottedLine = (
    <S.DottedLineWrapper
      $direction={direction}
      $offset={direction === 'x' ? carWidth / 2 : carHeight / 2}
    >
      {direction === 'x' ? (
        <S.SvgDottedLineHorizontal />
      ) : (
        <S.SvgDottedLineVertical />
      )}
    </S.DottedLineWrapper>
  );

  const signs = (
    <S.SignsWrapper
      $direction={direction}
      $offset={direction === 'x' ? carWidth / 4 : carHeight / 4}
    >
      <S.StopSignWrapper
        $direction={direction}
        $hidden={value !== 0}
        style={
          direction === 'x'
            ? { x: stopSignTranslateX }
            : { y: stopSignTranslateY }
        }
      >
        <SvgStopSign preserveAspectRatio="xMidYMid meet" />
      </S.StopSignWrapper>
      <S.SlowSignWrapper
        $direction={direction}
        $hidden={value !== 1}
        style={
          direction === 'x'
            ? { x: slowSignTranslateX }
            : { y: slowSignTranslateY, x: '250%' }
        }
      >
        <SvgSlowSign preserveAspectRatio="xMidYMid meet" />
      </S.SlowSignWrapper>
      <S.FreewaySignWrapper
        $direction={direction}
        $hidden={value !== 2}
        style={
          direction === 'x'
            ? { x: freewaySignTranslateX }
            : { y: freewaySignTranslateY }
        }
      >
        <SvgFreewaySign preserveAspectRatio="xMidYMid meet" />
      </S.FreewaySignWrapper>
    </S.SignsWrapper>
  );

  return (
    <S.AnswerSliderWrapper
      ref={answerSliderWrapperRef}
      $direction={direction}
      {...rest}
    >
      {dottedLine}

      {signs}

      <S.KnobWrapper
        id="knob-wrapper"
        key={direction}
        ref={carRef}
        drag={direction}
        dragConstraints={answerSliderWrapperRef}
        dragMomentum={false}
        dragTransition={{
          modifyTarget: direction === 'x' ? modifyTargetX : modifyTargetY,
          timeConstant: 200,
        }}
        animate={carControls}
        style={direction === 'x' ? { x } : { y }}
        $direction={direction}
        whileDrag={{ cursor: 'grabbing' }}
      >
        {direction === 'x' ? <S.SvgCar /> : <S.SvgCarVertical />}
      </S.KnobWrapper>

      {[...Array(numberOfAnswers)].map((_, index) => (
        <S.DotWrapper key={`dot-wrapper-${index}`} $direction={direction}>
          <S.Dot onClick={() => handleDotClick(index)} />
        </S.DotWrapper>
      ))}
    </S.AnswerSliderWrapper>
  );
};

export default CarSlider;
