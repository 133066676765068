import styled, { css } from 'styled-components';

import media from 'utils/styles/mediaQueries';

import BaseButton from '../BaseButton/BaseButton';

import Typography from '1_components/Typography/Typography';

export type PrimaryButtonVariant = 'light' | 'dark' | 'disabled';

const getButtonVariant = (variant: PrimaryButtonVariant) => {
  switch (variant) {
    case 'light':
      return css`
        color: ${({ theme }) => theme.colors.amazonInk};
        background: ${({ theme }) => theme.colors.white};

        &:hover {
          color: ${({ theme }) => theme.colors.amazonInk};
          background: #fff;
        }
      `;
    case 'dark':
      return css`
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.amazonInk};

        &:hover {
          color: ${({ theme }) => theme.colors.white};
          background: #1d2832;
        }
      `;
    case 'disabled':
      return css`
        color: ${({ theme }) => theme.colors.white};
        background: #878787;

        cursor: auto;
      `;
  }
};

export const PrimaryButtonWrapper = styled(BaseButton)<{
  $variant: PrimaryButtonVariant;
}>`
  display: grid;
  place-items: center;

  width: 100%;
  padding: 16px 0;
  border-radius: 100px;
  transition: 0.3s ease-in-out;
  transition-property: background-color, color;

  ${({ $variant }) => getButtonVariant($variant)}

  ${media.desktop} {
    padding: 1.8rem 6.4rem;
    width: unset;
  }
`;

export const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1;
  text-transform: uppercase;

  ${media.desktop} {
    font-size: 16px;
  }
`;
