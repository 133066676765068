import React from 'react';

import * as S from './ReorderableList.styled';

export interface ReorderableListItem {
  key: number;
  value: string;
  component: JSX.Element;
}
export interface ReorderableListProps {
  items: ReorderableListItem[];
  setItems: (items: ReorderableListItem[]) => void;
  axis: 'x' | 'y';
}

const ReorderableList: React.FC<ReorderableListProps> = ({
  items,
  setItems,
  axis,
  ...rest
}) => {
  const reorderableItems = items.map(item => (
    <S.ReorderableListItem key={item.value} value={item} drag>
      {item.component}
    </S.ReorderableListItem>
  ));

  return (
    <S.ReorderableListWrapper
      axis={axis}
      values={items}
      onReorder={setItems}
      {...rest}
    >
      {reorderableItems}
    </S.ReorderableListWrapper>
  );
};

export default ReorderableList;
