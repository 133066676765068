import styled from 'styled-components';

import KnobArrow from 'svgs/interactives/knobArrow.svg';

import { KnobIconColorVariant } from '1_components/KnobIcon/KnobIcon';

export const KnobCircle = styled.div<{
  $colorVariant: KnobIconColorVariant;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  gap: 15%;
  border-radius: 50%;
  background-color: ${({ theme, $colorVariant }) =>
    $colorVariant === 'light' ? theme.colors.white : theme.colors.black};
  color: ${({ theme, $colorVariant }) =>
    $colorVariant === 'light' ? theme.colors.amazonInk : theme.colors.white};
`;

export const SvgKnobRightArrow = styled(KnobArrow)<{ hidden: boolean }>`
  height: 35%;
  pointer-events: none;
  user-select: none;
  transition: opacity 400ms;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`;

export const SvgKnobLeftArrow = styled(SvgKnobRightArrow)`
  transform: rotate(180deg);
`;
