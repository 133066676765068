import Parallax from 'parallax-js';
import React, { FC, useEffect, useRef } from 'react';

import useDeviceMotionAllowed from 'hooks/useDeviceMotionAllowed';

import * as S from './ParallaxWrapper.styled';

export interface ParallaxWrapperProps {
  inverted?: boolean;
  showFor?: S.ParallaxOuterDisplays;
  fullScreen?: boolean;
  relative?: boolean;
  onReady?: () => void;
}

const ParallaxWrapper: FC<ParallaxWrapperProps> = ({
  children,
  inverted = true,
  relative = false,
  onReady,
  ...rest
}) => {
  const parallaxSceneRef = useRef<HTMLDivElement>(null);
  const { deviceMotionAllowed } = useDeviceMotionAllowed();

  useEffect(() => {
    const parallax = new Parallax(parallaxSceneRef?.current, {
      onReady,
    });
    return () => parallax?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceMotionAllowed]);

  return (
    <S.ParallaxOuter
      {...rest}
      ref={parallaxSceneRef}
      relative={relative}
      data-limit-y="30"
      data-limit-x="30"
      data-invert-x={inverted}
      data-invert-y={inverted}
    >
      {children}
    </S.ParallaxOuter>
  );
};
export default ParallaxWrapper;
