import React, { useRef, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { Direction, VRSelectorSceneItem } from './VRSelector.constants';
import * as S from './VRSelector.styled';
import VRSelectorScene from './VRSelectorScene';

export interface VRSelectorProps {
  maxAngle: number;
  withTitle?: boolean;
  items: VRSelectorSceneItem[];
  onSelect: (value: string | null) => void;
  disableHorizontalArrows?: boolean;
}

const VRSelector: React.FC<VRSelectorProps> = ({
  maxAngle,
  withTitle,
  items,
  onSelect,
  disableHorizontalArrows,
}) => {
  const parentRef = useRef(null);
  const [showDirection, setShowDirection] = useState<Direction | null>();

  useEffectOnce(() => {
    const scene = new VRSelectorScene(parentRef.current, {
      items: items,
      maxAngle: maxAngle,
      onHitBoundary: setShowDirection,
      onSelected: onSelect,
      showTitle: withTitle,
    });
    return () => scene.destroy();
  });

  const arrow = (
    <S.VRSelectorArrowWrapper>
      {showDirection === Direction.TOP && <S.ArrowBottom />}
      {showDirection === Direction.BOTTOM && <S.ArrowTop />}
      {!disableHorizontalArrows && (
        <>
          {showDirection === Direction.RIGHT && <S.ArrowLeft />}
          {showDirection === Direction.LEFT && <S.ArrowRight />}
        </>
      )}
    </S.VRSelectorArrowWrapper>
  );
  return (
    <>
      <S.VRSelectorWrapper ref={parentRef} />
      {arrow}
    </>
  );
};

export default VRSelector;
