import styled from 'styled-components';

import MobileTopBackgroundRip from 'svgs/questions/spaceship/mobileTopBackgroundRip.svg';
import MobileGreenLines from 'svgs/questions/spaceship/mobileTopLineRip.svg';
import media from 'utils/styles/mediaQueries';
import {
  mobileQuestionStyles,
  splitQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import ParallaxWrapper from '1_components/ParallaxWrapper/ParallaxWrapper';

export const SpaceshipQuestionWrapper = styled(QuestionWrapper)<{
  $height: number;
}>`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkPurple};

  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};

  @supports (height: 100dvh) {
    height: 100dvh;
  }
`;

export const SpaceshipQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles}
`;

export const SpaceshipQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const TeleportWrapper = styled.div`
  position: absolute;
  top: 25vh;
  right: 0;
  bottom: 0;
  left: 0;

  ${media.desktop} {
    top: 0;
    left: 47vw;
  }
`;

export const MobileBackgroundWrapper = styled(ParallaxWrapper)`
  z-index: ${zIndex('aboveContent')};
`;

export const SvgMobileBackground = styled(MobileTopBackgroundRip)`
  height: 35vh;
  min-height: 275px;
  width: 100%;
`;

export const SvgMobileGreenLines = styled(MobileGreenLines)`
  height: 35vh;
  min-height: 300px;
  width: 100%;
`;
