import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

import EyesIcon from 'svgs/questions/smiley/eyesIcon.svg';
import SmileyIcon from 'svgs/questions/smiley/smileyIcon.svg';
import { zIndex } from 'utils/styles/mixins';

const animateEyeBlink = (step: number) => keyframes`
  0%, 100% {
    opacity: 1;
    transform: translateY(128%) scaleY(0);
  }
  ${`${step}%`}, 100% {
    opacity: 1;
    transform: translateY(128%) scaleY(1);
  }
`;

export const SmileyFaceWrapper = styled.div`
  position: relative;
  display: flex;
  place-content: center;

  width: 100%;
  aspect-ratio: 1/1;
  max-width: 500px;
`;

export const SvgSmileyBackground = styled(SmileyIcon)`
  position: absolute;
  inset: 0;
  z-index: 0;

  width: 100%;
  height: 100%;
`;

export const SvgSmile = styled(motion.svg)`
  position: absolute;

  width: 64%;
  height: 64%;
  transform: translateY(28%);

  z-index: ${zIndex('aboveContent')};
`;

export const SvgEyes = styled(EyesIcon)`
  position: absolute;

  height: 20%;
  pointer-events: none;
  transform: translateY(128%);

  transform-origin: center;
  transform-box: fill-box;
  animation: ${animateEyeBlink(2)} 8s ease infinite 2s;
`;

export const Controller = styled(motion.span)`
  position: absolute;
  inset: 25%;

  width: 100%;
  height: 100%;
  touch-action: pan-y;
  z-index: ${zIndex('overlay')};
`;
