import { Variants } from 'framer-motion';

export const hintCardMotionVariants: Variants = {
  initial: {
    opacity: 0,
    rotate: 15,
    y: 80,
    x: '-50%',
    transition: { type: 'spring', duration: 0.4 },
  },
  visible: {
    opacity: 1,
    rotate: 0,
    y: 0,
    x: '-50%',
    transition: { type: 'spring', duration: 0.6, bounce: 0.4 },
  },
  hidden: {
    opacity: 0,
    rotate: -7,
    y: 80,
    x: '-50%',
    transition: { type: 'spring', duration: 0.4 },
  },
};
