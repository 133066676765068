import React, { FC, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { useLottieFiles } from 'hooks/useLottieFiles';
import { isAppleTablet } from 'u9/utils/platform';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { spaceshipQuestionData } from '../SpaceshipQuestion.data';
import * as S from './Teleport.styled';
import { getScale } from './Teleport.utils';

import SwiperSelector from '1_components/SwiperSelector/SwiperSelector';

export interface TeleportProps {}

const Teleport: FC<TeleportProps> = ({ ...rest }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [scale, setScale] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`);
  const { setAnswer } = useQuiz();

  const {
    files: {
      teleport: { sofa, burger, camera },
    },
  } = useLottieFiles();
  const lotties = [sofa, burger, camera];
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    setAnswer({ spaceshipQuestion: activeIndex });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    setScale(getScale(videoRef));
  }, [setScale]);

  const sliderItems = lotties.map((file, idx) => ({
    value: idx.toString(),
    component: (
      <S.SliderItem>
        <S.LottieComp
          animationData={file}
          loop
          play={activeIndex === idx}
          rendererSettings={{ progressiveLoad: true }}
          preserveAspectRatio="xMidYMid meet"
          $alignLower={idx === 1}
        />
      </S.SliderItem>
    ),
  }));

  const selector = (
    <SwiperSelector
      items={sliderItems}
      onSelected={v => setActiveIndex(Number(v))}
      navigation={{ prev: prevRef.current, next: nextRef.current }}
      smoothTransition
      loop
    />
  );

  const navigation = (
    <S.SpaceshipNavigation>
      <S.ArrowButton as="button" ref={prevRef}>
        <S.SvgArrowLeft />
      </S.ArrowButton>
      <S.ArrowButton as="button" ref={nextRef}>
        <S.SvgArrowRight />
      </S.ArrowButton>
    </S.SpaceshipNavigation>
  );

  const answer = (
    <S.AnswerWrapper key={activeIndex} duration={0.2}>
      <S.AnswerText
        fontFamily="texGyreAdventor"
        text={
          isDesktop
            ? spaceshipQuestionData.answers[activeIndex].text.desktop
            : spaceshipQuestionData.answers[activeIndex].text.mobile
        }
        renderAs="p"
      />
    </S.AnswerWrapper>
  );

  return (
    <S.TeleportWrapper {...rest}>
      <S.VideoWrapper
        ref={videoRef}
        muted
        loop
        playsInline
        preload="auto"
        scale={scale}
        isIpad={isAppleTablet()}
        poster="https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/questions/spaceshipQuestion/ufoPoster.jpeg"
        onCanPlayThrough={() => {
          videoRef.current.play();
        }}
      >
        <source src="/assets/videos/ufo-high.webm" type="video/webm" />
        <source src="/ufo.mp4" type="video/mp4" />
      </S.VideoWrapper>

      <S.SliderContainer>
        {selector}
        {navigation}

        {answer}
      </S.SliderContainer>
    </S.TeleportWrapper>
  );
};
export default Teleport;
