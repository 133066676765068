import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { keyholeQuestionImages } from 'assets/questionsAssets';

import * as S from './KeyholeQuestion.styled';

interface KeyholeComponentProps {
  images: string[];
  isActive?: boolean;
}

export const keyholeAframeItem = ({ images }: KeyholeComponentProps) => `
    <a-image src="${images[0]}" scale="10 10" position="0 -1.6 -14"></a-image>
    <a-image src="${images[1]}" scale="4 4" position="0 -1 -5"></a-image>
    <a-image src="${images[2]}" scale="1.5 1.5" position="0 -0.4 -2.45"></a-image>
    <a-image src="${keyholeQuestionImages.overlay[0]}" position="0 -0.4 -2.3" scale="2.5 2.5"></a-image>
`;

export const KeyholeSwiperComponent: React.FC<KeyholeComponentProps> = ({
  images,
  isActive,
}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    return () => setIsReady(false);
  }, [isActive]);

  const renderComp = (isActive: boolean) => {
    let endAnim = false;

    const component_1 = (
      <S.Keyhole
        animate={{ opacity: isReady ? 0 : 1 }}
        transition={{ duration: 0.4, delay: 0.1 }}
        onAnimationComplete={() => (endAnim = true)}
      >
        <S.KeyholeInner src={images[0]} />
        <S.KeyholeInner src={images[1]} />
        <S.KeyholeInner src={images[2]} />
      </S.Keyhole>
    );

    const component_2 = (
      <>
        {!endAnim && component_1}
        <motion.div
          animate={{ opacity: isReady ? 1 : 0 }}
          transition={{ duration: 0.4 }}
        >
          <S.ParallaxKeyhole onReady={() => setIsReady(true)} relative>
            <S.KeyholeInner src={images[0]} data-depth="0.5" />
            <S.KeyholeInner src={images[1]} data-depth="0.4" />
            <S.KeyholeInner src={images[2]} data-depth="0.2" />
          </S.ParallaxKeyhole>
        </motion.div>
      </>
    );

    return isActive ? component_2 : component_1;
  };

  return (
    <S.KeyholeWrapper>
      <AnimatePresence>{renderComp(isActive)}</AnimatePresence>
      <S.KeyholeOverlay />
    </S.KeyholeWrapper>
  );
};
