import { useAnimation } from 'framer-motion';
import React, {
  Dispatch,
  forwardRef,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useWindowSize } from 'usehooks-ts';

import * as S from './HorizontalSlider.styled';

export interface HorizontalSliderProps {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  direction: 'x' | 'y';
  numberOfAnswers?: number;
}

const HorizontalSlider = forwardRef<HTMLDivElement, HorizontalSliderProps>(
  (
    { value, setValue, numberOfAnswers = 3, direction = 'x', ...rest },
    ref: MutableRefObject<any>
  ) => {
    const [isDragging, setIsDragging] = useState(false);
    const knobControls = useAnimation();
    const { width: windowWidth, height: windowHeight } = useWindowSize();

    const modifyTargetX = target => {
      if (!window || !ref.current) return target;

      const knobWrapperWidth =
        document.getElementById('knob-wrapper').offsetWidth;
      const offset = knobWrapperWidth / 2;

      const centeredTarget = target + offset;
      const trackDistance = (ref.current as HTMLDivElement).offsetWidth;

      const snapValues = [
        0,
        trackDistance / 2 - offset,
        trackDistance - knobWrapperWidth,
      ];

      const origins = [0 + offset, trackDistance / 2, trackDistance - offset];

      const closestOrigin = origins.reduce((prev, curr) =>
        Math.abs(curr - centeredTarget) < Math.abs(prev - centeredTarget)
          ? curr
          : prev
      );

      setValue(origins.indexOf(closestOrigin));
      return snapValues[origins.indexOf(closestOrigin)];
    };

    const modifyTargetY = target => {
      if (!window || !ref.current) return target;

      const knobWrapperWidth =
        document.getElementById('knob-wrapper').offsetWidth;
      const offset = knobWrapperWidth / 2;

      const centeredTarget = target - offset;
      const trackDistance = (ref.current as HTMLDivElement).offsetHeight;

      const snapValues = [
        0,
        trackDistance / 2 - offset,
        trackDistance - knobWrapperWidth,
      ];

      const origins = [
        0 - offset,
        (-1 * trackDistance) / 2,
        -1 * trackDistance + offset,
      ];

      const closestOrigin = origins.reduce((prev, curr) =>
        Math.abs(curr - centeredTarget) < Math.abs(prev - centeredTarget)
          ? curr
          : prev
      );

      setValue(origins.indexOf(closestOrigin));
      return -1 * snapValues[origins.indexOf(closestOrigin)];
    };

    const handleDotClick = (dotIndex: number) => {
      if (!window || !ref.current) return;

      if (direction === 'x') {
        setValue(dotIndex);

        const knobWrapperWidth =
          document.getElementById('knob-wrapper').offsetWidth;
        const offset = knobWrapperWidth / 2;

        const trackDistance = (ref.current as HTMLDivElement).offsetWidth;

        const snapValues = [
          0,
          trackDistance / 2 - offset,
          trackDistance - knobWrapperWidth,
        ];

        knobControls.start({
          x: snapValues[dotIndex],
          transition: { type: 'spring', damping: 15 },
        });
      } else if (direction === 'y') {
        setValue(numberOfAnswers - 1 - dotIndex);

        const knobWrapperHeight =
          document.getElementById('knob-wrapper').offsetHeight;
        const offset = knobWrapperHeight / 2;

        const trackDistance = (ref.current as HTMLDivElement).offsetHeight;

        const snapValues = [
          0,
          trackDistance / 2 - offset,
          trackDistance - knobWrapperHeight,
        ];
        knobControls.start({
          y: -1 * snapValues[numberOfAnswers - 1 - dotIndex],
          transition: { type: 'spring', damping: 15 },
        });
      }
    };

    const line =
      direction === 'x' ? (
        <S.SvgDottedLineHorizontal />
      ) : (
        <S.SvgDottedLineVertical />
      );

    useEffect(() => {
      setValue(0);
      knobControls.start({
        y: 0,
        x: 0,
        transition: { duration: 0 },
      });
    }, [windowWidth, windowHeight, knobControls, setValue]);

    return (
      <S.HorizontalSliderWrapper $direction={direction} {...rest}>
        {line}

        <S.KnobWrapper
          id="knob-wrapper"
          $direction={direction}
          drag={direction}
          dragConstraints={ref as RefObject<Element>}
          dragElastic={false}
          dragMomentum={false}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          animate={knobControls}
          whileDrag={{ cursor: 'grabbing' }}
          dragTransition={{
            modifyTarget: direction === 'x' ? modifyTargetX : modifyTargetY,
            timeConstant: 100,
          }}
        >
          <S.KnobIconComp
            colorVariant="dark"
            showLeftArrow={isDragging || value !== 0}
            showRightArrow={isDragging || value !== numberOfAnswers - 1}
            $direction={direction}
          />
        </S.KnobWrapper>

        {[...Array(numberOfAnswers)].map((_, index) => (
          <S.DotWrapper key={`dot-wrapper-${index}`} $direction={direction}>
            <S.Dot onClick={() => handleDotClick(index)} />
          </S.DotWrapper>
        ))}
      </S.HorizontalSliderWrapper>
    );
  }
);

HorizontalSlider.displayName = 'HorizontalSlider';
export default HorizontalSlider;
