import React, { FC } from 'react';

import { useGlobalState } from 'context/globalState';

import { headerData } from './Header.data';
import * as S from './Header.styled';

import BuildInfoButton from '1_components/BuildInfoButton/BuildInfoButton';

export interface HeaderProps {}

const Header: FC<HeaderProps> = ({ ...rest }) => {
  const [headerColor] = useGlobalState('headerColor');

  return (
    <S.HeaderWrapper {...rest} color={headerColor}>
      <S.LogoWrapper href="/">
        <S.SvgAmazonLogo />

        <S.PageTitle
          text={headerData.title}
          fontFamily="bookerly"
          renderAs="small"
        />
      </S.LogoWrapper>
    </S.HeaderWrapper>
  );
};
export default Header;
