import React, { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';

import SvgNoise from 'svgs/noise/noise.svg';
import SvgNoise9x16 from 'svgs/noise/noise9x16.svg';

import * as S from './NoiseLayer.styled';

export interface NoiseLayerProps {}

const NoiseLayer: FC<NoiseLayerProps> = ({ ...rest }) => {
  const { height, width } = useWindowSize();
  return (
    <S.NoiseLayerWrapper {...rest}>
      {height > width ? (
        <SvgNoise9x16 preserveAspectRatio="xMidYMid slice" />
      ) : (
        <SvgNoise preserveAspectRatio="xMidYMid slice" />
      )}
    </S.NoiseLayerWrapper>
  );
};
export default NoiseLayer;
