import React, { FC } from 'react';

import * as S from './LinkTypography.styled';

import { TypographyProps } from '1_components/Typography/Typography';

export interface LinkTypographyProps extends Omit<TypographyProps, 'renderAs'> {
  href: string;
}

export const LinkTypography: FC<LinkTypographyProps> = ({
  children,
  href,
  fontFamily,
  text,
  ...rest
}) => {
  return (
    <S.StyledTypographyLink href={href} $fontFamily={fontFamily} {...rest}>
      {text ? text : children}
    </S.StyledTypographyLink>
  );
};

export default LinkTypography;
