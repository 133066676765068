export const gardenQuestionData = {
  question: {
    desktop:
      "You're relaxing in the tall<br>grass. What do *<mark>you notice</mark>?*",
    mobile:
      "You're relaxing in the tall grass.<br>What do *<mark>you notice</mark>?*",
  },
  answers: [
    { text: 'A tiny bug\ninvestigating\na flower.', value: 'bug' },
    { text: 'Some kites and\ntheir aerial\nacrobatics.', value: 'birds' },
    {
      text: {
        mobile: 'The distant\nclouds and sky.',
        desktop: 'The distant clouds\nand sky.',
      },
      value: 'clouds',
    },
  ],
};
