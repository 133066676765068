import styled from 'styled-components';

import CircleAroundUnderline from 'svgs/markUnderlines/circleAroundUnderline.svg';
import KnotLongUnderline from 'svgs/markUnderlines/knotLongUnderline.svg';
import media from 'utils/styles/mediaQueries';

export const CustomMarkWrapper = styled.mark<{
  $color: string;
  $markBetweenLines?: boolean;
}>`
  all: unset;

  position: relative;
  display: inline-block;
  z-index: 1;

  user-select: none;
  pointer-events: none;

  * {
    user-select: none;
  }

  svg {
    position: absolute;
    top: ${({ $markBetweenLines }) => ($markBetweenLines ? '95%' : '100%')};

    width: 100%;

    color: ${({ $color }) => $color};
    pointer-events: none;
    z-index: -1;
  }
`;

export const SvgKnotLongUnderline = styled(KnotLongUnderline)`
  max-height: 8px;

  ${media.desktop} {
    max-height: 12px;
  }
`;

export const SvgCircleAroundUnderline = styled(CircleAroundUnderline)`
  transform: translateY(-80%) scale(1.4);
`;
