import { AnimatePresence, motion } from 'framer-motion';
import { findIndex } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { useGlobalState } from 'context/globalState';
import useDeviceMotionAllowed from 'hooks/useDeviceMotionAllowed';
import useQuiz from 'utils/quizAnswer/useQuiz';

import { keyholeQuestionData } from './KeyholeQuestion.data';
import { keyholeAnswerVariants } from './KeyholeQuestion.motion';
import * as S from './KeyholeQuestion.styled';
import {
  keyholeAframeItem,
  KeyholeSwiperComponent,
} from './KeyholeQuestionSelectorComponents';

import VRSelector from '1_components/VRSelector/VRSelector';

const SignsQuestionSelector: React.FC = () => {
  const { deviceMotionAllowed } = useDeviceMotionAllowed();
  const { setAnswer } = useQuiz();
  const [allowSubmit, setAllowSubmit] = useGlobalState('allowSubmit');

  const [currentAnswer, setCurrentAnswer] = useState<string | undefined>();
  const [index, setIndex] = useState(0);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (currentAnswer === undefined) {
      setAllowSubmit(false);
    } else {
      setAllowSubmit(true);
    }

    return () => {
      setAllowSubmit(true);
    };
  }, [currentAnswer, setAllowSubmit]);

  const vrSelectorItems = keyholeQuestionData.answers.map(
    ({ images, ...rest }) => ({
      component: keyholeAframeItem({
        images,
      }),
      ...rest,
    })
  );

  useEffect(() => {
    const current = findIndex(
      keyholeQuestionData.answers,
      item => item.value === currentAnswer
    );
    setIndex(current);
    setAnswer({ keyholeQuestion: current });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswer]);

  const swiperSelectorItems = keyholeQuestionData.answers.map(
    ({ images, value }) => ({
      component: (
        <KeyholeSwiperComponent
          images={images}
          isActive={value === currentAnswer}
        />
      ),
      value,
    })
  );

  const answer = (
    <S.KeyholeAnswerWrapper>
      {keyholeQuestionData.answers.map(answer => (
        <AnimatePresence key={answer.value}>
          {answer.value === currentAnswer && (
            <motion.div
              variants={keyholeAnswerVariants}
              initial="hidden"
              exit="hidden"
              animate="visible"
            >
              <S.KeyholeAnswer
                fontFamily="royals"
                text={answer.message}
                renderAs="h2"
              />
            </motion.div>
          )}
        </AnimatePresence>
      ))}
    </S.KeyholeAnswerWrapper>
  );

  const navigation = (
    <S.KeyholeNavigation>
      <S.ArrowButton as="button" ref={prevRef} disabled={index === 0}>
        <S.SvgArrowLeft />
      </S.ArrowButton>
      <S.ArrowButton
        as="button"
        ref={nextRef}
        disabled={index === keyholeQuestionData.answers.length - 1}
      >
        <S.SvgArrowRight />
      </S.ArrowButton>
    </S.KeyholeNavigation>
  );

  return deviceMotionAllowed ? (
    <VRSelector
      maxAngle={260}
      items={vrSelectorItems}
      onSelect={x => setCurrentAnswer(x)}
      withTitle
      disableHorizontalArrows
    />
  ) : (
    <S.KeyholeSwiperWrapper>
      <S.KeyholeSwiperSelector
        items={swiperSelectorItems}
        onSelected={x => setCurrentAnswer(x)}
        navigation={{ prev: prevRef.current, next: nextRef.current }}
      />
      {answer}

      {navigation}
    </S.KeyholeSwiperWrapper>
  );
};

export default SignsQuestionSelector;
