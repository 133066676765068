export const d1Paths = [
  'M50 208C112 211 173 211 224 208',
  'M25,150 C35,290 240,290 250,150',
  'M50,208 C108.3,158 166.3,158 225,208',
];

const transition = { type: 'spring', duration: 0.3, bounce: 0.2 };

export const SmileyQuestionVariants = {
  longPath: {
    start: arr => {
      return {
        opacity: 1,
        pathLength: 1,
        d: arr[0],
      };
    },
    smile: arr => {
      return {
        d: arr[1],
        pathLength: 1,
        opacity: 1,
        transition: {
          d: transition,
        },
      };
    },
    sad: arr => {
      return {
        d: arr[2],
        pathLength: 1,
        opacity: 1,
        transition: {
          d: transition,
        },
      };
    },
  },
  shortPath: {
    start: right => {
      return {
        transform: right ? 'matrix(1,0,0,1,0,0)' : 'matrix(1,0,0,1,-5,0)',
      };
    },
    smile: right => {
      return {
        transform: right
          ? 'matrix(0.64279, -0.76604, 0.76604, 0.64279, 30.2126, -55.323)'
          : 'matrix(0.64279, 0.76604, -0.76604, 0.64279, -30.724, -55.8796)',
        transition: transition,
      };
    },
    sad: right => {
      return {
        transform: right
          ? 'matrix(0.64279, 0.76604, -0.76604, 0.64279, 4.094, 0.8214)'
          : 'matrix(0.64279, -0.76604, 0.76604, 0.64279, -5.527, 0.889)',
        transition: transition,
      };
    },
  },
};
