import styled, { css } from 'styled-components';

import media from 'utils/styles/mediaQueries';

export type ParallaxOuterDisplays = 'desktop' | 'mobile' | 'default';

const getParallaxOuterDisplays = (display: ParallaxOuterDisplays) => {
  switch (display) {
    case 'mobile':
      return css`
        display: block;

        ${media.desktop} {
          display: none;
        }
      `;
    case 'desktop':
      return css`
        display: none;

        ${media.desktop} {
          display: block;
        }
      `;
    default:
      return '';
  }
};

export const ParallaxOuter = styled.div<{
  showFor?: ParallaxOuterDisplays;
  fullScreen?: boolean;
  relative?: boolean;
}>`
  ${({ relative }) =>
    relative &&
    css`
      position: relative;
      inset: 0;
      z-index: -1;
    `}

  ${({ showFor }) => getParallaxOuterDisplays(showFor)}
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      position: fixed;
      inset: -30px;
    `}
`;
