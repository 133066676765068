import { createGlobalState } from 'react-hooks-global-state';

import { Lotties } from 'assets/lottie';
import theme from 'utils/styles/theme';

import { PrimaryButtonVariant } from '1_components/Buttons/PrimaryButton/PrimaryButton.styles';

import {
  HintCardTheme,
  HintCardVariant,
} from '1_components/HintCard/HintCard.types';

type Responsive<T> = { mobile: T; desktop: T };

interface InitialStateProps {
  headerColor: string;
  progressBarColor: string;
  quizSubmitVariant: PrimaryButtonVariant;
  deviceMotionAllowed: boolean;
  quizHintCardTheme: Responsive<HintCardTheme>;
  quizHintCardVariant: Responsive<HintCardVariant>;
  allowSubmit: boolean;
  lottieReady: boolean;
  lotties?: Lotties;
  answerRefresh: number;
}

const initialState: InitialStateProps = {
  headerColor: theme.colors.white,
  progressBarColor: theme.colors.white,
  quizSubmitVariant: 'dark',
  deviceMotionAllowed: false,
  quizHintCardTheme: { mobile: 'dark', desktop: 'dark' },
  quizHintCardVariant: { mobile: 'rotate', desktop: 'rotate' },
  allowSubmit: false,
  lottieReady: false,
  lotties: null,
  answerRefresh: 0,
};

const { setGlobalState, useGlobalState } = createGlobalState(initialState);

export { setGlobalState, useGlobalState };
