import styled from 'styled-components';

import { fontFaces, FontFamily } from '../Typography/Typography.types';

import BaseLink from '1_components/BaseLink/BaseLink';

interface StyledTypographyProps {
  $fontFamily: FontFamily;
}

export const StyledTypographyLink = styled(BaseLink)<StyledTypographyProps>`
  display: inline;
  color: inherit;
  white-space: pre;
  font-family: ${({ $fontFamily }) => fontFaces[$fontFamily]};
  user-select: none;
`;
