import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import media from 'utils/styles/mediaQueries';
import { mobileQuestionStyles, splitQuestionStyles } from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import Lottie from '1_components/Lottie/Lottie';
import Typography from '1_components/Typography/Typography';

export const GardenQuestionWrapper = styled(QuestionWrapper)`
  background: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.black};
`;

export const GardenQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles}
`;

export const GardenQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const GardenQuestionContentWrapper = styled.div<{ $height: number }>`
  position: absolute;
  inset: ${({ theme }) => theme.sharedValues.headerHeight}px 0 0 0;
  overflow: hidden;
  height: ${({ theme, $height }) =>
    $height
      ? `calc(${$height}px - ${theme.sharedValues.headerHeight}px)`
      : '100vh'};

  ${media.desktop} {
    inset: 0 0 0 47vw;
    height: unset;
  }
`;

export const InnerWrapper = styled.div<{ $height: number }>`
  position: relative;
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : '100vh')};
  top: ${({ theme }) => -1 * theme.sharedValues.headerHeight}px;

  ${media.desktop} {
    top: unset;
  }
`;

export const LottieLayer = styled(Lottie)<{
  $blurStrength: number;
  $hidden?: boolean;
  $moveUp?: boolean;
}>`
  position: absolute;
  inset: 0;

  filter: blur(${({ $blurStrength }) => $blurStrength}px);
  opacity: ${({ $hidden }) => ($hidden ? 0 : 100)};

  transition-property: filter, opacity;
  transition-duration: 0.3s, 0s;
  transition-timing-function: ease-in-out;

  ${({ $moveUp }) =>
    $moveUp &&
    css`
      transform: translateY(-5%);
      height: 105%;

      ${media.tablet} {
        transform: none;
        height: initial;
      }
    `};
`;

export const FadeInWrapper = styled(motion.div)``;

export const AnswerWrapper = styled(motion.div)`
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  user-select: none;

  top: 50%;
  left: 50%;

  ${media.desktop} {
    top: 50%;
    left: 50%;
  }
`;

export const AnswerText = styled(Typography)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  line-height: 120%;
  font-size: 2.4rem;
  pointer-events: none;
  user-select: none;

  ${media.desktop} {
    font-size: 3.2rem;
  }
`;

export const AnswerSliderWrapper = styled(motion.div)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 0;
  left: unset;
  height: 50%;
  width: 64px;

  ${media.desktop} {
    top: 80%;
    left: 50%;
    height: 7.2rem;
    width: 45rem;

    @media (max-height: 600px) {
      width: 60%;
    }
  }
`;
