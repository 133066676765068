import {
  Boundaries,
  CAMERA_ELEMENT,
  Direction,
  MAX_INTERSECTION_DISTANCE,
  overlay,
  title,
  VRSelectorSceneItem,
} from './VRSelector.constants';
import scheme from './VRSelectorScene.scheme';

interface VRSelectorSceneOptions {
  readonly items: VRSelectorSceneItem[];
  readonly maxAngle: number;
  readonly onSelected: (selected: string) => void;
  readonly onHitBoundary: (direction: Direction) => void;
  readonly showTitle?: boolean;
}

const resetRotation = (rotation: number) => {
  if (rotation > 180) return resetRotation(rotation - 360);
  if (rotation < -180) return resetRotation(rotation + 360);
  return rotation;
};

const ARROW_OFFSET = 40;

type AFrameNode = Node & { emit: (value: string) => void };

class VRSelectorScene {
  private scene: string;
  private boundaries: Boundaries;

  private selected: string;
  private currentOverlay: AFrameNode;
  private currentTitle: AFrameNode;

  constructor(
    private readonly wrapper: HTMLDivElement,
    private readonly options: VRSelectorSceneOptions
  ) {
    this.init().then();
  }

  private async init() {
    await this.preload();
    this.createScene();
  }

  private async preload() {
    try {
      global.aframe = await require('aframe');

      this.boundaries = {
        TOP: ARROW_OFFSET,
        BOTTOM: -ARROW_OFFSET,
        RIGHT: -(this.options.maxAngle / 2) - ARROW_OFFSET,
        LEFT: this.options.maxAngle / 2 + ARROW_OFFSET,
      };

      this.registerComponents();
    } catch (error) {
      console.error(error);
    }
  }

  private selectItem(el?: any, name?: string) {
    this.selected = name;
    this.options.onSelected(name);

    this.currentOverlay = el;
    this.currentTitle = this.currentOverlay
      ? (this.wrapper.querySelector(`#${title(name)}`) as unknown as AFrameNode)
      : null;
  }

  private registerComponents() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    this.options.items.forEach(item => {
      global.aframe.registerComponent(overlay(item.value), {
        init: function () {
          this.el.addEventListener('raycaster-intersected', evt => {
            this.raycaster = evt.detail.el;
          });
        },
        tick: function () {
          const currentValue = item.value;
          const intersection =
            this.raycaster?.components.raycaster.getIntersection(this.el);
          if (intersection?.distance < MAX_INTERSECTION_DISTANCE) {
            if (self.selected !== currentValue) {
              self.selectItem(this.el, currentValue);
            }
          } else {
            if (self.selected === currentValue) {
              self.selectItem();
            }
          }
        },
      });
    });

    global.aframe.registerComponent(CAMERA_ELEMENT, {
      tick: function () {
        const { x, y } = this.el.getAttribute('rotation');
        self.handleRotation(x, y);
      },
    });
  }

  private createScene() {
    this.scene = scheme(
      this.options.items,
      this.options.maxAngle,
      this.options.showTitle
    );
    this.wrapper.innerHTML = this.scene;
  }

  private handleRotation(x: number, y: number) {
    const {
      boundaries,
      options: { onHitBoundary },
    } = this;

    if (x > boundaries[Direction.TOP]) return onHitBoundary(Direction.TOP);
    if (x < boundaries[Direction.BOTTOM])
      return onHitBoundary(Direction.BOTTOM);
    if (resetRotation(y) < boundaries[Direction.RIGHT])
      return onHitBoundary(Direction.RIGHT);
    if (resetRotation(y) > boundaries[Direction.LEFT])
      return onHitBoundary(Direction.LEFT);
    return onHitBoundary(null);
  }

  public destroy() {
    const componentNames = [
      CAMERA_ELEMENT,
      ...this.options.items.reduce(
        (prev, { value }) => [...prev, overlay(value), title(value)],
        []
      ),
    ];
    componentNames.forEach(name => {
      delete global.aframe.components[name];
    });
    this.wrapper.innerHTML = '';
    document.querySelector('html').classList.remove('a-fullscreen');
  }
}

export default VRSelectorScene;
