export const spaceshipQuestionData = {
  question: {
    desktop:
      'An alien spacecraft lands<br>in your backyard.<br>They come in peace.<br><mark>*What* </mark>do you offer them?',
    mobile:
      'An alien spacecraft lands in your<br>backyard. They come in peace.<br><mark>*What* </mark>do you offer them?',
  },
  answers: [
    {
      text: {
        desktop: 'A place to relax, they’ve\nhad a long journey.',
        mobile: 'A place to relax,\nthey’ve had a long\njourney.',
      },
      value: 'relax',
    },
    {
      text: {
        desktop: 'A snack.\nIt’s polite to offer friends\nfood while you chat.',
        mobile: 'A snack.\nIt’s polite to offer friends\nfood while you chat.',
      },
      value: 'snack',
    },
    {
      text: {
        desktop: 'A camera.\nWe should document this\nfor proof.',
        mobile: 'A camera.\nWe should document\nthis for proof.',
      },
      value: 'camera',
    },
  ],
};
