import { Personalities } from '../utils/quizAnswer/personality';

type ShareAssetsTypes = {
  [K in Personalities]?: {
    [K in Personalities]?: string;
  };
};

export const GENRES_ASSETS: Record<'mobile' | 'desktop', ShareAssetsTypes> = {
  mobile: {
    reliable: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0000_-Reliably-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0001_Reliably-Creator.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0002_Reliably-Thrill-Seeker.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0003_Reliably-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0004_Reliably-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0005_Reliably-People-Person.png',
    },
    wild: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0006_Wildly-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0007_Wildly-Creator.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0008_Wildly-Thrill-Seeker.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0009_Wildly-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0010_Wildly-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0011_Wildly-People-Person.png',
    },
    unique: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0012_Uniquely-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0013_Uniquely-Creator.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0014_Uniquely-Thrill-Seeker.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0015_Uniquely-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0016_Uniquely-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/mobile/BooksonaResults_9x16_0017_Uniquely-People-Person.png',
    },
  },
  desktop: {
    reliable: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0000_-Reliably-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0001_Reliably-Creator.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0002_Reliably-Thrill-Seeker.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0003_Reliably-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0004_Reliably-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0005_Reliably-People-Person.png',
    },
    wild: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0006_Wildly-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0007_Wildly-Creator.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0008_Wildly-Thrill-Seeker.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0009_Wildly-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0010_Wildly-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0011_Wildly-People-Person.png',
    },
    unique: {
      adventurer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0012_Uniquely-Adventurer.png',
      creator:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0013_Uniquely-Creator.png',
      thrillSeeker:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0014_Uniquely-Thrill-Seeker.png',
      daydreamer:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0015_Uniquely-Daydreamer.png',
      goGetter:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0016_Uniquely-Go-Getter.png',
      peoplePerson:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/genres/desktop/Booksona_GenresWeb_16x9_0017_Uniquely-People-Person.png',
    },
  },
};
