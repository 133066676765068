export const fontFaces = {
  // eslint-disable-next-line quotes
  texGyreAdventor:
    "'TeX Gyre Adventor', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  junicode: "'Junicode', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  amazonEmber: "'AmazonEmber', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  basteleur: "'Basteleur', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  royals: "'Royals', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  leagueGothic:
    "'LeagueGothic', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  soap: "'Soap', 'Helvetica Neue', Helvetica, Arial, sans-serif",

  bookerly: "'Bookerly', 'Helvetica Neue', Helvetica, Arial, sans-serif",
};

export type FontFamily = keyof typeof fontFaces;
