import styled, { css } from 'styled-components';

import ArrowIcon from 'svgs/interactives/arrow.svg';
import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';
import theme from 'utils/styles/theme';

import BaseButton from '1_components/Buttons/BaseButton/BaseButton';
import FadeInOut from '1_components/FadeInOut/FadeInOut';
import Lottie from '1_components/Lottie/Lottie';
import Typography from '1_components/Typography/Typography';

export const TeleportWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;

  width: 100%;
  height: ${({ theme }) =>
    `calc(100% - ${theme.sharedValues.mobileQuizSubmitBottomOffset + 8}px)`};

  ${media.desktop} {
    height: 100%;
  }
`;

export const SliderContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: ${zIndex('aboveContent')};

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    display: none;
  }

  ${media.desktop} {
    button {
      display: initial;
    }
  }
`;

export const SliderItem = styled.div`
  position: relative;
  display: flex;

  width: 100%;
  height: 100%;

  ${media.desktop} {
    width: 100%;
    height: 100%;
  }
`;

export const LottieComp = styled(Lottie)<{ $alignLower?: boolean }>`
  width: 100%;
  height: 130%;
  transform: translateY(-10%) scale(1.5);
  position: absolute;
  width: 100%;
  height: 100%;

  ${media.mobileSmall} {
    transform: translateY(0%) scale(1.3);
  }

  ${media.desktop} {
    height: 100%;

    transform: scale(135%);
  }

  ${({ $alignLower }) =>
    $alignLower &&
    css`
      top: 10%;

      ${media.desktop} {
        top: 15%;
      }
    `};
`;

export const AnswerWrapper = styled(FadeInOut)`
  position: relative;
  height: 30%;

  ${media.desktop} {
    height: 45%;
  }
`;

export const AnswerText = styled(Typography)`
  line-height: 120%;
  font-size: 1.9rem;
  color: ${theme.colors.sapGreen};
  z-index: ${zIndex('overlay')};

  ${media.desktop} {
    font-size: 3.2rem;
  }
`;

export const VideoWrapper = styled.video<{
  scale: number;
  isIpad: boolean;
  ref: any;
}>`
  position: fixed;
  top: 25vh;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: fill;
  width: 100vw;
  height: 75vh;
  min-width: 100vw;
  min-height: 75vh;

  user-select: none;
  touch-action: none;
  pointer-events: none;

  ${({ isIpad, scale }) =>
    isIpad &&
    css`
      transform-origin: top center;
      transform: ${`scale(${scale})`};
    `}

  ${media.desktop} {
    top: 0;
    left: 47vw;
    height: 100vh;
    width: 53vw;

    min-width: 53vw;
    min-height: 100vh;
  }
`;

export const SpaceshipNavigation = styled.div`
  display: none;

  ${media.desktop} {
    position: absolute;
    display: flex;
    justify-content: space-around;

    height: fit-content;
    z-index: ${zIndex('aboveContent')};

    width: 100%;
    inset: 50% 0;
    pointer-events: none;
  }
`;

export const ArrowButton = styled(BaseButton)`
  cursor: pointer;
  pointer-events: all;
  color: ${theme.colors.sapGreen};
`;

export const SvgArrowLeft = styled(ArrowIcon)`
  color: ${theme.colors.sapGreen};
  transform: rotate(180deg);
`;

export const SvgArrowRight = styled(ArrowIcon)`
  color: ${theme.colors.sapGreen};
`;
