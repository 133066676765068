import Eye from 'svgs/questions/multiColorHead/eye.svg';
import Smiley from 'svgs/questions/multiColorHead/smiley.svg';
import Star from 'svgs/questions/multiColorHead/star.svg';
import Thunder from 'svgs/questions/multiColorHead/thunder.svg';

export const multicolorHeadQuestionData = {
  question: {
    desktop: 'Which do you<br><mark>*desire most?*</mark>',
    mobile: 'Which do you <mark>*desire most?*</mark>',
  },
  answers: [
    {
      text: 'To summon\ngreat magic',
      value: 'magic',
      icon: Star,
      logic: 'daydreamer',
    },
    {
      text: 'To uncover\nthe unknown',
      value: 'uncovering-unknown',
      icon: Eye,
      logic: 'adventurer',
    },
    {
      text: 'To face\nyour fears',
      value: 'facing-fears',
      icon: Thunder,
      logic: 'thrillSeeker',
    },
    {
      text: 'To be\nunderstood',
      value: 'understanding',
      icon: Smiley,
      logic: 'peoplePerson',
    },
    {
      text: 'To grow\nand evolve',
      value: 'understanding',
      icon: Eye,
      logic: 'goGetter',
    },
    {
      text: 'To be moved\nby a work of art',
      value: 'understanding',
      icon: Thunder,
      logic: 'creator',
    },
  ],
};
