import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import { useLottieFiles } from 'hooks/useLottieFiles';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { paperRipsBarsQuestionData } from './PaperRipsBarsQuestion.data';
import * as S from './PaperRipsBarsQuestion.styled';

import PaperRips from '1_components/PaperRips/PaperRips';
import PaperRipSlider from '1_components/PaperRipSlider/PaperRipSlider';

export interface PaperRipsBarsQuestionProps {}

const PaperRipsBarsQuestion: React.FC<PaperRipsBarsQuestionProps> = ({
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState<number>(-1);
  const { setAnswer, getAnswer } = useQuiz();
  const {
    files: {
      paperRipsQuestions: { lines },
    },
  } = useLottieFiles();

  useEffect(() => {
    setGlobalState('allowSubmit', selectedOption !== -1);
    setAnswer({
      paperRipsBarsQuestion: selectedOption,
    });

    return () => {
      getAnswer('paperRipsBarsQuestion') === -1 &&
        setAnswer({ paperRipsBarsQuestion: 0 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'light', desktop: 'light' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'dragHorizontal',
      desktop: 'dragHorizontal',
    });
    setGlobalState('allowSubmit', false);
  });

  const questionTitle = (
    <>
      <S.Caption
        fontFamily="amazonEmber"
        markdown={paperRipsBarsQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h3"
      />
      <S.CaptionMobile
        fontFamily="amazonEmber"
        markdown={paperRipsBarsQuestionData.question.mobile}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h3"
      />
    </>
  );

  const leftSide = {
    value: paperRipsBarsQuestionData.answers[0].value,
    component: (
      <S.LeftSideWrapper>
        <S.LeftLottie animationData={lines} play preserveAspectRatio="none" />
        <PaperRips variant="paperSplit" primaryColor={theme.colors.red} />
        <S.LeftTitle
          text={paperRipsBarsQuestionData.answers[0].text}
          fontFamily="leagueGothic"
          renderAs="h2"
        />
      </S.LeftSideWrapper>
    ),
  };

  const rightSide = {
    value: paperRipsBarsQuestionData.answers[1].value,
    component: (
      <S.RightSideWrapper>
        <S.RightLottie animationData={lines} play preserveAspectRatio="none" />
        <PaperRips variant="paperSplit" primaryColor={theme.colors.pink} />
        <S.RightTitle
          text={paperRipsBarsQuestionData.answers[1].text}
          fontFamily="leagueGothic"
          renderAs="h2"
        />
      </S.RightSideWrapper>
    ),
  };

  return (
    <S.BarsQuestionWrapper {...rest}>
      {questionTitle}

      <PaperRipSlider
        answers={{ leftAnswer: leftSide, rightAnswer: rightSide }}
        paperRips={paperRipsBarsQuestionData.paperRips}
        onSelect={u =>
          setSelectedOption(
            paperRipsBarsQuestionData.answers.indexOf(
              paperRipsBarsQuestionData.answers.find(({ value }) => value === u)
            )
          )
        }
      />
    </S.BarsQuestionWrapper>
  );
};

PaperRipsBarsQuestion.displayName = 'PaperRipsBarsQuestion';

export default PaperRipsBarsQuestion;
