import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { multicolorHeadQuestionData } from './MulticolorHeadQuestion.data';
import {
  MultiColorHeadAnswerVariants,
  MultiColorHeadVariants,
} from './MulticolorHeadQuestion.motion';
import * as S from './MulticolorHeadQuestion.styled';
import { filteredAnswers, getLogicKey } from './MulticolorHeadQuestion.utils';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface MulticolorHeadQuestionProps {
  logics: string[];
}

const MulticolorHeadQuestion: React.FC<MulticolorHeadQuestionProps> = ({
  logics,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const { setAnswer } = useQuiz();
  const answers = useMemo(() => {
    return filteredAnswers(logics, multicolorHeadQuestionData.answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAnswer({
      multicolorHeadQuestion: getLogicKey(answers, selectedOption),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers, selectedOption]);

  useEffectOnce(() => {
    setGlobalState('allowSubmit', true);
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.amazonInk);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'light', desktop: 'light' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'rotate',
      desktop: 'rotate',
    });
  });

  const questionTitle = (
    <>
      <S.MulticolorHeadQuestionTitle
        fontFamily="amazonEmber"
        markdown={multicolorHeadQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h1"
      />
      <S.MulticolorHeadQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={multicolorHeadQuestionData.question.mobile}
        underline={{ variant: 'knotLong', color: theme.colors.amazonInk }}
        renderAs="h1"
      />
    </>
  );

  const Icon = answers[selectedOption].icon;
  const icons = (
    <S.IconsWrapper>
      <Icon />
      <Icon />
    </S.IconsWrapper>
  );

  const headLayers = [...Array(4)].map((_, index) => (
    <S.HeadWrapper
      key={`multicolorHeadWrapper-${index}`}
      variants={MultiColorHeadVariants.headsWrapper[index]}
      initial="initial"
      animate={
        index === 0
          ? selectedOption > index
            ? 'animate'
            : 'initial'
          : selectedOption >= index
          ? 'animate'
          : 'initial'
      }
    >
      <S.SvgHead />
    </S.HeadWrapper>
  ));

  const answer = (
    <AnimatePresence>
      <S.AnswerWrapper
        key={answers[selectedOption].text}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={MultiColorHeadAnswerVariants}
      >
        {icons}
        <S.Answer
          text={answers[selectedOption].text}
          fontFamily="texGyreAdventor"
          renderAs="p"
        />
      </S.AnswerWrapper>
    </AnimatePresence>
  );
  return (
    <S.MulticolorHeadQuestionWrapper>
      <PaperRips
        primaryColor={theme.colors.red}
        secondaryColor={theme.colors.white}
      />
      {questionTitle}

      <S.InteractionWrapper>
        {headLayers}
        <S.RotationWheelComp
          numberOfOptions={4}
          colorVariant="light"
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        {answer}
      </S.InteractionWrapper>
    </S.MulticolorHeadQuestionWrapper>
  );
};

MulticolorHeadQuestion.displayName = 'MulticolorHeadQuestion';

export default MulticolorHeadQuestion;
