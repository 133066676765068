import paperRipQuestionSvg from 'svgs/questions/paperRipSliderQuestions/dots/desktop.svg';
import paperRipQuestionMobileSvg from 'svgs/questions/paperRipSliderQuestions/dots/mobile.svg';
import paperRipQuestionOutlineSvg from 'svgs/questions/paperRipSliderQuestions/dots/outlineDesktop.svg';
import paperRipQuestionMobileOutlineSvg from 'svgs/questions/paperRipSliderQuestions/dots/outlineMobile.svg';

export const paperRipsDotsQuestionData = {
  question: {
    desktop: 'Follow your <mark>*intuition*</mark>.',
    mobile: 'Follow your <mark>*intuition*</mark>.',
  },
  answers: [
    { text: 'an endless\nhorizon', value: 'endless-horizon' },
    { text: 'a blank\ncanvas', value: 'blank-canvas' },
  ],
  paperRips: [
    paperRipQuestionMobileSvg,
    paperRipQuestionSvg,
    paperRipQuestionOutlineSvg,
    paperRipQuestionMobileOutlineSvg,
  ],
};
