import { animate, motion } from 'framer-motion';
import { clamp, uniqueId } from 'lodash';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useEffectOnce, useWindowSize } from 'usehooks-ts';

import * as S from './PaperRipSlider.styled';
import { PAPER_RIPS_SELECTORS } from './PaperRipSlider.styled';

export interface PaperRipChild {
  component: JSX.Element;
  value: string;
}
export interface PaperRipSliderProps {
  answers: {
    leftAnswer: PaperRipChild;
    rightAnswer: PaperRipChild;
  };
  paperRips: React.FunctionComponent<any>[];
  onSelect: (selected?: string) => void;
}

const INITIAL_OVERLAY_WIDTH = 50;
const SLIDER_BOUNDS = [20, 80];
const SLIDER_BOUNDS_MOBILE = [20, 100];

const PaperRipSlider: React.FC<PaperRipSliderProps> = ({
  answers,
  onSelect,
  paperRips,
  ...rest
}) => {
  const wrapperRef = useRef(null);
  const rangeRef = useRef<HTMLInputElement>(null);
  const [overlayWidth, setOverlayWidth] = useState(INITIAL_OVERLAY_WIDTH);
  const [isDragging, setIsDragging] = useState(false);

  const { width } = useWindowSize();
  const theme = useTheme();

  const isTablet = width >= theme.breakpoints.tablet;
  const bounds = isTablet ? SLIDER_BOUNDS : SLIDER_BOUNDS_MOBILE;

  useEffectOnce(() => {
    onSelect(null);

    const missing = [];
    PAPER_RIPS_SELECTORS.forEach(selector => {
      if (!wrapperRef.current.querySelector(selector)) missing.push(selector);
    });
    if (missing.length)
      throw new Error(
        `paperRips prop of PaperRipSlider is missing svg files with following clip-path ids: ${missing}`
      );
  });

  const updateValue = (value: number) => {
    setOverlayWidth(value);
    if (rangeRef.current) rangeRef.current.value = String(value);
  };

  const onSliderChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    updateValue(Number(value));
  };

  const onDragEnd = () => {
    setIsDragging(false);

    let direction;

    if (overlayWidth > 50) {
      direction = bounds[1];
      onSelect(answers.leftAnswer.value);
    } else {
      direction = bounds[0];
      onSelect(answers.rightAnswer.value);
    }

    animate(overlayWidth, direction, {
      onUpdate: v => updateValue(v),
      type: 'spring',
    });
  };

  return (
    <S.PaperRipSliderWrapper {...rest} ref={wrapperRef}>
      {paperRips.map(paperRip =>
        React.createElement(paperRip, {
          style: { position: 'absolute' },
          key: uniqueId(),
        })
      )}

      <S.PaperRipSliderBackground>
        {answers.rightAnswer.component}
      </S.PaperRipSliderBackground>

      <S.OverlaySlider
        as={motion.div}
        style={{ x: `-${clamp(100 - overlayWidth, 0, 100)}%` }}
        dragTransition={{ bounceDamping: 8 }}
      >
        <S.PaperRipBorders />
        <S.PaperSliderClipBox>
          <motion.div
            style={{ x: `${clamp(100 - overlayWidth, 0, 100)}%` }}
            dragTransition={{ bounceDamping: 8 }}
          >
            {answers.leftAnswer.component}
          </motion.div>
        </S.PaperSliderClipBox>
      </S.OverlaySlider>

      <S.SliderInput
        ref={rangeRef}
        type="range"
        min={bounds[0]}
        max={bounds[1]}
        step={isDragging ? '0.01' : bounds[1] - bounds[0]}
        defaultValue={INITIAL_OVERLAY_WIDTH}
        onChange={onSliderChange}
        onPointerDown={() => setIsDragging(true)}
        onPointerUp={onDragEnd}
        onTouchStart={() => setIsDragging(true)}
        onTouchEnd={onDragEnd}
        $isGrabbing={isDragging}
      />
    </S.PaperRipSliderWrapper>
  );
};

export default PaperRipSlider;
