import React from 'react';
import { Navigation } from 'swiper';
import { EffectCreative } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { useEffectOnce } from 'usehooks-ts';

import * as S from './SwiperSelector.styled';

import 'swiper/css';
import 'swiper/css/effect-fade';

interface SwiperSelectorItem {
  value: string;
  component: JSX.Element;
}
export interface SwiperSelectorProps {
  items: SwiperSelectorItem[];
  loop?: boolean;
  navigation?: {
    prev: HTMLElement;
    next: HTMLElement;
  };
  onSelected: (value: string) => void;
  smoothTransition?: boolean;
}

const smoothAppearanceEffect = {
  effect: 'creative',
  creativeEffect: {
    prev: {
      translate: ['-40%', 0, 0],
      opacity: 0,
    },
    next: {
      translate: ['40%', 0, 0],
      opacity: 0,
    },
  },
};
const SwiperSelector: React.FC<SwiperSelectorProps> = ({
  items,
  loop,
  navigation,
  onSelected,
  smoothTransition,
  ...rest
}) => {
  const onSlideChange = ({ realIndex }) => onSelected(items[realIndex].value);

  useEffectOnce(() => onSelected(items[0].value));

  const effect = smoothTransition ? smoothAppearanceEffect : {};
  return (
    <S.SwiperSelectorSlider
      navigation={{
        prevEl: navigation?.prev,
        nextEl: navigation?.next,
      }}
      modules={[Navigation, EffectCreative]}
      initialSlide={0}
      onSlideChange={onSlideChange}
      slidesPerView={1}
      loop={loop}
      {...effect}
      {...rest}
    >
      {items.map(item => (
        <SwiperSlide key={item.value}>{item.component}</SwiperSlide>
      ))}
    </S.SwiperSelectorSlider>
  );
};

export default SwiperSelector;
