import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

type variant = 'light' | 'dark';

const usePreferredColorScheme = () => {
  const [scheme, setScheme] = useState<variant>('light');
  useEffectOnce(() =>
    setScheme(
      window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light'
    )
  );
  return [scheme];
};

export default usePreferredColorScheme;
