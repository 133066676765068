import React from 'react';

import * as S from './PaperRips.styled';

type PaperVariant = 'split' | 'detail' | 'paperSplit' | 'result';
export interface PaperRipsProps {
  primaryColor?: string;
  secondaryColor?: string;
  variant?: PaperVariant;
  hideSideRip?: boolean;
}

const PaperRips: React.FC<PaperRipsProps> = ({
  primaryColor,
  secondaryColor,
  hideSideRip,
  variant = 'split',
}) => {
  const renderPapers = (variant: PaperVariant) => {
    switch (variant) {
      case 'split':
        return (
          <>
            <S.ParallaxBox fullScreen showFor="mobile">
              <S.SvgMobileRips data-depth="0.3" color={primaryColor} />
            </S.ParallaxBox>

            <S.ParallaxBox fullScreen showFor="desktop">
              {!hideSideRip && (
                <S.SvgSideRip data-depth="0.05" color={primaryColor} />
              )}
              <S.SvgPrimaryRips data-depth="0.2" color={primaryColor} />
              <S.SvgSecondaryRips data-depth="0.1" color={secondaryColor} />
            </S.ParallaxBox>
          </>
        );

      case 'detail':
        return (
          <>
            <S.ParallaxBox fullScreen showFor="mobile">
              <S.SvgMobileRips data-depth="0.3" color={primaryColor} />
            </S.ParallaxBox>
            <S.ParallaxBox fullScreen showFor="desktop">
              <S.SvgPrimaryRips data-depth="0.2" color={primaryColor} />
              <S.SvgSecondaryRips data-depth="0.1" color={primaryColor} />
            </S.ParallaxBox>
          </>
        );

      case 'paperSplit':
        return (
          <S.ParallaxBox fullScreen showFor="desktop">
            <S.PaperQuestionPrimaryRips data-depth="0.1" color={primaryColor} />
            <S.PaperQuestionSecondaryRips
              data-depth="0.2"
              color={primaryColor}
            />
          </S.ParallaxBox>
        );

      case 'result':
        return (
          <>
            <S.ParallaxBox fullScreen showFor="mobile">
              <S.PaperRipsResultMobile data-depth="0.3" color={primaryColor} />
            </S.ParallaxBox>

            <S.ParallaxBox fullScreen showFor="desktop">
              <S.PaperRipsResultDesktop data-depth="0.2" color={primaryColor} />
            </S.ParallaxBox>
          </>
        );
    }
  };

  return renderPapers(variant);
};

export default PaperRips;
