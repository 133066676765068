export const keyholeQuestionImages = {
  overlay: ['/assets/images/questions/keyholeQuestion/keyHole.png'],
  theArtistStudio: [
    '/assets/images/questions/keyholeQuestion/ArtistStudio/BACK.png',
    '/assets/images/questions/keyholeQuestion/ArtistStudio/MID.png',
    '/assets/images/questions/keyholeQuestion/ArtistStudio/TOP.png',
  ],
  theDeepestDungeon: [
    '/assets/images/questions/keyholeQuestion/DeepestDungeon/BACK.png',
    '/assets/images/questions/keyholeQuestion/DeepestDungeon/MID.png',
    '/assets/images/questions/keyholeQuestion/DeepestDungeon/TOP.png',
  ],
  diningHall: [
    '/assets/images/questions/keyholeQuestion/DiningHall/BACK.png',
    '/assets/images/questions/keyholeQuestion/DiningHall/MID.png',
    '/assets/images/questions/keyholeQuestion/DiningHall/TOP.png',
  ],
  library: [
    '/assets/images/questions/keyholeQuestion/Library/BACK.png',
    '/assets/images/questions/keyholeQuestion/Library/MID.png',
    '/assets/images/questions/keyholeQuestion/Library/TOP.png',
  ],
  mysticalStudy: [
    '/assets/images/questions/keyholeQuestion/MysticalStudy/BACK.png',
    '/assets/images/questions/keyholeQuestion/MysticalStudy/MID.png',
    '/assets/images/questions/keyholeQuestion/MysticalStudy/TOP.png',
  ],
  theSunnyGarden: [
    '/assets/images/questions/keyholeQuestion/SunnyGarden/BACK.png',
    '/assets/images/questions/keyholeQuestion/SunnyGarden/MID.png',
    '/assets/images/questions/keyholeQuestion/SunnyGarden/TOP.png',
  ],
};

export const signsQuestionImages = {
  curious: '/assets/images/questions/signsQuestion/Curious.png',
  chill: '/assets/images/questions/signsQuestion/Chill.png',
  fun: '/assets/images/questions/signsQuestion/Fun.png',
};
