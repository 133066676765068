import styled, { css } from 'styled-components';

import Download from 'svgs/interactives/download.svg';
import Facebook from 'svgs/socials/Facebook.svg';
import Instagram from 'svgs/socials/Instagram.svg';
import Twitter from 'svgs/socials/Twitter.svg';
import media from 'utils/styles/mediaQueries';

import Typography from '1_components/Typography/Typography';

export const ShareWidgetWrapper = styled.div`
  display: grid;
  row-gap: 8px;
  transform: scale(1.4);

  ${media.mobileSmall} {
    transform: none;
  }

  ${media.desktop} {
    transform: none;
  }
`;

export const ShareText = styled(Typography)`
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.5;
  letter-spacing: 0.05em;
  line-height: 120%;

  font-size: 16px;
`;

export const ShareWidget = styled.div`
  display: flex;
  justify-content: space-around;

  padding: 0 20px;

  width: 20.8rem;
  height: 4.8rem;
  max-width: 208px;
  max-height: 48px;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 50px;
  transform: none;
  transform-origin: initial;

  button {
    display: grid;
    place-items: center;
  }

  ${media.mobileSmall} {
    transform-origin: top;
    transform: scale(1.4);
  }
`;

const buttonStyles = css`
  height: 60%;

  color: ${({ theme }) => theme.colors.black};
  transition: color 0.5s;
`;

export const SvgDownload = styled(Download)`
  transform: scale(0.7);
  ${buttonStyles}

  &:hover {
    color: ${({ theme }) => theme.colors.midGreen};
  }
`;

export const SvgFacebook = styled(Facebook)`
  ${buttonStyles}

  &:hover {
    color: ${({ theme }) => theme.colors.facebook};
  }
`;

export const SvgInstagram = styled(Instagram)`
  ${buttonStyles}

  &:hover {
    color: ${({ theme }) => theme.colors.instagram};
  }
`;

export const SvgTwitter = styled(Twitter)`
  ${buttonStyles}

  &:hover {
    color: ${({ theme }) => theme.colors.twitter};
  }
`;
