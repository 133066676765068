import { motion } from 'framer-motion';
import styled from 'styled-components';

import Leaves from 'svgs/questions/leaves/leaves.svg';
import media from 'utils/styles/mediaQueries';
import { mobileQuestionStyles, splitQuestionStyles } from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import RotationWheel from '1_components/RotationWheel/RotationWheel';
import Typography from '1_components/Typography/Typography';

export const LeavesQuestionWrapper = styled(QuestionWrapper)<{
  $height: number;
}>`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  display: grid;
  place-items: center;
  height: ${({ $height }) => ($height ? `${$height}px` : '100vh')};
`;

export const LeavesQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles};
`;

export const LeavesQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const LeavesWrapper = styled(motion.div)`
  position: absolute;
  display: grid;
  place-items: center;
  pointer-events: none;
  user-select: none;
  mix-blend-mode: multiply;
  top: -7.5%;
  transition: opacity 0.3 ease-in-out;

  width: 100vw;
  height: calc(100% + 7.5%);

  ${media.tablet} {
    height: 115vh;
  }

  ${media.desktop} {
    height: 100vh;
    left: 25%;
    top: 2.5%;
  }
`;

export const SvgLeaves = styled(Leaves)`
  height: 100%;

  ${media.desktop} {
    transform: scale(1.3);
  }
`;

export const InteractionWrapper = styled.div`
  position: absolute;
  display: grid;
  place-items: center;

  width: 100%;
  margin: 0 12px;
  transform: translateY(8px);
  max-width: 375px;

  ${media.desktop} {
    inset: 0 0 0 50vw;

    width: initial;
    max-width: initial;
    transform: initial;

    margin: 12vw;
  }
`;

export const AnswerWrapper = styled(motion.div)`
  position: absolute;
`;

export const Answer = styled(Typography)`
  font-size: 3.6rem;
  line-height: 1.1;

  ${media.desktop} {
    font-size: 4.4rem;
  }
`;

export const RotationWheelComp = styled(RotationWheel)`
  position: absolute;
`;
