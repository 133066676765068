import React, { FC } from 'react';

import { FadeInOutVariants } from './FadeInOut.motion';
import * as S from './FadeInOut.styled';

export interface FadeInOutProps {
  duration?: number;
}

const FadeInOut: FC<FadeInOutProps> = ({
  children,
  duration = 0.3,
  ...rest
}) => {
  return (
    <S.Wrapper
      {...FadeInOutVariants.transition}
      transition={{ duration: duration, ease: 'easeInOut' }}
      {...rest}
    >
      {children}
    </S.Wrapper>
  );
};
export default FadeInOut;
