import Ball from 'svgs/questions/eye/ball.svg';
import Bushes from 'svgs/questions/eye/bushes.svg';
import Clouds from 'svgs/questions/eye/clouds.svg';

export const eyeQuestionData = {
  question: {
    desktop: "Poof! You're an animal.<br><mark>*Which animal*</mark> are you?",

    mobile: "Poof! You're an animal.<br><mark>*Which animal*</mark> are you?",
  },
  answers: [
    { text: 'A CONTENTED\nHOUSE CAT', value: 'cat', svg: Ball },
    { text: 'A GARDEN\nRABBIT', value: 'rabbit', svg: Bushes },
    { text: 'A WILD\nFALCON', value: 'falcon', svg: Clouds },
  ],
};
