import { compiler } from 'markdown-to-jsx';
import React, { FC } from 'react';

import CustomMark from '1_components/CustomMark/CustomMark';
import { CustomMarkVariant } from '1_components/CustomMark/CustomMark.types';
import Typography, {
  TypographyProps,
} from '1_components/Typography/Typography';

export interface RichTypographyProps
  extends Exclude<TypographyProps, { text: string }> {
  markdown: string;
  underline?: {
    variant: CustomMarkVariant;
    color: string;
  };
  underlineBetweenTextLines?: boolean;
}

export const RichTypography: FC<RichTypographyProps> = ({
  markdown,
  underline,
  underlineBetweenTextLines,
  ...rest
}) => {
  const compiledText = compiler(markdown, {
    wrapper: null,
    overrides: {
      mark: underline && {
        component: CustomMark,
        props: {
          variant: underline.variant,
          color: underline.color,
          underlineBetweenTextLines,
        },
      },
    },
  });

  return <Typography {...rest}>{compiledText}</Typography>;
};

RichTypography.displayName = 'RichTypography';
export default RichTypography;
