import { useEffectOnce } from 'usehooks-ts';

import { lottiePaths, Lotties } from 'assets/lottie';
import { useGlobalState } from 'context/globalState';

export const useLottieFiles = () => {
  const [lotties, setLotties] = useGlobalState('lotties');
  const [lottieReady, setLottieReady] = useGlobalState('lottieReady');

  const fetchJSON = async (url: string) => {
    const res = await fetch(url);

    if (!res.ok) throw new Error(`Couldn't fetch ${res}`);
    return res.json();
  };

  const fetchRecursively = async el => {
    const files = {};
    for await (const k of Object.keys(el)) {
      files[k] =
        typeof el[k] === 'object'
          ? await fetchRecursively(el[k])
          : await fetchJSON(el[k] as string);
    }
    return files;
  };

  useEffectOnce(() => {
    if (!lottieReady)
      fetchRecursively(lottiePaths).then((e: Lotties) => {
        setLotties(e);
        setLottieReady(true);
      });
  });

  return { ready: lottieReady, files: lotties };
};
