import { Personalities } from '../utils/quizAnswer/personality';

type PersonalityAnswersCopyTypes = {
  [K in Personalities]: {
    subtitle: string;
    title: string;
    description: string;
  };
};

export const PERSONALITY_ANSWERS_COPY: PersonalityAnswersCopyTypes = {
  reliable: {
    subtitle: '<mark>How</mark> you like to read',
    title: 'Reliably',
    description:
      'You’re dedicated, detailed, and pretty organized. Reading-wise, try making a to-read list and keeping track of exciting titles. Set aside dedicated reading time every day, week, month—or whatever works for you! A little structure could go a long way towards making you a happy and consistent reader.',
  },
  unique: {
    subtitle: '<mark>How</mark> you like to read',
    title: 'Uniquely',
    description:
      'A little order. A little chaos. You pick and choose what works for you, and you know what feels right. So, try out a variety of reading habits and see what sticks. Ambitious goals? Read without pressure. Or challenge yourself and see if you rise to the occasion. Mix it up with a new schedule, or a new genre. Because trying it all has always been the best way to find what works best for you.',
  },
  wild: {
    subtitle: '<mark>How</mark> you like to read',
    title: 'Wildly',
    description:
      'You’re a free spirit who can’t be tied down. When it comes to reading habits, a rigid approach may never feel right—so, throw it out the window! Struggling to finish a book? Cut it loose! Always on the go? Drop the hardback and try a Kindle. Finding your groove might mean forgetting what you think you know about the right way to read.',
  },
  curious: {
    subtitle: '<mark>Why</mark> you like to read',
    title: 'Curious',
    description:
      'Simply put, you love to learn—and not just in stuffy lecture halls. You’re a student of life with a sense of adventure and like to dive deep into the world around you. In books (and in life) you feel a need to explore.',
  },
  fun: {
    subtitle: '<mark>Why</mark> you like to read',
    title: 'Fun',
    description:
      'You see books as a source of endless entertainment. You may have reached for a book over a tv show or a movie in the past because nothing beats getting lost in a great story. No matter the genre, you’re sure to let your imagination run wild as you explore worlds as fun and fantastic as you are.',
  },
  chill: {
    subtitle: '<mark>Why</mark> you like to read',
    title: 'Chill',
    description:
      'You read to relax, to disconnect from whatever’s stressing you out, and get in touch with yourself. For you, reading is me-time and there’s nothing quite like the feeling when the real world melts away and it’s just you and the words on the page.',
  },
  daydreamer: {
    subtitle: '<mark>What</mark> you like to read',
    title: 'Daydreamer',
    description:
      'You like stories that involve magic, imagination, fantastic realities, and other-worldly adventures.',
  },
  peoplePerson: {
    subtitle: '<mark>What</mark> you like to read',
    title: 'People-person',
    description:
      'You like stories about humanity and human connection!  Also: relationships, growth, and maybe a little love, too.',
  },
  adventurer: {
    subtitle: '<mark>What</mark> you like to read',
    title: 'Adventurer',
    description:
      'You like stories that let you explore the real world. Books about travel, real and remarkable people, and interesting journeys.',
  },
  creator: {
    subtitle: '<mark>What</mark> you like to read',
    title: 'Creator',
    description:
      'You turn to books for inspiration and enjoy learning about art and creative expression.',
  },
  goGetter: {
    subtitle: '<mark>What</mark> you like to read',
    title: 'Go-getter',
    description:
      'You like learning, advancing and evolving and turn to books that teach you new life skills and inspire you on your journey to becoming who you are.',
  },
  thrillSeeker: {
    subtitle: '<mark>What</mark> you like to read',
    title: 'Thrill-seeker',
    description:
      'You like stories with elements of action, intrigue—and maybe a little fear now and again—that get your adrenaline pumping and your mind racing.',
  },
};
