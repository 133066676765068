import paperRipQuestionSvg from 'svgs/questions/paperRipSliderQuestions/waves/desktop.svg';
import paperRipQuestionMobileSvg from 'svgs/questions/paperRipSliderQuestions/waves/mobile.svg';
import paperRipQuestionOutlineSvg from 'svgs/questions/paperRipSliderQuestions/waves/outlineDesktop.svg';
import paperRipQuestionMobileOutlineSvg from 'svgs/questions/paperRipSliderQuestions/waves/outlineMobile.svg';

export const paperRipsWavesQuestionData = {
  question: {
    desktop: "Don't *<mark>overthink</mark>* it.",
    mobile: "Don't *<mark>overthink</mark>* it.",
  },
  answers: [
    { text: 'a feeling\nof pride', value: 'pride' },
    { text: 'a rush of\nadrenaline', value: 'adrenaline' },
  ],
  paperRips: [
    paperRipQuestionMobileSvg,
    paperRipQuestionSvg,
    paperRipQuestionOutlineSvg,
    paperRipQuestionMobileOutlineSvg,
  ],
};
