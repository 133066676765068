import { css } from 'styled-components';

import media from './mediaQueries';

export const vh = (value: number) => {
  return `calc(var(--vh) * ${value})`;
};

export const zIndex = (
  id: 'aboveContent' | 'overlay' | 'aboveOverlay' | 'header' | 'absoluteTop'
) => {
  const order = [
    '',
    'aboveContent',
    'overlay',
    'aboveOverlay',
    'header',
    'absoluteTop',
  ];
  return order.indexOf(id) * 100;
};

export const splitQuestionStyles = css`
  display: none;

  ${media.desktop} {
    display: block;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
`;

export const middleQuestionStyles = css`
  display: none;

  ${media.desktop} {
    display: block;
    position: absolute;
    top: ${({ theme }) =>
      `min(calc(${theme.sharedValues.headerHeight}px + 6rem),calc(${theme.sharedValues.headerHeight}px + 5vh))`};
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const mobileQuestionStyles = css`
  position: absolute;
  top: ${({ theme }) =>
    `min(calc(${theme.sharedValues.headerHeight}px + 4rem),calc(${theme.sharedValues.headerHeight}px + 4vh))`};
  left: 50%;
  transform: translateX(-50%);

  ${media.mobileSmall} {
    top: ${({ theme }) =>
      `min(calc(${theme.sharedValues.headerHeight}px + 2.5rem),calc(${theme.sharedValues.headerHeight}px + 3vh))`};
  }

  ${media.tablet} {
    top: ${({ theme }) =>
      `min(calc(${theme.sharedValues.headerHeight}px + 6.4rem),calc(${theme.sharedValues.headerHeight}px + 4vh))`};
  }

  ${media.desktop} {
    display: none;
  }
`;

export const largeHeadingStyles = css`
  text-transform: uppercase;
  font-weight: 400;
  line-height: 0.9;
  font-size: min(9rem, 24vh);

  ${media.desktop} {
    font-size: min(20rem, 24vh);

    margin-top: ${({ theme }) => theme.sharedValues.headerHeight}px;
  }
`;
