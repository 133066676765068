import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';
import { mobileQuestionStyles, splitQuestionStyles } from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';
import SmileyFace from './SmileyFace/SmileyFace';

export const SmileyQuestionWrapper = styled(QuestionWrapper)`
  background: ${({ theme }) => theme.colors.pink};
  color: ${({ theme }) => theme.colors.black};
`;

export const SmileyQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles}
`;

export const SmileyQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const SmileyWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;

  padding: 5vw;

  ${media.desktop} {
    inset: 0 0 0 50vw;

    padding: 8vw;
  }
`;

export const SmileyFaceComp = styled(SmileyFace)`
  position: absolute;
  max-height: 64vh;
`;
