import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';
import {
  largeHeadingStyles,
  middleQuestionStyles,
  mobileQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import Lottie from '1_components/Lottie/Lottie';
import Typography from '1_components/Typography/Typography';

export const BarsQuestionWrapper = styled(QuestionWrapper)``;

export const Caption = styled(QuestionTitle)`
  ${middleQuestionStyles}
  z-index: ${zIndex('aboveOverlay')};
  pointer-events: none;

  svg {
    transform: scale(1.2);
  }
`;

export const CaptionMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
  font-size: 2.8rem;
  z-index: ${zIndex('aboveOverlay')};
  pointer-events: none;

  svg {
    transform: scale(1.2);
  }
`;

///////////////////////////////////////////////
// Left
//////////////////////////////////////////////

export const LeftSideWrapper = styled(QuestionWrapper)`
  display: grid;
  place-items: center;

  background: ${({ theme }) => theme.colors.pink};
`;

export const LeftTitle = styled(Typography)`
  position: absolute;
  ${largeHeadingStyles}
  color: ${({ theme }) => theme.colors.black};
  pointer-events: none;
`;

export const LeftLottie = styled(Lottie)`
  position: absolute;
  inset: 0;

  pointer-events: none;
  will-change: auto;
  transform: scaleX(-1);

  height: 150vh;
  width: 130vw;
  opacity: 0.3;

  ${media.desktop} {
    width: unset;
  }
`;

///////////////////////////////////////////////
// RightSide
//////////////////////////////////////////////

export const RightSideWrapper = styled(LeftSideWrapper)`
  background: ${({ theme }) => theme.colors.red};
`;

export const RightTitle = styled(LeftTitle)`
  color: ${({ theme }) => theme.colors.white};
`;

export const RightLottie = styled(LeftLottie)`
  transform: unset;
`;
