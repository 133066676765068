import { signsQuestionImages } from 'assets/questionsAssets';

export const signsQuestionData = {
  question: {
    desktop:
      'You step into the lobby<br>of a grand museum.<br><mark>*Which sign* </mark>do you follow?',
    mobile:
      'You step into the lobby<br>of a grand museum.<br><mark>*Which sign* </mark>do you follow?',
  },
  answers: [
    {
      value: 'zen-garden',
      image: signsQuestionImages.curious,
      message: 'curious',
    },
    {
      value: 'ancient-mysteries',
      image: signsQuestionImages.chill,
      message: 'curious',
    },
    {
      value: 'short-film',
      image: signsQuestionImages.fun,
      message: 'curious',
    },
  ],
};
