import theme from 'utils/styles/theme';

export const carQuestionData = {
  question: {
    desktop:
      "A friend proposes a spontaneous<br>road trip to somewhere you've never<br>been. No plans. Just adventure and<br>the open road. *<mark>You in</mark>?*",
    mobile:
      "A friend proposes a spontaneous<br>road trip to somewhere you've<br>never been. No plans.<br>Just adventure and the open road.<br>*<mark>You in</mark>?*",
  },
  backgroundColors: [
    theme.colors.red,
    theme.colors.yellow,
    theme.colors.midGreen,
  ],
  answers: [{ value: 'stop' }, { value: 'slow' }, { value: 'freeway' }],
};
