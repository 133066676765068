import type { AppProps } from 'next/app';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useEffectOnce, useMediaQuery } from 'usehooks-ts';

import {
  keyholeQuestionImages,
  signsQuestionImages,
} from 'assets/questionsAssets';
import useDeviceMotionAllowed from 'hooks/useDeviceMotionAllowed';
import usePreload from 'hooks/usePreload';
import { isMobile, isTablet } from 'u9/utils/platform';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';

import { useLottieFiles } from '../hooks/useLottieFiles';

import 'utils/styles/fontFace.css';
import Layout from '2_sections/Layout/Layout';
import RotateDevice from '2_sections/NonFunctionals/RotateDevice/RotateDevice';
import PreloaderOverlay from '2_sections/PreloaderOverlay/PreloaderOverlay';

const images = [
  'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/questions/spaceshipQuestion/ufoPoster.jpeg',
  ...Object.values(keyholeQuestionImages).flat(1),
  ...Object.values(signsQuestionImages),
];

const videos = [];

const fonts = [
  '/fonts/AmazonEmber/Amazon-Ember-Medium.woff2',
  '/fonts/AmazonEmber/Amazon-Ember-MediumItalic.woff2',
  '/fonts/AmazonEmber/AmazonEmber_Lt.woff2',
  '/fonts/AmazonEmber/AmazonEmber_Rg.woff2',
  '/fonts/AmazonEmber/AmazonEmber_RgIt.woff2',
  '/fonts/Basteleur/Basteleur-Bold.woff2',
  '/fonts/Junicode/Junicode-BoldCondensed.woff2',
  '/fonts/LeagueGothic/LeagueGothic-Regular.woff2',
  '/fonts/Royals/Royals-Regular.woff2',
  '/fonts/Soap/Soap.woff2',
  '/fonts/TexGyreAdventor/TexGyreAdventor-Bold.woff2',
  '/fonts/Bookerly/Bookerly.woff2',
];

const App = ({ Component, pageProps }: AppProps) => {
  const [loaded] = usePreload({ images, videos, fonts });

  const { ready } = useLottieFiles();

  const { requestDeviceMotionPermissions } = useDeviceMotionAllowed();

  useEffectOnce(() => {
    requestDeviceMotionPermissions();
  });

  const isDesktopMediaQuery = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktop}px)`
  );

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyles />

        <Layout
          PageComponent={loaded && ready ? Component : PreloaderOverlay}
          pageProps={pageProps}
        />

        {(isMobile() || (isTablet() && !isDesktopMediaQuery)) && (
          <RotateDevice />
        )}
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
