import { HTMLMotionProps } from 'framer-motion';
import React, { FC } from 'react';
import { useWindowSize } from 'usehooks-ts';

import usePreferredColorScheme from 'hooks/usePreferredColorScheme';

import * as S from './PreloaderOverlay.styled';
import {
  PreloaderVariant,
  preloaderVariantSettings,
} from './PreloaderOverlay.types';

import Lottie from '1_components/Lottie/Lottie';

export interface PreloaderOverlayProps extends HTMLMotionProps<'div'> {
  variant?: PreloaderVariant;
}

const PreloaderOverlay: FC<PreloaderOverlayProps> = ({ variant, ...rest }) => {
  const [scheme] = usePreferredColorScheme();
  const { height: windowHeight } = useWindowSize();

  return (
    <S.PreloaderOverlayWrapper {...rest} $variant={variant || scheme}>
      <S.InnerWrapper $height={windowHeight}>
        <S.Noise />

        <S.AnimationWrapper>
          <Lottie
            play
            animationData={
              preloaderVariantSettings[variant || scheme].animationData
            }
          />
        </S.AnimationWrapper>
      </S.InnerWrapper>
    </S.PreloaderOverlayWrapper>
  );
};

export default PreloaderOverlay;
