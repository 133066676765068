import React, { FC } from 'react';
import { useEffectOnce, useWindowSize } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import useDeviceMotionAllowed from 'hooks/useDeviceMotionAllowed';
import useDisableScroll from 'hooks/useDisableScroll';
import pixels from 'utils/pixels';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { landingData } from './Landing.data';
import * as S from './Landing.styled';

export interface LandingProps {}

const Landing: FC<LandingProps> = ({ ...rest }) => {
  const { requestDeviceMotionPermissions } = useDeviceMotionAllowed();
  const { height: windowHeight } = useWindowSize();
  const { resetQuiz, startQuiz } = useQuiz();

  useEffectOnce(() => {
    resetQuiz();
    pixels();
    setGlobalState('headerColor', theme.colors.white);
  });

  useDisableScroll();

  const legalLinks = landingData.links.map(({ text, href }) => (
    <S.LegalLink key={text} text={text} href={href} fontFamily="amazonEmber" />
  ));

  const handleStartQuiz = () => {
    startQuiz();
  };

  return (
    <S.LandingWrapper
      {...rest}
      onTouchEnd={requestDeviceMotionPermissions}
      $height={windowHeight}
    >
      <S.ParallaxBox showFor="desktop" fullScreen>
        <S.SvgBackgroundDesktopLeft data-depth="0.05" />
        <S.SvgBackgroundDesktopRightBack data-depth="0.1" />
        <S.SvgBackgroundDesktopRight data-depth="0.15" />
      </S.ParallaxBox>

      <S.ParallaxBox showFor="mobile" fullScreen>
        <S.SvgBackgroundMobileLeft data-depth="0.1" />
        <S.SvgBackgroundMobileRightBack data-depth="0.2" />
        <S.SvgBackgroundMobileRight data-depth="0.3" />
      </S.ParallaxBox>

      <S.Inner>
        <S.Title fontFamily="soap" text={landingData.title} renderAs="h1" />

        <S.SvgUnderline />

        <S.Description
          fontFamily="amazonEmber"
          text={landingData.body}
          renderAs="p"
        />
      </S.Inner>

      <S.StartButton
        href={landingData.button.href}
        text={landingData.button.label}
        onClick={handleStartQuiz}
        id="landing_start_button"
      />

      <S.DesktopFooterWrapper>
        <S.LegalLinksWrapper>{legalLinks} </S.LegalLinksWrapper>
      </S.DesktopFooterWrapper>

      <S.MobileFooterWrapper>
        <S.StartButtonMobile
          href={landingData.button.href}
          text={landingData.button.label}
          onClick={handleStartQuiz}
          id="landing_start_button"
        />
        <S.LegalLinksWrapper>{legalLinks}</S.LegalLinksWrapper>
      </S.MobileFooterWrapper>

      <S.Noise />
    </S.LandingWrapper>
  );
};
export default Landing;
