import styled from 'styled-components';

import DesktopArrows from 'svgs/questions/reorder/desktopArrows.svg';
import MobileArrows from 'svgs/questions/reorder/mobileArrows.svg';
import media from 'utils/styles/mediaQueries';
import {
  middleQuestionStyles,
  mobileQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import ReorderableListComponent from '1_components/ReorderableList/ReorderableList';

export const ReorderQuestionWrapper = styled(QuestionWrapper)`
  position: relative;
  display: grid;
  place-items: center;
  color: ${({ theme }) => theme.colors.black};

  background: ${({ theme }) => theme.colors.lightPurple};

  * {
    user-select: none;
  }
`;

export const ReorderQuestionTitle = styled(QuestionTitle)`
  ${middleQuestionStyles}
`;

export const ReorderQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const ReorderableListWrapper = styled.div`
  position: relative;
`;

export const ReorderableList = styled(ReorderableListComponent)`
  display: grid;
  place-items: center;
  row-gap: 10vh;

  text-transform: uppercase;

  ${media.desktop} {
    grid-auto-flow: column;
    column-gap: 10vw;
  }
`;

export const OrderNumbers = styled.ul`
  list-style: none;
  position: absolute;

  display: grid;
  place-items: center;
  row-gap: 10vh;

  width: 180%;
  height: 100%;
  inset: 0 40% 0 -40%;

  ${media.desktop} {
    position: relative;

    width: 100%;
    inset: 0;

    grid-auto-flow: column;
    column-gap: 10vw;

    margin: 5vh 0 -5vh;
  }
`;

export const OrderNumber = styled.li`
  color: ${({ theme }) => theme.colors.white};

  &:nth-child(odd) {
    margin-right: auto;
  }

  &:nth-child(even) {
    margin-left: auto;
  }

  ${media.desktop} {
    margin: auto;
  }
`;

export const SvgMobileArrows = styled(MobileArrows)`
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  z-index: ${zIndex('aboveOverlay')};
  pointer-events: none;

  ${media.desktop} {
    display: none;
  }
`;

export const SvgDesktopArrows = styled(DesktopArrows)`
  display: none;

  ${media.desktop} {
    position: absolute;
    inset: 0;
    display: block;

    width: 100%;
    height: 100%;
    z-index: ${zIndex('aboveOverlay')};
    pointer-events: none;
  }
`;
