import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

import NoiseLayer from '1_components/NoiseLayer/NoiseLayer';

export const QuizResultsWrapper = styled.div`
  display: grid;
  place-items: center;
  row-gap: 64px;

  background-color: black;
  color: white;
  min-height: 100vh;
  margin: 0 0 82px;

  ${media.desktop} {
    margin: 0 0 209px;
  }
`;

export const Noise = styled(NoiseLayer)`
  min-height: 100vh;
  z-index: ${zIndex('overlay')};
`;

export const Content = styled.article`
  width: 100%;
  z-index: ${zIndex('overlay')};
`;
