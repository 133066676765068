import { motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';

import useQuiz from 'utils/quizAnswer/useQuiz';

import { d1Paths, SmileyQuestionVariants } from './SmileyFace.motion';
import * as S from './SmileyFace.styled';

export interface SmileyFaceProps {}

const SmileyFace: FC<SmileyFaceProps> = ({ ...rest }) => {
  const [dragStartValue, setDragStartValue] = useState<number>(0);
  const [animationNumber, setAnimationNumber] = useState<number>(0);
  const { setAnswer } = useQuiz();

  const animationNames = Object.keys(SmileyQuestionVariants.longPath);

  useEffect(() => {
    const answerNumbers = {
      0: 1,
      1: 0,
      2: 2,
    };

    setAnswer({ smileyQuestion: answerNumbers[animationNumber] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationNumber]);

  const onDrag = event => {
    const dragY = event.clientY;
    if (dragStartValue - dragY > 40) setAnimationNumber(2);
    else if (dragY - dragStartValue > 40) setAnimationNumber(1);
    else setAnimationNumber(0);
  };

  return (
    <S.SmileyFaceWrapper {...rest}>
      <S.SvgSmileyBackground />
      <S.SvgEyes />

      <S.SvgSmile
        viewBox="0 0 277 277"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial="start"
        animate={animationNames[animationNumber]}
        custom={d1Paths}
        variants={SmileyQuestionVariants.longPath}
      >
        <motion.path
          d="M50 208C112 211 173 211 224 208"
          stroke="#030504"
          strokeWidth="8.18049"
          custom={d1Paths}
          variants={SmileyQuestionVariants.longPath}
        />
        <motion.path
          d="M59.9004 195.976C56.7839 197.493 53.984 201.138 52.7255 205.835C51.467 210.531 52.0693 215.088 54.01 217.96"
          stroke="#030504"
          strokeWidth="8.18049"
          strokeLinecap="round"
          custom={false}
          variants={SmileyQuestionVariants.shortPath}
        />
        <motion.path
          d="M222.683 218.648C224.624 215.776 225.226 211.22 223.968 206.523C222.709 201.826 219.909 198.181 216.793 196.665"
          stroke="#030504"
          strokeWidth="8.18049"
          strokeLinecap="round"
          custom={true}
          variants={SmileyQuestionVariants.shortPath}
        />
      </S.SvgSmile>

      <S.Controller
        drag="y"
        dragConstraints={{ top: 100, bottom: 100 }}
        dragElastic={0.1}
        onDrag={onDrag}
        onPointerDown={e => {
          setDragStartValue(e.clientY);
        }}
        whileTap={{ cursor: 'grabbing' }}
        whileHover={{ cursor: 'grab' }}
      />
    </S.SmileyFaceWrapper>
  );
};
export default SmileyFace;
