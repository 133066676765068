import { keyholeQuestionImages } from 'assets/questionsAssets';

export const keyholeQuestionData = {
  question: {
    desktop:
      'A hallway of <mark>endless </mark><br>possibilities. *Which door*<br>do you open?',
    mobile:
      'A hallway <mark>of endless </mark>possibilities.<br>*Which door* do you open?',
  },
  answers: [
    {
      value: 'study',
      images: keyholeQuestionImages.mysticalStudy,
      message: 'The mystical\nstudy',
    },
    {
      value: 'hall',
      images: keyholeQuestionImages.diningHall,
      message: 'The noisy\ndining hall',
    },
    {
      value: 'garden',
      images: keyholeQuestionImages.theSunnyGarden,
      message: 'The sunny\ngarden',
    },
    {
      value: 'studio',
      images: keyholeQuestionImages.theArtistStudio,
      message: 'The artist’s\nstudio',
    },
    {
      value: 'library',
      images: keyholeQuestionImages.library,
      message: 'The quiet\nlibrary',
    },
    {
      value: 'dungeon',
      images: keyholeQuestionImages.theDeepestDungeon,
      message: 'The deepest\ndungeon',
    },
  ],
};
