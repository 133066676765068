import { useEffect, useState } from 'react';

const cacheImages = async (images: string[]) => {
  const promises = images.map(
    src =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.addEventListener('load', resolve);
        img.addEventListener('error', reject);
      })
  );

  await Promise.all(promises);
  return true;
};

const cacheVideos = async (videos: string[]) => {
  const promises = videos.map(
    src =>
      new Promise((resolve, reject) => {
        const loadVideo = async () => {
          try {
            const res = await fetch(src);
            await res.blob();
            resolve(true);
          } catch (e) {
            reject(e);
          }
        };
        loadVideo().then();
      })
  );

  await Promise.all(promises);
  return true;
};

const cacheFonts = async (fonts: string[]) => {
  const promises = fonts.map(
    (src, index) =>
      new Promise((resolve, reject) => {
        const font = new FontFace(`preloaded-font-${index}`, `url(${src})`);
        font.load().then(resolve).catch(reject);
      })
  );
  await Promise.all(promises);
  return true;
};

interface UsePreloadProps {
  images?: string[];
  videos?: string[];
  fonts?: string[];
}

const usePreload: (
  props: UsePreloadProps
) => [
  boolean,
  (
    value: ((prevState: UsePreloadProps) => UsePreloadProps) | UsePreloadProps
  ) => void
] = (props: UsePreloadProps) => {
  const [assets, setAssets] = useState<UsePreloadProps>(props);
  const [loaded, setLoaded] = useState(false);

  const load = async () => {
    setLoaded(false);

    if (assets.images) await cacheImages(assets.images);
    if (assets.videos) await cacheVideos(assets.videos);
    if (assets.fonts) await cacheFonts(assets.fonts);

    setLoaded(true);
  };

  useEffect(() => {
    load().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  return [loaded, setAssets];
};

export default usePreload;
