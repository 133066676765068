import React, { FC } from 'react';

import { rotateDeviceData } from './RotateDevice.data';
import * as S from './RotateDevice.styled';

export interface RotateDeviceProps {}

const RotateDevice: FC<RotateDeviceProps> = ({ ...rest }) => {
  const { message, title } = rotateDeviceData;

  return (
    <S.RotateDeviceWrapper {...rest}>
      <S.MessageWrapper>
        <S.SvgRotateIcon />
        <S.Title text={title} fontFamily="soap" renderAs="h2" />
        <S.Message text={message} fontFamily="amazonEmber" renderAs="p" />
      </S.MessageWrapper>
    </S.RotateDeviceWrapper>
  );
};
export default RotateDevice;
