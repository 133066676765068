export const landingData = {
  title: 'Discover Your\nBooksona',
  body: "There's a unique reader inside all of us.\nMeet yours.",
  button: { label: 'start', href: '/quiz/#0' },
  links: [
    {
      text: 'Conditions of Use',
      href: 'https://www.amazon.com/gp/help/customer/display.html?nodeId=508088&ref_=footer_cou',
    },
    {
      text: 'Privacy Notice',
      href: 'https://www.amazon.com/gp/help/customer/display.html?nodeId=468496&ref_=footer_privacy',
    },
  ],
};
