import styled from 'styled-components';

import {
  largeHeadingStyles,
  middleQuestionStyles,
  mobileQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import Lottie from '1_components/Lottie/Lottie';
import Typography from '1_components/Typography/Typography';

export const DotsQuestionWrapper = styled(QuestionWrapper)``;

export const Caption = styled(QuestionTitle)`
  ${middleQuestionStyles}
  pointer-events: none;
  color: ${({ theme }) => theme.colors.white};
  z-index: ${zIndex('overlay')};
`;

export const CaptionMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
  font-size: 2.8rem;
  pointer-events: none;
  color: ${({ theme }) => theme.colors.white};
  z-index: ${zIndex('overlay')};
`;

///////////////////////////////////////////////
// Left
//////////////////////////////////////////////

export const LeftSideWrapper = styled(QuestionWrapper)`
  display: grid;
  place-items: center;

  background: ${({ theme }) => theme.colors.darkPurple};
`;

export const LeftTitle = styled(Typography)`
  position: absolute;
  ${largeHeadingStyles}
  color: ${({ theme }) => theme.colors.white};
  pointer-events: none;
`;

export const LeftLottie = styled(Lottie)`
  position: absolute;
  left: 0;
  pointer-events: none;
  will-change: auto;

  height: 100vh;
  width: 100vw;

  transform: scaleY(-1);
  opacity: 0.3;
`;

///////////////////////////////////////////////
// RightSide
//////////////////////////////////////////////

export const RightSideWrapper = styled(LeftSideWrapper)`
  background: ${({ theme }) => theme.colors.lightPurple};
`;

export const RightTitle = styled(LeftTitle)`
  color: ${({ theme }) => theme.colors.black};
`;

export const RightLottie = styled(LeftLottie)`
  transform: scaleY(1);
  left: unset;
  right: 0;
`;
