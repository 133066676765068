export interface Lotties {
  teleport: {
    sofa: any;
    burger: any;
    camera: any;
  };
  paperRipsQuestions: {
    dots: any;
    circles: any;
    lines: any;
  };
  garden: {
    horizontal: {
      sky: {
        loop: any;
      };
      birds: {
        loop: any;
      };
      trees: {
        intro: any;
        loop: any;
      };
      plants: {
        intro: any;
        loop: any;
      };
    };
    vertical: {
      sky: {
        loop: any;
      };
      birds: {
        loop: any;
      };
      trees: {
        intro: any;
        loop: any;
      };
      plants: {
        intro: any;
        loop: any;
      };
    };
  };
  hintCards: {
    light: {
      rotate: any;
      dragVertical: any;
      reorder: any;
      dragHorizontal: any;
      gyroscope: any;
    };
    dark: {
      rotate: any;
      dragVertical: any;
      reorder: any;
      dragHorizontal: any;
      gyroscope: any;
    };
  };
  reorder: {
    scribble1: any;
    scribble2: any;
    scribble3: any;
  };
  transitionWrapper: { pageRip05: any };
}

export const lottiePaths: Lotties = {
  garden: {
    vertical: {
      sky: {
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/skyLoop9x16.json',
      },
      birds: {
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/birdsLoop9x16.json',
      },
      trees: {
        intro:
          'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/treesIntro9x16.json',
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/treesLoop9x16.json',
      },
      plants: {
        intro:
          'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/plantsIntro9x16.json',
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/plantsLoop9x16.json',
      },
    },
    horizontal: {
      sky: {
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/skyLoop.json',
      },
      birds: {
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/birdsLoop.json',
      },
      trees: {
        intro:
          'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/treesIntro.json',
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/treesLoop.json',
      },
      plants: {
        intro:
          'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/plantsIntro.json',
        loop: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/garden/plantsLoop.json',
      },
    },
  },
  hintCards: {
    dark: {
      dragHorizontal:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/dark/dragHorizontal.json',
      dragVertical:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/dark/dragVertical.json',
      gyroscope:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/dark/gyroscope.json',
      reorder:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/dark/reorder.json',
      rotate:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/dark/rotate.json',
    },
    light: {
      dragHorizontal:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/light/dragHorizontal.json',
      dragVertical:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/light/dragVertical.json',
      gyroscope:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/light/gyroscope.json',
      reorder:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/light/reorder.json',
      rotate:
        'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/hintCards/light/rotate.json',
    },
  },
  paperRipsQuestions: {
    circles:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/paperRipsQuestions/Circles.json',
    dots: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/paperRipsQuestions/Dots.json',
    lines:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/paperRipsQuestions/Lines.json',
  },
  reorder: {
    scribble1:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/reorder/scribble1.json',
    scribble2:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/reorder/scribble2.json',
    scribble3:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/reorder/scribble3.json',
  },
  teleport: {
    burger:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/teleport/burger.json',
    camera:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/teleport/camera.json',
    sofa: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/teleport/sofa.json',
  },
  transitionWrapper: {
    pageRip05:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/lottie/transitionWrapper/PageRip05.json',
  },
};
