import { uniq } from 'lodash';

import { ANSWERS_LOGIC_WEIGHT } from 'utils/quizAnswer/logicWeights';

import { orderedQuestions } from './Quiz.questions';

export const getHashIndex = value => {
  let hashIndex;

  if (value <= orderedQuestions.length) {
    hashIndex = value;
  } else {
    hashIndex = 0;
  }

  return hashIndex;
};

export const isQuestionAnswersRepeat = (
  answers,
  questions,
  { questionIndex, questionsToCut = 2 }
) => {
  const lastThreeQuestions = [];
  const startSliceIndex =
    questionIndex - questionsToCut <= 0 ? 0 : questionIndex - questionsToCut;

  questions
    .slice(startSliceIndex, questionIndex + 1)
    .forEach(question => lastThreeQuestions.push(question.displayName));

  const logicWeightCompare = lastThreeQuestions.map(question => {
    const at = `${question.charAt(0).toLowerCase() + question.slice(1)}`;
    const answer = answers[at];

    return ANSWERS_LOGIC_WEIGHT[at][answer + 1];
  });

  const uniqQuestions = uniq(logicWeightCompare);

  return questionsToCut === 3
    ? uniqQuestions.length === 4
    : uniqQuestions.length > 2 && uniqQuestions.length > 1;
};

export const questionLeave = async (
  orderedQuestions,
  questionIndex,
  { prevQuestion, question, tieBreaker, getAnswers, questionsToCut }
) => {
  const findQuestion = orderedQuestions.find(
    question => question.displayName === tieBreaker
  );
  const indexOfQuestion = orderedQuestions.indexOf(findQuestion);

  const findPrevQuestion = orderedQuestions.find(
    question => question.displayName === prevQuestion.displayName
  );
  const prevQuestionIndex = orderedQuestions.indexOf(findPrevQuestion);

  if (!(prevQuestionIndex === questionIndex)) {
    return;
  }

  const tieFlag = isQuestionAnswersRepeat(getAnswers(), orderedQuestions, {
    questionIndex,
    questionsToCut,
  });

  const nextTieQuestionPresent = indexOfQuestion !== -1;

  if (tieFlag) {
    if (!nextTieQuestionPresent) {
      orderedQuestions.splice(prevQuestionIndex + 1, 0, question);
    }
  } else {
    if (nextTieQuestionPresent) {
      if (orderedQuestions[indexOfQuestion].displayName === tieBreaker) {
        orderedQuestions.splice(indexOfQuestion, 1);
      }
    }
  }
};
