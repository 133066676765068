import {
  CAMERA_ELEMENT,
  overlay,
  title,
  VRSelectorSceneItem,
} from './VRSelector.constants';

export default (
  items: VRSelectorSceneItem[],
  maxAngle: number,
  showTitle?: boolean
) => {
  const offsetAngle = maxAngle / (items.length - 1);
  return `
    <a-scene  vr-mode-ui="enabled: false" background="color: black">
      <a-entity visible="false" look-controls ${CAMERA_ELEMENT} id="${CAMERA_ELEMENT}">
        <a-entity position="0 0 0">
            <a-entity active camera position="0 0 -0.2">
              <a-cursor raycaster="objects: .collidable"></a-cursor>
            </a-entity>
        </a-entity>
      </a-entity>
        ${items
          .map(
            (item, index) =>
              `
        <a-box
            look-controls-enabled="false"
            depth="2"
            height="4"
            width="5"
            position="0 0.2 0"
            wrapper-${item.value.toLowerCase()}
            rotation="0 ${-(maxAngle / 2) + offsetAngle * index} 0"
            opacity="0"
            animation__1="
                property: opacity;
                to: 1;
                startEvents: show;
                dur: 250;
            "
            animation__2="
                property: opacity;
                to: 0.8;
                startEvents: hide;
                dur: 250;
            "
            >
            ${item.component}
          <a-plane
            color="#000"
            width="1.7"
            height="1.6"
            position="0 -0.4 -2.29"
            opacity="0"
            class="collidable"
            ${overlay(item.value)}
            id="${overlay(item.value)}" 
            animation__1="
                property: opacity;
                to: 0.5;
                startEvents: show;
                dur: 250;
            "
            animation__2="
                property: opacity;
                to: 0;
                startEvents: hide;
                dur: 250;
            "
          ></a-plane>
          ${
            showTitle
              ? `<a-text
            scale="5 5"
            opacity="1"
            id="${title(item.value)}" 
            position="0 -1.4 -2.2" width="1" font="/fonts/Royals/Royals-Regular-msdf.json"
            color="#fff"
            value="${item.message}"
            negate="false"
            align="center"
            animation__1="
                property: opacity;
                to: 1;
                startEvents: show;
                dur: 250;
            "
            animation__2="
                property: opacity;
                to: 0;
                startEvents: hide;
                dur: 250;
            "
          >
          </a-text>`
              : ''
          }
      </a-box>`
          )
          .join('')}
    </a-scene>
  `;
};
