export const reorderQuestionData = {
  question: {
    desktop: 'Drag and drop in order<br>*of <mark>importance...</mark>*',
    mobile: 'Drag and drop in order<br>*of <mark>importance...</mark>*',
  },
  answers: [
    {
      key: 0,
      text: 'learning\nsomething new',
      value: 'learning',
      scribbleType: 'square',
    },
    {
      key: 1,
      text: 'being entertained\n& inspired',
      value: 'entertained-inspired',
      scribbleType: 'lines',
    },
    {
      key: 2,
      text: 'rest & relaxation',
      value: 'rest-relaxation',
      scribbleType: 'round',
    },
  ],
};
