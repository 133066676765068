import React, { useEffect, useState } from 'react';
import { useEffectOnce, useMediaQuery } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import One from 'svgs/questions/reorder/01.svg';
import Two from 'svgs/questions/reorder/02.svg';
import Three from 'svgs/questions/reorder/03.svg';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import AnimatedAnswer from './AnimatedAnswers/AnimatedAnswer';
import { reorderQuestionData } from './ReorderQuestion.data';
import * as S from './ReorderQuestion.styled';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface ReorderQuestionProps {}

const ReorderQuestion: React.FC<ReorderQuestionProps> = () => {
  const { setAnswer } = useQuiz();

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'light', desktop: 'light' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'reorder',
      desktop: 'reorder',
    });
  });

  const answersFromData = reorderQuestionData.answers.map(
    ({ key, text, scribbleType, value }) => ({
      key,
      value,
      component: (
        <AnimatedAnswer
          text={text}
          fontFamily="leagueGothic"
          scribbleType={scribbleType}
        />
      ),
    })
  );

  const [answers, setAnswers] = useState(answersFromData);
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktop}px)`
  );

  useEffect(() => {
    setAnswer({ reorderQuestion: +answers[0].key });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  useEffectOnce(() => {
    setAnswer({ gardenQuestion: -1 });
  });

  const questionTitle = (
    <>
      <S.ReorderQuestionTitle
        fontFamily="amazonEmber"
        markdown={reorderQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h1"
      />
      <S.ReorderQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={reorderQuestionData.question.mobile}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        renderAs="h1"
      />
    </>
  );

  return (
    <S.ReorderQuestionWrapper>
      {questionTitle}

      <S.ReorderableListWrapper>
        <S.ReorderableList
          setItems={setAnswers}
          items={answers}
          axis={isDesktop ? 'x' : 'y'}
        />
        <S.OrderNumbers>
          <S.OrderNumber>
            <One />
          </S.OrderNumber>
          <S.OrderNumber>
            <Two />
          </S.OrderNumber>
          <S.OrderNumber>
            <Three />
          </S.OrderNumber>
        </S.OrderNumbers>
      </S.ReorderableListWrapper>

      <PaperRips primaryColor={theme.colors.orange} variant="detail" />
      <S.SvgDesktopArrows />
      <S.SvgMobileArrows />
    </S.ReorderQuestionWrapper>
  );
};

ReorderQuestion.displayName = 'ReorderQuestion';

export default ReorderQuestion;
