import { useEffect } from 'react';

import { setGlobalState, useGlobalState } from 'context/globalState';

const useDeviceMotionAllowed = () => {
  const [deviceMotionAllowed] = useGlobalState('deviceMotionAllowed');

  const onDeviceOrientation = (e: DeviceOrientationEvent) => {
    if (!e.alpha) return;
    setGlobalState('deviceMotionAllowed', true);
  };

  useEffect(
    () => () =>
      window.removeEventListener('deviceorientation', onDeviceOrientation),
    []
  );

  const requestDeviceMotionPermissions = () => {
    if (deviceMotionAllowed) return;
    const request = async () => {
      if (
        typeof DeviceOrientationEvent !== 'undefined' &&
        typeof (DeviceMotionEvent as any).requestPermission === 'function'
      ) {
        // iOS 13+
        const state = await (DeviceOrientationEvent as any).requestPermission();
        if (state === 'granted') {
          window.addEventListener('deviceorientation', onDeviceOrientation, {
            once: true,
          });
        } else {
          console.error('Request to access the orientation was rejected');
          setGlobalState('deviceMotionAllowed', false);
        }
      } else {
        // iOS 12- and other devices
        window.addEventListener('deviceorientation', onDeviceOrientation, {
          once: true,
        });
      }
    };
    request().then();
  };

  return { requestDeviceMotionPermissions, deviceMotionAllowed };
};

export default useDeviceMotionAllowed;
