import theme from './theme';

const customMediaQuery = (value: number) => `@media (min-width: ${value}px)`;

const media = {
  custom: customMediaQuery,

  mobileSmall: '@media (max-width: 375px)',
  tablet: customMediaQuery(theme.breakpoints.tablet),
  desktop: customMediaQuery(theme.breakpoints.desktop),
  desktopLarge: customMediaQuery(theme.breakpoints.desktopLarge),
  desktopWide: customMediaQuery(theme.breakpoints.desktopWide),
};

export default media;
