import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

import PrimaryButton from '1_components/Buttons/PrimaryButton/PrimaryButton';
import HintCard from '1_components/HintCard/HintCard';
import NoiseLayer from '1_components/NoiseLayer/NoiseLayer';
import ProgressBar from '1_components/ProgressBar/ProgressBar';

export const QuizWrapper = styled.div`
  display: grid;
  place-items: center;

  height: 100vh;
  overflow: hidden;
`;

export const QuizProgress = styled(ProgressBar)`
  position: fixed;
  top: ${({ theme }) => `${theme.sharedValues.headerHeight}px`};
  left: 0;
`;

export const HintCardMobileComp = styled(HintCard)`
  position: fixed;
  bottom: 96px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${zIndex('overlay') + 1};

  ${media.desktop} {
    display: none;
  }
`;

export const HintCardDesktopComp = styled(HintCard)`
  display: none;

  ${media.desktop} {
    display: initial;

    position: fixed;
    bottom: 160px;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${zIndex('overlay') + 1};
  }
`;

export const SubmitButton = styled(PrimaryButton)`
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${({ theme }) =>
    `calc(100vw - 2 * ${theme.sharedValues.mobileIndent}px)`};

  z-index: ${zIndex('aboveOverlay')};

  ${media.desktop} {
    bottom: 4.5rem;
  }
`;

export const NoiseLayerComp = styled(NoiseLayer)`
  z-index: calc(
    ${zIndex('overlay')} + 2
  ); // the + 2 sets it above the question title which also has 'overlay' and hint card which has overlay + 1
`;
