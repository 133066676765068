import React from 'react';

import * as S from './KnobIcon.styled';

export type KnobIconColorVariant = 'light' | 'dark';

export interface KnobIconProps {
  showLeftArrow: boolean;
  showRightArrow: boolean;
  colorVariant: KnobIconColorVariant;
}

const KnobIcon: React.FC<KnobIconProps> = ({
  showLeftArrow,
  showRightArrow,
  colorVariant,
  ...rest
}) => {
  return (
    <S.KnobCircle {...rest} $colorVariant={colorVariant}>
      <S.SvgKnobLeftArrow hidden={!showLeftArrow} />
      <S.SvgKnobRightArrow hidden={!showRightArrow} />
    </S.KnobCircle>
  );
};

export default KnobIcon;
