import styled from 'styled-components';

import { fontFaces, FontFamily } from './Typography.types';

interface StyledTypographyProps {
  $fontFamily: FontFamily;
}

export const StyledTypography = styled.span<StyledTypographyProps>`
  display: inline;
  color: inherit;
  white-space: pre;
  font-family: ${({ $fontFamily }) => fontFaces[$fontFamily]};
  user-select: none;

  & * {
    user-select: none;
  }
`;
