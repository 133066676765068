import { transform, useAnimation, useMotionValue } from 'framer-motion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts';

import * as S from './RotationWheel.styled';

import KnobIcon from '1_components/KnobIcon/KnobIcon';

export type RotationWheelColorVariant = 'light' | 'dark';

export interface RotationWheelProps {
  numberOfOptions: number;
  selectedOption: number;
  setSelectedOption: (opt: number) => void;
  colorVariant: RotationWheelColorVariant;
  ringColorVariant?: RotationWheelColorVariant;
}

const RotationWheel: React.FC<RotationWheelProps> = ({
  children,
  numberOfOptions,
  selectedOption,
  setSelectedOption,
  colorVariant,
  ringColorVariant = 'dark',
  ...rest
}) => {
  const knobConstraintsRef = useRef<HTMLDivElement>(null);
  const knobX = useMotionValue(0);
  const knobY = useMotionValue(0);
  const knobRotation = useMotionValue(0);
  const knobControls = useAnimation();
  const [wheelRef, { width: wheelWidth }] = useElementSize();
  const [dotPositions, setDotPositions] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  // number multiplied, because the ring has an inner width
  const wheelRadius = useMemo(() => (wheelWidth / 2) * 0.96, [wheelWidth]);

  const getPosOnYAxis = useCallback(
    (xVal: number): number =>
      Math.sqrt(Math.pow(wheelRadius, 2) - Math.pow(xVal, 2)),
    [wheelRadius]
  );

  const getDotX = useCallback(
    (dotIndex: number): number => {
      const dotsSpreadWidth = wheelRadius * 1.5;
      const dotSpacing = dotsSpreadWidth / (numberOfOptions - 1);
      const dotXOffset = dotsSpreadWidth / 2;
      return dotSpacing * dotIndex - dotXOffset;
    },
    [wheelRadius, numberOfOptions]
  );

  const getNearestDotX = useCallback(
    (xVal: number): number => {
      if (dotPositions.length === 0) return 0;
      return dotPositions.reduce(
        (prev, curr) =>
          Math.abs(curr - xVal) < Math.abs(prev - xVal) ? curr : prev,
        dotPositions[0]
      );
    },
    [dotPositions]
  );

  const handleDotClick = (dotIndex: number) => {
    setSelectedOption(dotIndex);
    knobControls.start({
      x: dotPositions[dotIndex],
      transition: { type: 'spring', damping: 15 },
    });
  };

  useEffect(() => {
    const updateSelectedOption = (xVal: number) => {
      if (knobX.isAnimating()) return;
      const dotIndex = dotPositions.indexOf(getNearestDotX(xVal));
      setSelectedOption(dotIndex || 0);
    };

    const unsubscribe = knobX.onChange(xVal => {
      knobY.set(getPosOnYAxis(xVal));
      knobRotation.set(
        transform(xVal, [-wheelRadius, 0, wheelRadius], [60, 0, -60])
      );
      updateSelectedOption(xVal || 0);
    });
    return () => unsubscribe();
  }, [
    dotPositions,
    getNearestDotX,
    getPosOnYAxis,
    knobRotation,
    knobX,
    knobY,
    selectedOption,
    setSelectedOption,
    wheelRadius,
  ]);

  useEffect(
    () =>
      setDotPositions([...Array(numberOfOptions)].map((_, i) => getDotX(i))),

    [getDotX, numberOfOptions, wheelRadius]
  );

  useEffect(() => {
    if (dotPositions.length === 0 || dotPositions[0] === 0 || !isLoading)
      return;
    knobX.set(dotPositions[selectedOption]);
    setIsLoading(false);
  }, [dotPositions, isLoading, knobX, selectedOption]);

  useEffect(() => {
    if (isLoading) return;

    knobControls.start({
      y: getPosOnYAxis(dotPositions[selectedOption]),
      x: dotPositions[selectedOption],
      transition: { duration: 0 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, windowHeight]);

  const modifyTarget = target => getNearestDotX(target);

  return (
    <S.RotationWheelWrapper {...rest}>
      {children}

      <S.RotationWheelRingWrapper
        ref={wheelRef}
        $ringColorVariant={ringColorVariant}
      >
        <S.SvgRotationWheelRing />
      </S.RotationWheelRingWrapper>

      <S.KnobWrapper ref={knobConstraintsRef}>
        <S.Knob
          drag="x"
          dragElastic={false}
          dragMomentum={false}
          dragConstraints={knobConstraintsRef}
          dragTransition={{
            modifyTarget,
            timeConstant: 100,
          }}
          whileDrag={{ cursor: 'grabbing' }}
          animate={knobControls}
          style={{ x: knobX, y: knobY, rotate: knobRotation }}
        >
          {!isLoading && (
            <KnobIcon
              showLeftArrow={selectedOption !== 0}
              showRightArrow={selectedOption !== numberOfOptions - 1}
              colorVariant={colorVariant}
            />
          )}
        </S.Knob>
      </S.KnobWrapper>
      <S.DotsWrapper>
        {[...Array(numberOfOptions)].map((_, i) => (
          <S.Dot
            key={i}
            style={{
              x: dotPositions.length - 1 < i ? 0 : dotPositions[i],
              y: getPosOnYAxis(
                dotPositions.length - 1 < i ? 0 : dotPositions[i]
              ),
            }}
            onClick={() => handleDotClick(i)}
            $colorVariant={colorVariant}
          />
        ))}
      </S.DotsWrapper>
    </S.RotationWheelWrapper>
  );
};

export default RotationWheel;
