import Image from 'next/image';
import React, { FC } from 'react';

import { quizResultsData } from '../QuizResults.data';
import * as S from './RecommendedBooks.styled';
import { getBooks } from './RecommendedBooks.utils';

export interface RecommendedBooksProps {
  personality: string[];
}

const RecommendedBooks: FC<RecommendedBooksProps> = ({
  personality,
  ...rest
}) => {
  const booksList = getBooks(personality).map(
    ({ cover, author, title, url }, index) => (
      <S.BookItem key={`${cover + index}book`}>
        <S.CoverWrapper>
          <Image
            src={cover}
            alt=""
            layout="fill"
            objectFit="cover"
            objectPosition="top"
          />
        </S.CoverWrapper>

        <S.BookInfo>
          <S.BookTitle text={title} fontFamily="amazonEmber" renderAs="h4" />
          <S.Author text={author} fontFamily="amazonEmber" renderAs="p" />
        </S.BookInfo>

        <S.ShopNowButton
          text="shop now"
          href={url}
          className="shop_now_button"
        />
      </S.BookItem>
    )
  );

  return (
    <S.RecommendedBooksWrapper {...rest}>
      <S.ScrollDownMessage>
        <S.Message
          text={quizResultsData.recommendationMessage}
          fontFamily="amazonEmber"
          renderAs="p"
        />
        <S.SvgArrow />
      </S.ScrollDownMessage>

      <S.BookCovers>{booksList}</S.BookCovers>
    </S.RecommendedBooksWrapper>
  );
};
export default RecommendedBooks;
