import React, { forwardRef, Ref } from 'react';

import * as S from './BaseButton.styles';

import BaseLink, { BaseLinkProps } from '1_components/BaseLink/BaseLink';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  href?: undefined;
  subject?: never;
  download?: never;
  rel?: never;
  target?: never;
}

export type BaseButtonProps = ButtonProps | BaseLinkProps;

const BaseButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  BaseButtonProps | BaseLinkProps
>(({ href, children, ...props }, ref) => {
  return href ? (
    <BaseLink
      ref={ref as Ref<HTMLAnchorElement>}
      href={href}
      {...(props as BaseLinkProps)}
    >
      {children}
    </BaseLink>
  ) : (
    <S.Button ref={ref as Ref<HTMLButtonElement>} {...(props as ButtonProps)}>
      {children}
    </S.Button>
  );
});

BaseButton.displayName = 'BaseButton';

export default BaseButton;
