import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { PERSONALITY_ANSWERS_COPY } from 'assets/personalityAnswersCopy';
import theme from 'utils/styles/theme';

import { getResultAssets, quizResultsData } from '../QuizResults.data';
import * as S from './GraphicResults.styled';
import { getUrl } from './GraphicResults.utils';

import ShareWidget from '1_components/ShareWidget/ShareWidget';
import PreloaderOverlay from '2_sections/PreloaderOverlay/PreloaderOverlay';

export interface GraphicResultsProps {
  personality: string[];
}

const GraphicResults: React.FC<GraphicResultsProps> = ({
  personality,
  ...rest
}) => {
  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.desktop - 1}px)`
  );
  const {
    profile: profileAssets,
    writeups: writeupsAssets,
    genres: genresAssets,
  } = getResultAssets(personality, isMobile);
  const copy = personality.map(key => PERSONALITY_ANSWERS_COPY[key]);

  const [loaded, setLoaded] = useState(0);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 50 ? setShowMessage(false) : setShowMessage(true);
    });

    return () =>
      window.removeEventListener('scroll', () => {
        window.scrollY > 50 ? setShowMessage(false) : setShowMessage(true);
      });
  }, []);

  const profile = (
    <>
      <S.ImageWrapper>
        <img
          onLoad={() => setLoaded(i => i + 1)}
          src={profileAssets.image}
          alt=""
        />
      </S.ImageWrapper>

      <S.ShareContainer>
        <ShareWidget
          url={getUrl(personality, 'profile')}
          title={quizResultsData.shareTitle}
          message={quizResultsData.twitterPostMessage}
          imageUrl={profileAssets.shareImage}
          shareThrough="profile"
        />
      </S.ShareContainer>

      <S.ProfileScrollDownMessage $hidden={true}>
        <S.Message
          text={quizResultsData.readOnMessage}
          fontFamily="amazonEmber"
          renderAs="p"
        />

        <S.SvgArrow />
      </S.ProfileScrollDownMessage>

      <S.HiddingScrollMessage animate={{ opacity: showMessage ? 1 : 0 }}>
        <S.MessageWrapper>
          <S.Message
            text={quizResultsData.readOnMessage}
            fontFamily="amazonEmber"
            renderAs="p"
          />

          <S.SvgArrow />
        </S.MessageWrapper>
      </S.HiddingScrollMessage>
    </>
  );

  const writeupInfo = (
    <S.TextWrapper>
      {copy.map(({ title, subtitle, description }, idx) => (
        <S.CopyWrapper key={title + idx}>
          <S.Heading markdown={title} fontFamily="soap" renderAs="h1" />

          <S.Info
            markdown={subtitle}
            fontFamily="amazonEmber"
            underline={{ color: theme.colors.limeGreen, variant: 'short' }}
            renderAs="h2"
          />
          <div>
            <S.Description
              text={description}
              fontFamily="amazonEmber"
              renderAs="p"
            />
          </div>
        </S.CopyWrapper>
      ))}
    </S.TextWrapper>
  );

  const writeups = (
    <S.GraphicWithDownloadWrapper>
      <S.GraphicWrapper>
        <img
          onLoad={() => setLoaded(i => i + 1)}
          src={writeupsAssets.image}
          alt=""
        />
        {writeupInfo}
      </S.GraphicWrapper>
      <S.WriteUpsShareContainer>
        <ShareWidget
          url={getUrl(personality, 'writeups')}
          title={quizResultsData.shareTitle}
          message={quizResultsData.twitterPostMessage}
          imageUrl={writeupsAssets.shareImage}
          shareThrough="writeups"
        />
      </S.WriteUpsShareContainer>
    </S.GraphicWithDownloadWrapper>
  );

  const genres = (
    <>
      <S.ScrollDownMessage>
        <S.Message
          text={quizResultsData.genresMessage}
          fontFamily="amazonEmber"
          renderAs="p"
        />

        <S.SvgArrow />
      </S.ScrollDownMessage>
      <S.GraphicWithDownloadWrapper>
        <S.GraphicWrapper>
          <img
            onLoad={() => setLoaded(i => i + 1)}
            src={genresAssets.image}
            alt=""
          />
        </S.GraphicWrapper>
        <S.GenresShareContainer>
          <ShareWidget
            url={getUrl(personality, 'genres')}
            title={quizResultsData.shareTitle}
            message={quizResultsData.twitterPostMessage}
            imageUrl={genresAssets.shareImage}
            shareThrough="genres"
          />
        </S.GenresShareContainer>
      </S.GraphicWithDownloadWrapper>
    </>
  );

  const results = (
    <S.ResultsWrapper>
      <S.ProfileWrapper>{profile}</S.ProfileWrapper>

      <S.GraphicSection>
        {writeups}
        {genres}
      </S.GraphicSection>
    </S.ResultsWrapper>
  );

  return (
    <S.GraphicResultsWrapper {...rest}>
      {loaded < 3 && <PreloaderOverlay />}
      {results}
    </S.GraphicResultsWrapper>
  );
};

export default GraphicResults;
