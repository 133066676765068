import React from 'react';

import theme from 'utils/styles/theme';

import * as S from './ProgressBar.styled';

export interface ProgressBarProps {
  color?: string;
  currentStep: number;
  stepsLength: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  color = theme.colors.white,
  currentStep,
  stepsLength,
  ...rest
}) => {
  return (
    <S.ProgressBarWrapper
      initial={{ scaleX: 0 }}
      animate={{ scaleX: currentStep / stepsLength }}
      exit={{
        scaleX: 1,
        scaleY: 0,
        transition: { scaleY: { delay: 0.2, duration: 0.2 } },
      }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      $color={color}
      {...rest}
    />
  );
};

export default ProgressBar;
