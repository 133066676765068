import Link from 'next/link';
import React, { forwardRef, useMemo } from 'react';

const routedPrefixes = require
  .context('pages', true, /^.\/(?!_).*tsx$/)
  .keys()
  .map(k => `/${k.replace('./', '').split('.tsx')[0].split('/')[0]}`);

export interface BaseLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  subject?: string;
}

const BaseLink = forwardRef<HTMLAnchorElement, BaseLinkProps>(
  ({ className, href = '/', subject, children, ...props }, ref) => {
    const isRouted = useMemo(() => {
      return (
        href &&
        (href === '/' ||
          Boolean(routedPrefixes.find(prefix => href.startsWith(prefix))))
      );
    }, [href]);

    let prefix = '';
    let suffix = '';

    if (!props.download && href) {
      if (/^(https:\/\/|http:\/\/)/.test(href)) {
        props.target = '_blank';
        props.rel = 'noopener noreferrer';
      } else if (href.includes('@')) {
        props.target = '_blank';
        prefix = 'mailto:';
        if (subject) {
          suffix = `?subject=${subject}`;
        }
        if (href.startsWith('@')) href = href.replace('@', '');
      }
    }

    return isRouted ? (
      <Link href={`${prefix}${href}${suffix}`} scroll={false}>
        <a ref={ref} className={className} {...props}>
          {children}
        </a>
      </Link>
    ) : (
      <a
        ref={ref}
        className={className}
        href={href ? `${prefix}${href}${suffix}` : null}
        {...props}
      >
        {children}
      </a>
    );
  }
);

BaseLink.displayName = 'BaseLink';

export default BaseLink;
