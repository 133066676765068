import React, { FC } from 'react';

import { useLottieFiles } from 'hooks/useLottieFiles';

import * as S from './AnimatedAnswer.styled';

import { FontFamily } from '1_components/Typography/Typography.types';

type Scribble = 'square' | 'round' | 'lines' | string;

export interface AnimatedAnswerProps {
  text: string;
  fontFamily?: FontFamily;
  scribbleType: Scribble;
}

const AnimatedAnswer: FC<AnimatedAnswerProps> = ({
  text,
  fontFamily = 'leagueGothic',
  scribbleType,
  ...rest
}) => {
  const {
    files: {
      reorder: {
        scribble1: SquareScribble,
        scribble2: LinesScribble,
        scribble3: RoundScribble,
      },
    },
  } = useLottieFiles();

  const renderScribble = (variant: Scribble) => {
    switch (variant) {
      case 'square':
        return (
          <S.SquareScribble
            animationData={SquareScribble}
            loop
            play
            preserveAspectRatio="none"
          />
        );

      case 'lines':
        return (
          <S.LinesScribble
            animationData={LinesScribble}
            loop
            play
            preserveAspectRatio="none"
          />
        );

      case 'round':
        return (
          <S.RoundScribble
            animationData={RoundScribble}
            loop
            play
            preserveAspectRatio="none"
          />
        );
    }
  };

  return (
    <S.AnswerWrapper {...rest}>
      <S.Answer text={text} fontFamily={fontFamily} renderAs="h2" />
      {renderScribble(scribbleType)}
    </S.AnswerWrapper>
  );
};
export default AnimatedAnswer;
