import styled, { css } from 'styled-components';

import DetailRips from 'svgs/paperOverlay/detailRips.svg';
import MobileRips from 'svgs/paperOverlay/mobileRips.svg';
import MobileTopRip from 'svgs/paperOverlay/mobileTopLineRip.svg';
import PrimaryRips from 'svgs/paperOverlay/primaryRips.svg';
import SecondaryRips from 'svgs/paperOverlay/secondaryRips.svg';
import SideRip from 'svgs/paperOverlay/sideRip.svg';
import SvgPaperRipsResultDesktop from 'svgs/resultPage/desktopPaperRips.svg';
import SvgPaperRipsResultMobile from 'svgs/resultPage/mobilePaperRips.svg';
import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

import ParallaxWrapper from '../ParallaxWrapper/ParallaxWrapper';

export const commonStyles = css`
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const SvgPrimaryRips = styled(PrimaryRips)<{ color: string }>`
  ${commonStyles};

  ${media.desktop} {
    color: ${({ color }) => color};
  }
`;

export const SvgSideRip = styled(SideRip)<{ color: string }>`
  ${commonStyles};

  ${media.desktop} {
    color: ${({ color }) => color};
  }
`;

export const SvgSecondaryRips = styled(SecondaryRips)<{ color: string }>`
  ${commonStyles};

  ${media.desktop} {
    color: ${({ color }) => color};
  }
`;

export const SvgDetailRips = styled(DetailRips)<{ color: string }>`
  ${commonStyles};

  ${media.desktop} {
    color: ${({ color }) => color};
  }
`;

export const SvgMobileRips = styled(MobileRips)<{ color: string }>`
  ${commonStyles};
  color: ${({ color }) => color};
`;

export const SvgMobileTopOnlyRips = styled(MobileTopRip)<{ color: string }>`
  ${commonStyles};
  color: ${({ color }) => color};
`;

export const paperRipsStyle = css`
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const PaperQuestionPrimaryRips = styled(SvgPrimaryRips)<{
  color: string;
}>`
  ${paperRipsStyle};
  color: ${({ color }) => color};
`;

export const PaperQuestionSecondaryRips = styled(SvgSecondaryRips)<{
  color: string;
}>`
  ${paperRipsStyle};
  color: ${({ color }) => color};
`;

export const PaperRipsResultDesktop = styled(SvgPaperRipsResultDesktop)`
  ${paperRipsStyle};
`;

export const PaperRipsResultMobile = styled(SvgPaperRipsResultMobile)`
  ${paperRipsStyle};
`;

export const ParallaxBox = styled(ParallaxWrapper)`
  pointer-events: none;
  z-index: ${zIndex('aboveContent')};
`;
