type WriteupsObjTypes = {
  reliable: string;
  unique: string;
  wild: string;
};

interface WriteupsBackgroundTypes {
  mobile: WriteupsObjTypes;
  desktop: WriteupsObjTypes;
}

export const WRITEUPS_BACKGROUNDS: WriteupsBackgroundTypes = {
  mobile: {
    reliable:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/writeups/mobile/ResultsPage_BG_Mobile_Reliably.png',
    unique:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/writeups/mobile/ResultsPage_BG_Mobile_Uniquely.png',
    wild: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/writeups/mobile/ResultsPage_BG_Mobile_Wildly.png',
  },
  desktop: {
    reliable:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/writeups/desktop/ResultsPage_BG_Desktop_Reliably.png',
    unique:
      'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/writeups/desktop/ResultsPage_BG_Desktop_Uniquely.png',
    wild: 'https://amazon-booksona-assets.s3.us-west-1.amazonaws.com/writeups/desktop/ResultsPage_BG_Desktop_Wildly.png',
  },
};
