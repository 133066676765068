import { AnimatePresence } from 'framer-motion';
import { NextComponentType } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import * as S from './Layout.styled';

import FadeInOut from '1_components/FadeInOut/FadeInOut';
import Head from '1_components/Head/Head';
import Header from '2_sections/Header/Header';

interface LayoutProps {
  PageComponent: NextComponentType;
  pageProps: AppProps['pageProps'];
}

export const Layout: FC<LayoutProps> = ({ PageComponent, pageProps }) => {
  const router = useRouter();

  return (
    <>
      <Head og={pageProps.data || {}} />
      <Header />
      <S.Main>
        <AnimatePresence exitBeforeEnter initial={false}>
          <FadeInOut key={router.route}>
            <PageComponent {...pageProps} />
          </FadeInOut>
        </AnimatePresence>
      </S.Main>
    </>
  );
};

export default Layout;
