import React from 'react';

import { paths } from './BlinkEye.data';
import { BlinkEyeVariants, transition } from './BlinkEye.motion';
import * as S from './BlinkEye.styled';

export interface BlinkEyeProps {
  selectedOption: number;
}

const BlinkEye: React.FC<BlinkEyeProps> = ({ selectedOption, ...rest }) => {
  const pathsKeys = Object.keys(paths);

  return (
    <S.BlinkEyeWrapper {...rest}>
      <S.SvgEyeWrapper>
        <S.Svg
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          animate={pathsKeys[selectedOption]}
          custom={paths[pathsKeys[selectedOption]].top}
          variants={BlinkEyeVariants.svg}
          transition={transition}
          style={{ originX: '50%', originY: '50%' }}
        >
          <S.G>
            <S.Path
              d="M82,198 C140,95 253,95 320,198"
              fill="#FFD538"
              custom={paths[pathsKeys[selectedOption]].top}
              variants={BlinkEyeVariants.back}
            />
            <S.Path
              d="M82,198 C140,310 253,310 320,198"
              fill="#FFD538"
              custom={paths[pathsKeys[selectedOption]].bottom}
              variants={BlinkEyeVariants.back}
            />
          </S.G>
        </S.Svg>
        <S.ParallaxBox inverted={false}>
          <S.EyeWrapper data-depth="0.4">
            <S.SvgEye
              viewBox="0 0 400 400"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              animate={pathsKeys[selectedOption]}
              custom={paths[pathsKeys[selectedOption]].eyeTop}
              variants={BlinkEyeVariants.svgEye}
              transition={transition}
              style={{ originX: '50%', originY: '50%' }}
            >
              <S.Path
                d="M142,200 C171,125 231,125 256,200"
                stroke="#1F1F1F"
                fill="#1F1F1F"
                custom={paths[pathsKeys[selectedOption]].eyeTop}
                variants={BlinkEyeVariants.eye}
              />
              <S.Path
                d="M142,198 C171,270 231,270 256,198"
                stroke="#1F1F1F"
                fill="#1F1F1F"
                custom={paths[pathsKeys[selectedOption]].eyeBottom}
                variants={BlinkEyeVariants.eye}
              />
            </S.SvgEye>
          </S.EyeWrapper>
        </S.ParallaxBox>
      </S.SvgEyeWrapper>
    </S.BlinkEyeWrapper>
  );
};

export default BlinkEye;
