import React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { smileyQuestionData } from './SmileyQuestion.data';
import * as S from './SmileyQuestion.styled';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface SmileyQuestionProps {}

const SmileyQuestion: React.FC<SmileyQuestionProps> = () => {
  const { setAnswer } = useQuiz();

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'light', desktop: 'light' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'dragVertical',
      desktop: 'dragVertical',
    });
  });

  useEffectOnce(() => {
    setAnswer({ eyeQuestion: -1 });
  });

  const questionTitle = (
    <>
      <S.SmileyQuestionTitle
        fontFamily="amazonEmber"
        markdown={smileyQuestionData.question.desktop}
        underline={{ variant: 'knotLong', color: theme.colors.pink }}
        underlineBetweenTextLines
        renderAs="h1"
      />
      <S.SmileyQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={smileyQuestionData.question.mobile}
        underline={{ variant: 'knotLong', color: theme.colors.white }}
        underlineBetweenTextLines
        renderAs="h1"
      />
    </>
  );

  return (
    <S.SmileyQuestionWrapper>
      <PaperRips
        primaryColor={theme.colors.darkGreen}
        secondaryColor={theme.colors.pink}
      />
      <S.SmileyWrapper>
        <S.SmileyFaceComp />
      </S.SmileyWrapper>
      {questionTitle}
    </S.SmileyQuestionWrapper>
  );
};

SmileyQuestion.displayName = 'SmileyQuestion';

export default SmileyQuestion;
