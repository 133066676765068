import React, { useEffect, useState } from 'react';
import {
  useEffectOnce,
  useElementSize,
  useMediaQuery,
  useWindowSize,
} from 'usehooks-ts';

import { setGlobalState } from 'context/globalState';
import useQuiz from 'utils/quizAnswer/useQuiz';
import theme from 'utils/styles/theme';

import { carQuestionData } from './CarQuestion.data';
import * as S from './CarQuestion.styled';

import PaperRips from '1_components/PaperRips/PaperRips';

export interface CarQuestionProps {}

const CarQuestion: React.FC<CarQuestionProps> = () => {
  const [carSliderWrapperRef, carSliderWrapperSize] = useElementSize();
  const [currentAnswer, setCurrentAnswer] = useState(0);

  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktop}px)`
  );
  const { height: windowHeight } = useWindowSize();
  const { setAnswer } = useQuiz();

  useEffect(() => {
    setAnswer({ carQuestion: currentAnswer });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswer]);

  useEffectOnce(() => {
    setGlobalState('headerColor', theme.colors.amazonInk);
    setGlobalState('progressBarColor', theme.colors.white);
    setGlobalState('quizSubmitVariant', 'dark');
    setGlobalState('quizHintCardTheme', { mobile: 'light', desktop: 'light' });
    setGlobalState('quizHintCardVariant', {
      mobile: 'dragVertical',
      desktop: 'dragHorizontal',
    });
  });

  const questionTitle = (
    <>
      <S.CarQuestionTitle
        fontFamily="amazonEmber"
        markdown={carQuestionData.question.desktop}
        underline={{ variant: 'oval', color: theme.colors.pink }}
        renderAs="h1"
      />
      <S.CarQuestionTitleMobile
        fontFamily="amazonEmber"
        markdown={carQuestionData.question.mobile}
        underline={{ variant: 'oval', color: theme.colors.pink }}
        renderAs="h1"
      />
    </>
  );

  return (
    <S.CarQuestionWrapper
      $backgroundColor={carQuestionData.backgroundColors[currentAnswer]}
    >
      <PaperRips variant="detail" primaryColor={theme.colors.pink} />
      <S.SvgRoadsMobile />
      <S.SvgRoadsDesktop />
      {questionTitle}

      <S.RoadWrapper $windowHeight={windowHeight}>
        <S.RoadTurn>
          <S.InnerWrapper>
            <S.Turn
              $backgroundColor={carQuestionData.backgroundColors[currentAnswer]}
            />
          </S.InnerWrapper>
        </S.RoadTurn>

        <S.CarSliderWrapper
          ref={carSliderWrapperRef}
          $direction={isDesktop ? 'x' : 'y'}
        >
          <S.CarSliderComp
            value={currentAnswer}
            setValue={setCurrentAnswer}
            direction={isDesktop ? 'x' : 'y'}
            wrapperSize={carSliderWrapperSize}
          />
        </S.CarSliderWrapper>
      </S.RoadWrapper>
    </S.CarQuestionWrapper>
  );
};

CarQuestion.displayName = 'CarQuestion';

export default CarQuestion;
