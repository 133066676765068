import paperRipQuestionSvg from 'svgs/questions/paperRipSliderQuestions/bars/desktop.svg';
import paperRipQuestionMobileSvg from 'svgs/questions/paperRipSliderQuestions/bars/mobile.svg';
import paperRipQuestionOutlineSvg from 'svgs/questions/paperRipSliderQuestions/bars/outlineDesktop.svg';
import paperRipQuestionMobileOutlineSvg from 'svgs/questions/paperRipSliderQuestions/bars/outlineMobile.svg';

export const paperRipsBarsQuestionData = {
  question: {
    desktop: '<mark>*Think* fast.</mark>',
    mobile: '<mark>*Think* fast.</mark> ',
  },
  answers: [
    { text: 'time\ntravel', value: 'time-travel' },
    { text: 'love\npotion', value: 'love-potion' },
  ],
  paperRips: [
    paperRipQuestionMobileSvg,
    paperRipQuestionSvg,
    paperRipQuestionOutlineSvg,
    paperRipQuestionMobileOutlineSvg,
  ],
};
