export const BOOKS = {
  daydreamer: {
    a: [
      {
        title: 'How High We Go in the Dark',
        author: 'Nagamatsu, Sequoia',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/howhigh.jpg',
        url: 'https://www.amazon.com/How-High-We-Go-Dark-ebook/dp/B092VY49LZ?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Memory Librarian: And Other Stories of Dirty Computer',
        author: 'Monae, Janelle',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/Cover_The_Memory_Librarian.jpg',
        url: 'https://www.amazon.com/Memory-Librarian-Other-Stories-Computer/dp/B09K1VDQLR?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Kaiju Preservation Society',
        author: 'Scalzi, John',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/kaiju.jpg',
        url: 'https://www.amazon.com/Kaiju-Preservation-Society-John-Scalzi/dp/0765389126?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Eyes of the Void',
        author: 'Tchaikovsky, Adrian',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/eyesofvoid.jpg',
        url: 'https://www.amazon.com/Eyes-Void-Final-Architecture-2/dp/0316705918?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Mickey7',
        author: 'Ashton, Edward',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/mickey7.jpg',
        url: 'https://www.amazon.com/Mickey7-Novel-Edward-Ashton/dp/1250275032?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Siren Queen',
        author: 'Vo, Nghi',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/siren-queen.jpg',
        url: 'https://amazon.com/Siren-Queen-Nghi-Vo/dp/1250788838?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    b: [
      {
        title: 'When Women Were Dragons',
        author: 'Barnhill, Kelly',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/womendragons.jpg',
        url: 'https://www.amazon.com/When-Women-Were-Dragons-Novel/dp/0385548222?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Ordinary Monsters',
        author: 'Miro, J. M.',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/ordinary-monsters.jpg',
        url: 'https://www.amazon.com/Ordinary-Monsters-J-M-Miro/dp/1250833663?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Starless Crown',
        author: 'Rollins, James',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/starlesscrown.jpg',
        url: 'https://www.amazon.com/Starless-Crown-Moon-Fall/dp/1250816777?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Moon Witch, Spider King',
        author: 'James, Marlon',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/moonwitch.jpg',
        url: 'https://www.amazon.com/Moon-Witch-Spider-King-Trilogy/dp/0735220204?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Atlas Six',
        author: 'Blake, Olivie',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/atlassix.jpg',
        url: 'https://www.amazon.com/Atlas-Six-Olivie-Blake/dp/167991099X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Book of Night',
        author: 'Black, Holly',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/bookofnight.jpg',
        url: 'https://www.amazon.com/Book-Night-Holly-Black-ebook/dp/B096T6GHLG?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'In the Shadow of Lightning',
        author: 'McClellan, Brian',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/shadow-of-lightning.jpg',
        url: 'https://www.amazon.com/Shadow-Lightning-Glass-Immortals/dp/1250755697?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Where the Drowned Girls Go',
        author: 'McGuire, Seanan',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/drownedgirlsgo.jpg',
        url: 'https://www.amazon.com/Where-Drowned-Girls-Wayward-Children/dp/1250213622?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    c: [
      {
        title: 'Sea of Tranquility',
        author: 'Mandel, Emily St. John',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/seaoftranquility.jpg',
        url: 'https://www.amazon.com/Sea-Tranquility-Emily-John-Mandel/dp/0593321448?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Daughter of the Moon Goddess',
        author: 'Tan, Sue Lynn',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/daughterofmoon.jpg',
        url: 'https://www.amazon.com/Daughter-Moon-Goddess-Sue-Lynn/dp/0063031302?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Candy House',
        author: 'Egan, Jennifer',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/candyhouse.jpg',
        url: 'https://www.amazon.com/Candy-House-Novel-Visit-Squad/dp/1476716765?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Final Strife',
        author: 'El-Arifi, Saara',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/finalstrife.jpg',
        url: 'https://www.amazon.com/Final-Strife-Novel-Saara-El-Arifi/dp/0593356942?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'All the Seas of the World',
        author: 'Kay, Guy Gavriel',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/alltheseas.jpg',
        url: 'https://www.amazon.com/All-Seas-World-Guy-Gavriel/dp/0593441044?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Goliath',
        author: 'Onyebuchi, Tochi',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/goliath.jpg',
        url: 'https://www.amazon.com/Goliath-Novel-Tochi-Onyebuchi/dp/1250782953?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The School for Good Mothers',
        author: 'Jessamine Chan',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Daydreamer/goodmothers.jpg',
        url: 'https://www.amazon.com/School-Good-Mothers-Novel/dp/1982156120?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
  },

  peoplePerson: {
    a: [
      {
        title: 'Memphis',
        author: 'Stringfellow, Tara M.',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/memphis.jpg',
        url: 'https://www.amazon.com/Memphis-Novel-Tara-M-Stringfellow/dp/0593230485?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Half-Blown Rose',
        author: 'Leesa Cross-Smith',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/halfblownrose.jpg',
        url: 'https://www.amazon.com/Half-Blown-Rose-Novel-Leesa-Cross-Smith/dp/1538755165?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Sleepwalk',
        author: 'Dan Chaon',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/sleepwalk.jpg',
        url: 'https://www.amazon.com/Sleepwalk-Novel-Dan-Chaon/dp/1250175216?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Such Big Dreams',
        author: 'Reema Patel',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/such-big-dreams.jpg',
        url: 'https://www.amazon.com/Such-Big-Dreams-Reema-Patel/dp/0593499506?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Young Mungo',
        author: 'Douglas Stuart',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/young-mungo.jpg',
        url: 'https://www.amazon.com/Young-Mungo-Douglas-Stuart/dp/0802159559?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Nightcrawling',
        author: 'Leila Mottley',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/nightcrawling.jpg',
        url: 'https://www.amazon.com/Nightcrawling-novel-Leila-Mottley/dp/0593318935?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Other Mother',
        author: 'Rachel M. Harper',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/other-mother.jpg',
        url: 'https://www.amazon.com/Other-Mother-Rachel-M-Harper/dp/1640095047?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Unsinkable Greta James',
        author: 'Smith, Jennifer E.',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/unsinkable.jpg',
        url: 'https://www.amazon.com/Unsinkable-Greta-James-Novel-ebook/dp/B096D6BCTF?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'This Time Tomorrow',
        author: 'Straub, Emma',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/thistime-tomorrow.jpg',
        url: 'https://www.amazon.com/This-Time-Tomorrow-Emma-Straub/dp/052553900X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Cult Classic',
        author: 'Crosley, Sloane',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/cultclassic.jpg',
        url: 'https://www.amazon.com/Cult-Classic-Novel-Sloane-Crosley/dp/0374603391?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Latecomer',
        author: 'Jean Hanff Korelitz',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/latecomer.jpg',
        url: 'https://www.amazon.com/Latecomer-Jean-Hanff-Korelitz/dp/1250790794?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    b: [
      {
        title: 'Book Lovers',
        author: 'Henry, Emily',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/book-lovers-.jpg',
        url: 'https://www.amazon.com/Book-Lovers-Emily-Henry/dp/0593334833?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Reminders of Him',
        author: 'Hoover, Colleen',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/reminders-of-him.jpg',
        url: 'https://www.amazon.com/Reminders-Him-Novel-Colleen-Hoover-ebook/dp/B0976V6YSL?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Seoulmates',
        author: 'Frederick, Jen',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/seoulmates%2Bby%2Bjen%2Bfrederick.jpg',
        url: 'https://www.amazon.com/Seoulmates-Jen-Frederick/dp/0593100166?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: "Delilah Green Doesn't Care",
        author: 'Herring Blake, Ashley',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/greendoesntcare.jpg',
        url: 'https://www.amazon.com/Delilah-Green-Doesnt-Ashley-Herring-ebook/dp/B095MQ95JJ?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Lady’s Mine',
        author: 'Rivers, Francine',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/theladysmine.jpg',
        url: 'https://www.amazon.com/Ladys-Mine-Francine-Rivers/dp/1496447573?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Summer Getaway: A Novel',
        author: 'Mallery, Susan',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/the-summer-getaway-1.jpg',
        url: 'https://www.amazon.com/Summer-Getaway-Novel-Susan-Mallery/dp/1335479996?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Hook, Line, and Sinker: A Novel',
        author: 'Bailey, Tessa',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/hooklinesinker.jpg',
        url: 'https://www.amazon.com/Hook-Line-Sinker-Tessa-Bailey/dp/0063045699?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Dating Dr. Dil: A Novel',
        author: 'Sharma, Nisha',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/datingdill.jpg',
        url: 'https://www.amazon.com/Dating-Dr-Dil-Nisha-Sharma/dp/0063001101?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Part of Your World',
        author: 'Jimenez, Abby',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/partofyourworld.jpg',
        url: 'https://www.amazon.com/Part-Your-World-Abby-Jimenez/dp/1538708728?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The No-Show',
        author: "O'Leary, Beth",
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/thenoshow.jpg',
        url: 'https://www.amazon.com/No-Show-Beth-OLeary-ebook/dp/B09B964FLL?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Fool Me Once',
        author: 'Winstead, Ashley',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/foolmeonce.jpg',
        url: 'https://www.amazon.com/Fool-Me-Once-Ashley-Winstead-ebook/dp/B09717V5Y8?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Set on You',
        author: 'Lea, Amy',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/setonyou.jpg',
        url: 'https://www.amazon.com/Set-You-Amy-Lea-ebook/dp/B09CCKJCB4?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Homewreckers: A Novel',
        author: 'Andrews, Mary Kay',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/homewreckers.jpg',
        url: 'https://www.amazon.com/Homewreckers-Novel-Mary-Kay-Andrews/dp/1250278368?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'By the Book (A Meant To Be Novel): A Meant to be Novel',
        author: 'Guillory, Jasmine',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/bythebook.jpg',
        url: 'https://www.amazon.com/Book-Meant-Be-Novel/dp/1368053386?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'You Made a Fool of Death with Your Beauty: A Novel',
        author: 'Emezi, Akwaeke',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/you-made-a-fool-of-death-with-your-beauty.jpg',
        url: 'https://www.amazon.com/Made-Fool-Death-Your-Beauty/dp/1982188707?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Queerly Beloved: A Novel',
        author: 'Dumond, Susie',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/queerly_beloved.jpg',
        url: 'https://www.amazon.com/Queerly-Beloved-Novel-Susie-Dumond/dp/0593243978?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Nora Goes Off Script',
        author: 'Monaghan, Annabel',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/nora-gos-off-script-.jpg',
        url: 'https://www.amazon.com/Nora-Goes-Script-Annabel-Monaghan/dp/0593420039?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: "A Proposal They Can't Refuse: A Novel",
        author: 'Caña, Natalie',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/aproposaltheycantrefuse.jpg',
        url: 'https://www.amazon.com/Proposal-They-Cant-Refuse-Novel/dp/0778386090?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Boardwalk Bookshop',
        author: 'Mallery, Susan',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/boardwalkbookshop.jpg',
        url: 'https://www.amazon.com/Boardwalk-Bookshop-Novel-Susan-Mallery/dp/0778386082?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    c: [
      {
        title: 'Remarkably Bright Creatures',
        author: 'Van Pelt, Shelby',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/remarkably-bright-creatures.jpg',
        url: 'https://www.amazon.com/Remarkably-Bright-Creatures-Shelby-Pelt/dp/0063204150?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Lessons in Chemistry: A Novel',
        author: 'Bonnie Garmus',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/lessonsinchemistry.jpg',
        url: 'https://www.amazon.com/Lessons-Chemistry-Novel-Bonnie-Garmus/dp/038554734X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Black Cake',
        author: 'Wilkerson, Charmaine',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/black-cake.jpg',
        url: 'https://www.amazon.com/Black-Cake-Novel-Charmaine-Wilkerson/dp/0593358333?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'To Paradise',
        author: 'Yanagihara, Hanya',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/toparadise.jpg',
        url: 'https://www.amazon.com/Paradise-Novel-Hanya-Yanagihara/dp/0385547935?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Four Treasures of the Sky',
        author: 'Zhang, Jenny Tinghui',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/treasuresofthesku.jpg',
        url: 'https://www.amazon.com/Four-Treasures-Jenny-Tinghui-Zhang/dp/1250811783?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Mad Girls of New York: A Nellie Bly Novel',
        author: 'Rodale, Maya',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/madgirlsny.jpg',
        url: 'https://www.amazon.com/Mad-Girls-New-York-Nellie-ebook/dp/B09B96G88P?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Horse',
        author: 'Geraldine Brooks',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/PeoplePerson/horse.jpg',
        url: 'https://www.amazon.com/Horse-Novel-Geraldine-Brooks/dp/0399562966?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
  },

  adventurer: {
    a: [
      {
        title:
          'A Walk in the Woods: Rediscovering America on the Appalachian Trail (Official Guides to the Appalachian Trail)',
        author: 'Bill Bryson',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/awalkinthewoods.jpg',
        url: 'https://www.amazon.com/Walk-Woods-Rediscovering-Appalachian-Official-ebook/dp/B000S1LSAM?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Cabin Fever : The Harrowing Journey of a Cruise Ship at the Dawn of a Pandemic',
        author: 'Michael Smith and Jonathan Franklin',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Cabin-Fever---The-Harrowing-Journey-of-a-Cruise-Ship-at-the-Dawn-of-a-Pandemic.jpg',
        url: 'https://www.amazon.com/Cabin-Fever-Harrowing-Journey-Pandemic/dp/0385547404?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Wastelands : The True Story of Farm Country on Trial',
        author: 'Corban Addison',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/wastelands.jpg',
        url: 'https://www.amazon.com/Wastelands-True-Story-Country-Trial/dp/0593320824?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Bond King: How One Man Made a Market, Built an Empire, and Lost It All',
        author: 'Mary Childs',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/TheBondKing.jpg',
        url: 'https://www.amazon.com/Bond-King-Market-Built-Empire/dp/1250120845?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "The Catch Me If You Can: One Woman's Journey to Every Country in the World",
        author: 'Jessica Nabongo',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/CatchMeIfYouCan.jpg',
        url: 'https://www.amazon.com/Catch-Me-If-You-Can/dp/1426222262?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "Stolen Focus: Why You Can't Pay Attention--and How to Think Deeply Again",
        author: 'Johann Hari',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/stolen-focus.jpg',
        url: 'https://www.amazon.com/Stolen-Focus-Attention-Think-Deeply/dp/0593138511?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "The Monster's Bones : The Discovery of T. Rex and How It Shook Our World",
        author: 'David K. Randall',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/monstersbones.jpg',
        url: 'https://www.amazon.com/Monsters-Bones-Discovery-Shook-World/dp/1324006536?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Pandemic, Inc. : Chasing the Capitalists and Thieves Who Got Rich While We Got Sick',
        author: 'J. David McSwane',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/PandemicInc.jpg',
        url: 'https://www.amazon.com/Pandemic-Inc-Chasing-Capitalists-Thieves/dp/1982177748?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Last Slave Ship: The True Story of How Clotilda Was Found, Her Descendants, and an Extraordinary Reckoning',
        author: 'Ben Raines',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/TheLastSlaveShip.jpg',
        url: 'https://www.amazon.com/Last-Slave-Ship-Descendants-Extraordinary/dp/1982136049?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Speak: Find Your Voice, Trust Your Gut, and Get from Where You Are to Where You Want to Be',
        author: 'Tunde Oyeneyin',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/speak.jpg',
        url: 'https://www.amazon.com/Speak-Find-Voice-Trust-Where/dp/1982195444?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "Wired for Love: A Neuroscientist's Journey Through Romance, Loss, and the Essence of Human Connection",
        author: 'Stephanie Cacioppo',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/WiredForLove.jpg',
        url: 'https://www.amazon.com/Wired-Love-Neuroscientists-Journey-Connection/dp/1250790603?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Woman Beyond the Attic: The V.C. Andrews Story',
        author: 'Andrew Neiderman',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/womanbeyondattic.jpg',
        url: 'https://www.amazon.com/Woman-Beyond-Attic-Andrews-Story/dp/1982182636?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "Let's Get Physical: How Women Discovered Exercise and Reshaped the World",
        author: 'Danielle Friedman',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/LetsGetPhysical.jpg',
        url: 'https://www.amazon.com/Lets-Get-Physical-Discovered-Exercise/dp/059318842X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'How Minds Change : The Surprising Science of Belief, Opinion, and Persuasion',
        author: 'David McRaney',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/how-minds-change.jpg',
        url: 'https://www.amazon.com/How-Minds-Change-Surprising-Persuasion/dp/0593190297?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'River of the Gods: Genius, Courage, and Betrayal in the Search for the Source of the Nile',
        author: 'Candice Millard',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/River-of-the-Gods--Genius,-Courage,-and-Betrayal-in-the-Search-for-the-Source-of-the-Nile.jpg',
        url: 'https://www.amazon.com/River-Gods-Genius-Courage-Betrayal/dp/0385543107?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Bittersweet: How Sorrow and Longing Make Us Whole',
        author: 'Susan Cain',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/BitterSweet.jpg',
        url: 'https://www.amazon.com/Bittersweet-Sorrow-Longing-Make-Whole/dp/0451499786?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: "I'll Show Myself Out: Essays on Midlife and Motherhood",
        author: 'Jessi Klein',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/i-ll-show-myself-out.jpg',
        url: 'https://www.amazon.com/Ill-Show-Myself-Out-Motherhood/dp/0062981595?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "Eating to Extinction: The World's Rarest Foods and Why We Need to Save Them",
        author: 'Dan Saladino',
        cover:
          "https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Eating-to-Extinction--The-World's-Rarest-Foods-and-Why-We-Need-to-Save-Them.jpg",
        url: 'https://www.amazon.com/Eating-Extinction-Worlds-Rarest-Foods/dp/0374605327?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Puzzler',
        author: 'A.J. Jacobs',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Puzzler.jpg',
        url: 'https://www.amazon.com/Puzzler-Baffling-Puzzles-Crosswords-Jigsaws/dp/0593136713?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'How to Be an Antiracist',
        author: 'Ibram X. Kendi',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/HowToBeAnAntiRacist.jpg',
        url: 'https://www.amazon.com/How-Be-Antiracist-Ibram-Kendi/dp/0525509283?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    b: [
      {
        title: 'Corrections in Ink',
        author: 'Keri Blakinger',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/CorrectionInInk.jpg',
        url: 'https://www.amazon.com/Corrections-Ink-Memoir-Keri-Blakinger/dp/1250272858?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Year of the Horses',
        author: 'Courtney Maum',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/The-Year-of-the-Horses-cover-final.jpg',
        url: 'https://www.amazon.com/Year-Horses-Memoir-Courtney-Maum/dp/1953534155?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "Freezing Order: A True Story of Money Laundering, Murder, and Surviving Vladimir Putin's Wrath",
        author: 'Bill Browder',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/FreezingOrder.jpg',
        url: 'https://www.amazon.com/Freezing-Order-Laundering-Surviving-Vladimir/dp/1982153288?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "His Name Is George Floyd: One Man's Life and the Struggle for Racial Justice",
        author: 'Robert Samuels',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/hisnameisgeorgefloyd.jpg',
        url: 'https://www.amazon.com/His-Name-George-Floyd-Struggle/dp/0593490614?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: "Unmasked: My Life Solving America's Cold Cases",
        author: 'Holes, Paul',
        cover:
          "https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Unmasked--My-Life-Solving-America's-Cold-Cases.jpg",
        url: 'https://www.amazon.com/Unmasked-Life-Solving-Americas-Cases/dp/1250622794?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Emergency: A Year of Healing and Heartbreak in a Chicago ER',
        author: 'Thomas Fisher',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/TheEmergency.jpg',
        url: 'https://www.amazon.com/Emergency-Year-Healing-Heartbreak-Chicago/dp/0593230671?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Left on Tenth',
        author: 'Delia Ephron',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/LeftOnTenth.jpg',
        url: 'https://www.amazon.com/Left-Tenth-Second-Chance-Memoir/dp/0316267651?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'South to America',
        author: 'Imani Perry',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/SouthToAmerica.jpg',
        url: 'https://www.amazon.com/South-America-Journey-Understand-Nation/dp/0062977407?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'What My Bones Know: A Memoir of Healing from Complex Trauma',
        author: 'Foo, Stephanie',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/WhatMyBonesKnow.jpg',
        url: 'https://www.amazon.com/What-My-Bones-Know-Healing/dp/0593238109?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Community : A Memoir',
        author: 'N. Jamiyla Chisholm',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/TheCommunity.jpg',
        url: 'https://www.amazon.com/Community-N-Jamiyla-Chisholm/dp/1542037387?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Finding Me: A Memoir',
        author: 'Viola Davis',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/FindingMe.jpg',
        url: 'https://www.amazon.com/Finding-Me-Memoir-Viola-Davis/dp/0063037327?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Hello, Molly!: A Memoir',
        author: 'Shannon, Molly',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Hello,-Molly!--A-Memoir.jpg',
        url: 'https://www.amazon.com/Hello-Molly-Memoir-Shannon/dp/0063056232?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Lost & Found',
        author: 'Kathryn Shulz',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/LostAndFound.jpg',
        url: 'https://www.amazon.com/Lost-Found-Memoir-Kathryn-Schulz/dp/0525512462?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Managing Expectations: A Memoir in Essays',
        author: 'Driver, Minnie',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/ManagingExpectations.jpg',
        url: 'https://www.amazon.com/Managing-Expectations-Memoir-Minnie-Driver/dp/0063115301?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Black Ops: The Life of a CIA Shadow Warrior',
        author: 'Ric Prado',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/BlackOps.jpg',
        url: 'https://www.amazon.com/Black-Ops-Life-Shadow-Warrior/dp/1250271843?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'In Love: A Memoir of Love and Loss',
        author: 'Bloom, Amy',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/In-Love--A-Memoir-of-Love-and-Loss.jpg',
        url: 'https://www.amazon.com/Love-Memoir-Loss/dp/0593243943?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'In the Houses of Their Dead: The Lincolns, the Booths, and the Spirits',
        author: 'Terry Alford',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/InTheHousesOfTheirDead.jpg',
        url: 'https://www.amazon.com/Houses-Their-Dead-Lincolns-Spirits/dp/1631495607?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Shine Bright: A Very Personal History of Black Women in Pop',
        author: 'Danyel Smith',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/ShineBright.jpg',
        url: 'https://www.amazon.com/Shine-Bright-Personal-History-Black/dp/0593132718?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Riverman: An American Odyssey',
        author: 'Ben McGrath',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/riverman.jpg',
        url: 'https://www.amazon.com/Riverman-American-Odyssey-Ben-McGrath/dp/0451494008?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    c: [
      {
        title: 'Making History: The Storytellers Who Shaped the Past',
        author: 'Richard Cohen',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Making-History--The-Storytellers-Who-Shaped-the-Past.jpg',
        url: 'https://www.amazon.com/Making-History-Storytellers-Shaped-Past/dp/1982195789?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'We Refuse to Forget: A True Story of Black Creeks, American Identity, and Power',
        author: 'Caleb Gayle',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/werefusetoforget.jpg',
        url: 'https://www.amazon.com/We-Refuse-Forget-American-Identity/dp/0593329589?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Palace Papers: Inside the House of Windsor--the Truth and the Turmoil',
        author: 'Tina Brown',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/ThePalacePapers.jpg',
        url: 'https://www.amazon.com/Palace-Papers-Inside-Windsor-Turmoil/dp/0593138090?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'In the Shadow of the Gods: The Emperor in World History',
        author: 'Dominic Lieven',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/InTheShadowOfTheGods.jpg',
        url: 'https://www.amazon.com/Shadow-Gods-Emperor-World-History/dp/0735222193?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'World Travel: An Irreverent Guide',
        author: 'Anthony Bourdain',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/World-Travel--An-Irreverent-Guide.jpg',
        url: 'https://www.amazon.com/World-Travel-Irreverent-Anthony-Bourdain/dp/0062802798?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Indelible City: Dispossession and Defiance in Hong Kong',
        author: 'Louisa Lim',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/IndelibleCity.jpg',
        url: 'https://www.amazon.com/Indelible-City-Dispossession-Defiance-Hong/dp/0593191811?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Adriatic: A Concert of Civilizations at the End of the Modern Age',
        author: 'Robert D. Kaplan',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Adriatic.jpg',
        url: 'https://www.amazon.com/Adriatic-Concert-Civilizations-End-Modern/dp/0399591044?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'My America: Recipes from a Young Black Chef: A Cookbook',
        author: 'Kwame Onwuachi',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/MyAmerica.jpg',
        url: 'https://www.amazon.com/My-America-Recipes-Young-Black/dp/0525659609?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "The Facemaker: A Visionary Surgeon's Battle to Mend the Disfigured Soldiers of World War I",
        author: 'Lindsey Fitzharris',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/facemaker.jpg',
        url: 'https://www.amazon.com/Facemaker-Visionary-Surgeons-Disfigured-Soldiers/dp/0374282307?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Butler to the World',
        author: 'Oliver Bullough',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Butler-to-the-World.jpg',
        url: 'https://www.amazon.com/Butler-World-Britain-Launder-Anything/dp/125028192X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Sandy Hook: An American Tragedy and the Battle for Truth',
        author: 'Elizabeth Williamson',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Sandy-Hook--An-American-Tragedy-and-the-Battle-for-Truth.jpg',
        url: 'https://www.amazon.com/Sandy-Hook-American-Tragedy-Battle/dp/1524746576?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Sex Lives of African Women: Self-Discovery, Freedom, and Healing',
        author: 'Nana Darkoa Sekyiamah',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/TheSexLivesOfAfricanWomen.jpg',
        url: 'https://www.amazon.com/Sex-Lives-African-Women-Self-Discovery/dp/1662650817?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          "The Desperate Hours: One Hospital's Fight to Save a City on the Pandemic's Front Lines",
        author: 'Marie Brenner',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/TheDesperateHours.jpg',
        url: 'https://www.amazon.com/Desperate-Hours-Hospitals-Fight-Pandemics/dp/1250805732?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Why We Fight: The Roots of War and the Paths to Peace',
        author: 'Christopher Blattman',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/WhyWeFight.jpg',
        url: 'https://www.amazon.com/Why-We-Fight-Roots-Paths/dp/1984881574?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Nineties: A Book',
        author: 'Chuck Klosterman',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/the-nineties-2.jpg',
        url: 'https://www.amazon.com/Nineties-Book-Chuck-Klosterman/dp/0735217955?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The First Kennedys: The Humble Roots of an American Dynasty',
        author: 'Neal Thompson',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/The-First-Kennedys--The-Humble-Roots-of-an-American-Dynasty.jpg',
        url: 'https://www.amazon.com/First-Kennedys-Humble-American-Dynasty/dp/0358437695?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: "The Bald Eagle: The Improbable Journey of America's Bird",
        author: 'Jack E. Davis',
        cover:
          "https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/The-Bald-Eagle--The-Improbable-Journey-of-America's-Bird.jpg",
        url: 'https://www.amazon.com/Bald-Eagle-Improbable-Journey-Americas/dp/1631495259?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Crown & Sceptre: A New History of the British Monarchy, from William the Conqueror to Elizabeth II',
        author: 'Tracy Borman',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Adventurer/Crown-&-Sceptre--A-New-History-of-the-British-Monarchy,-from-William-the-Conqueror-to-Elizabeth-II.jpg',
        url: 'https://www.amazon.com/Crown-Sceptre-Monarchy-Conqueror-Elizabeth/dp/0802159109?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
  },
  creator: {
    a: [
      {
        title: 'Artists: Their Lives and Works',
        author: 'DK, Ross King',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/ArtistsTheirLivesAndWorks.jpg',
        url: 'https://www.amazon.com/Artists-Their-Lives-Works-DK/dp/1465463283?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Seven Days in the Art World',
        author: 'Sarah Thornton ',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/sevendaysintheartworld.jpg',
        url: 'https://www.amazon.com/Seven-Days-World-Sarah-Thornton/dp/039333712X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'How We Might Live: At Home with Jane and William Morris',
        author: 'Suzanna Cooper',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/HowWeMightLiveAtHome.jpg',
        url: 'https://www.amazon.com/Might-Live-Suzanne-Fagence-Cooper-ebook/dp/B08KZLR752?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Letters of Vincent Van Gogh',
        author: 'Vincent Van Gogh',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/TheLettersOfVincentVanGogh.jpg',
        url: 'https://www.amazon.com/Letters-Vincent-Gogh-Mark-Roskill/dp/1416580867?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Design of Everyday Things',
        author: 'Donald A. Norman',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/thedesignofeverydaythings.jpg',
        url: 'https://www.amazon.com/Design-Everyday-Things-Revised-Expanded-ebook/dp/B00E257T6C?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    b: [
      {
        title: 'Humans of New York',
        author: 'Brandon Stanton',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/HumansOfNewYork.jpg',
        url: 'https://www.amazon.com/Humans-New-York-Brandon-Stanton/dp/1250038820?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Americans',
        author: 'Robert Frank',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/TheAmericans.jpg',
        url: 'https://www.amazon.com/Americans-Robert-Frank/dp/386521584X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Camera Lucida: Reflections on Photography',
        author: 'Roland Barthes',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/CameraLucida-ReflectionsOnPhotography-.jpg',
        url: 'https://www.amazon.com/Camera-Lucida-Reflections-Roland-Barthes/dp/0374532338?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Fred Herzog: Modern Color',
        author: 'Fred Herzog',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/fred-herzog-modern-color.jpg',
        url: 'https://www.amazon.com/Fred-Herzog-Modern-Color/dp/377574181X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Wall and Piece',
        author: 'Banksy',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/wallandpiece.jpg',
        url: 'https://www.amazon.com/Wall-Piece-Banksy/dp/1844137872?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    c: [
      {
        title:
          'Find Your Artistic Voice: The Essential Guide to Working Your Creative Magic',
        author: 'Lisa Congdon',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/FindYourArtisticVoice.jpg',
        url: 'https://www.amazon.com/Find-Your-Artistic-Voice-Essential/dp/1452168865?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Design, Second Edition: The Definitive Visual Guide',
        author: 'DK',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/design.jpg',
        url: 'https://www.amazon.com/Design-Second-DK/dp/1465491376?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Steal Like an Artist: 10 Things Nobody Told You About Being Creative',
        author: 'Austin Kleon',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/steallikeanartist.jpg',
        url: 'https://www.amazon.com/Steal-Like-Artist-Things-Creative/dp/0761169253?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Shock of the New',
        author: 'Robert Hughes',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Creator/shockofnew.jpg',
        url: 'https://www.amazon.com/Shock-New-Hundred-Year-Art-Its-Achievement/dp/0679728767?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
  },

  goGetter: {
    a: [
      {
        title:
          'From Strength to Strength: Finding Success, Happiness, and Deep Purpose in the Second Half of Life',
        author: 'Arthur C. Brooks',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/fromstrengthtostrength.jpg',
        url: 'https://www.amazon.com/Strength-Finding-Success-Happiness-Purpose/dp/059319148X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The End of Burnout: Why Work Drains Us and How to Build Better Lives',
        author: 'Jonathan Malesic',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/theendofburnout.jpg',
        url: 'https://www.amazon.com/End-Burnout-Drains-Build-Better/dp/0520344073?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Power Law: Venture Capital and the Making of the New Future',
        author: 'Sebastian Mallaby',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/powerlaw.jpg',
        url: 'https://www.amazon.com/Power-Law-Venture-Capital-Making/dp/052555999X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Voltage Effect: How to Make Good Ideas Great and Great Ideas Scale',
        author: 'John A. List',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/voltageeffect.jpg',
        url: 'https://www.amazon.com/Voltage-Effect-Ideas-Great-Scale/dp/0593239482?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Collective Illusions: Conformity, Complicity, and the Science of Why We Make Bad Decisions',
        author: 'Todd Rose',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/collectiveillusions.jpg',
        url: 'https://www.amazon.com/Collective-Illusions-Conformity-Complicity-Decisions/dp/0306925680?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'How to Talk to Your Boss About Race: Speaking Up Without Getting Shut Down',
        author: 'Y-Vonne Hutchinson',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/howtotalktoyourboss.jpg',
        url: 'https://www.amazon.com/Talk-Your-Boss-About-Race/dp/0593418093?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Abundance: The Inner Path to Wealth',
        author: 'Deepak Chopra',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/abundance.jpg',
        url: 'https://www.amazon.com/Abundance-Wealth-Deepak-Chopra-MD/dp/0593233794?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Bond King: How One Man Made a Market, Built an Empire, and Lost It All',
        author: 'Mary Childs',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/The-Bond-King.jpg',
        url: 'https://www.amazon.com/Bond-King-Market-Built-Empire/dp/1250120845?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Be a Triangle: How I Went from Being Lost to Getting My Life into Shape',
        author: 'Lilly Singh',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/beatriangle.jpg',
        url: 'https://www.amazon.com/Be-Triangle-Being-Getting-Shape/dp/0593357817?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Thinking like an Economist: How Efficiency Replaced Equality in U.S. Public Policy',
        author: 'Elizabeth Popp Berman',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/thinkinglikeaneconomist.jpg',
        url: 'https://www.amazon.com/Thinking-like-Economist-Efficiency-Replaced/dp/0691167389?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    b: [
      {
        title: 'Your Data, Their Billions: Unraveling and Simplifying Big Tech',
        author: 'Jane S. Hoffman, Natalie Duke, et al.',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/your-data-their-billions.jpg',
        url: 'https://www.amazon.com/Your-Data-Their-Billions-Simplifying/dp/1637580746?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Speak: Find Your Voice, Trust Your Gut, and Get From Where You Are to Where You Want to Be',
        author: 'Tunde Oyeneyin',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/speak.jpg',
        url: 'https://www.amazon.com/Speak-Find-Voice-Trust-Where/dp/1982195444?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'How Minds Change : The Surprising Science of Belief, Opinion, and Persuasion',
        author: 'David McRaney',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/howmindschange.jpg',
        url: 'https://www.amazon.com/How-Minds-Change-Surprising-Persuasion/dp/0593190297?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Build: An Unorthodox Guide to Making Things Worth Making',
        author: 'Tony Fadell',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/build.jpg',
        url: 'https://www.amazon.com/Build-Unorthodox-Guide-Making-Things/dp/0063046067?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Power of One More: The Ultimate Guide to Happiness and Success',
        author: 'Ed Mylett',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/the-power-of-one-more.jpg',
        url: 'https://www.amazon.com/Power-One-More-Ed-Mylett/dp/1119815363?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Power of Crisis: How Three Threats – and Our Response – Will Change the World',
        author: 'Ian Bremmer',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/the-power-of-crisis.jpg',
        url: 'https://www.amazon.com/Power-Crisis-Threats-Response-Change/dp/1982167505?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Burn Rate: Launching a Startup and Losing My Mind',
        author: 'Andy Dunn',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/burnrate.jpg',
        url: 'https://www.amazon.com/Burn-Rate-Launching-Startup-Losing/dp/0593238265?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Baby Steps Millionaires: How Ordinary People Built Extraordinary Wealth--and How You Can Too',
        author: 'Dave Ramsey',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/babystepsmillionaires.jpg',
        url: 'https://www.amazon.com/Baby-Steps-Millionaires-Extraordinary-Wealth/dp/1942121598?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Earned Life: Lose Regret, Choose Fulfillment',
        author: 'Marshall Goldsmith',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/earnedlife.jpg',
        url: 'https://www.amazon.com/Earned-Life-Regret-Choose-Fulfillment/dp/0593237277?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Power of Regret: How Looking Backward Moves Us Forward',
        author: 'Daniel H. Pink',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/the-power-of-regret.jpg',
        url: 'https://www.amazon.com/Power-Regret-Looking-Backward-Forward/dp/0735210659?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    c: [
      {
        title: 'The Modern Proper',
        author: 'Holly Erickson',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/modernproper.jpg',
        url: 'https://www.amazon.com/Modern-Proper-Simple-Dinners-Cookbook/dp/1982177667?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Wok: Recipes and Techniques',
        author: 'J. Kenji López-Alt',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/the-wok-recipes-and-techniques.jpg',
        url: 'https://www.amazon.com/Wok-Techniques-J-Kenji-L%C3%B3pez-Alt/dp/0393541215?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Mi Cocina: Recipes and Rapture from My Kitchen in Mexico: A Cookbook',
        author: 'Rick Martínez',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/mi-cocina.jpg',
        url: 'https://www.amazon.com/Mi-Cocina-Recipes-Rapture-Cookbook/dp/0593138708?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Watermelon and Red Birds: A Cookbook for Juneteenth and Black Celebrations',
        author: 'Nicole A. Taylor',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/watermelon-and-red-birds.jpg',
        url: 'https://www.amazon.com/Watermelon-Red-Birds-Juneteenth-Celebrations/dp/1982176210?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Half Baked Harvest Every Day: Recipes for Balanced, Flexible, Feel-Good Meals',
        author: 'Tieghan Gerard',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/halfbakedharvesteveryday.jpg',
        url: 'https://www.amazon.com/Half-Baked-Harvest-Every-Day/dp/0593232550?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'The Forest Feast Road Trip: Simple Vegetarian Recipes Inspired by My Travels through California',
        author: 'Erin Gleeson',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/RoadTripCover.jpg',
        url: 'https://www.amazon.com/Forest-Feast-Road-Trip-Vegetarian/dp/1419744259?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Crazy for Cookies, Brownies, and Bars: Super-Fast, Made-from-Scratch Sweets, Treats, and Desserts',
        author: 'Dorothy Kern',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/Crazy-for-Cookies,-Brownies,-and-Bars.jpg',
        url: 'https://www.amazon.com/Crazy-Cookies-Brownies-Made-Scratch/dp/0760372810?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: "Turkey and the Wolf: Flavor Trippin' in New Orleans",
        author: 'Mason Hereford',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/turkeyandthewolf.jpg',
        url: 'https://www.amazon.com/Turkey-Wolf-Trippin-Orleans-Cookbook/dp/1984858998?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'My America: Recipes from a Young Black Chef: A Cookbook Hardcover – May 17, 2022',
        author: 'Kwame Onwuachi',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/MyAmerica.jpg',
        url: 'https://www.amazon.com/My-America-Recipes-Young-Black/dp/0525659609?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Cocktail Chemistry: The Art and Science of Drinks from Iconic TV Shows and Movies',
        author: 'Nick Fisher',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/CocktailChemistry.jpg',
        url: 'https://www.amazon.com/Cocktail-Chemistry-Science-Drinks-Iconic/dp/1982167424?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: "Bludso's BBQ Cookbook: A Family Affair in Smoke and Soul",
        author: 'Kevin Bludso',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/bludsosbbqcookbook.jpg',
        url: 'https://www.amazon.com/Bludsos-BBQ-Cookbook-Family-Affair/dp/1984859552?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Good Eats: The Final Years',
        author: 'Alton Brown',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/GoodEats.jpg',
        url: 'https://www.amazon.com/Good-Eats-Final-Alton-Brown/dp/1419753525?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'PlantYou: 140+ Ridiculously Easy, Amazingly Delicious Plant-Based Oil-Free Recipes',
        author: 'Carleigh Bodrug',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/PlantYou.jpg',
        url: 'https://www.amazon.com/PlantYou-Ridiculously-Amazingly-Delicious-Plant-Based/dp/0306923041?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Boards',
        author: "America's Test Kitchen",
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/boards.jpg',
        url: 'https://www.amazon.com/Boards-Stylish-Spreads-Casual-Gatherings/dp/1954210000?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          '101 Thai Dishes You Need to Cook Before You Die: The Essential Recipes, Techniques and Ingredients of Thailand',
        author: 'Jet Tila',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/101-thai-dishes-you-need-to-cook-before-you-die.jpg',
        url: 'https://www.amazon.com/Thai-Dishes-Need-Cook-Before/dp/1645673669?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Gullah Geechee Home Cooking',
        author: 'Emily Meggett',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/GullahGeechee.jpg',
        url: 'https://www.amazon.com/Gullah-Geechee-Home-Cooking-Matriarch/dp/1419758780?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Thali: A Joyful Celebration of Indian Home Cooking',
        author: 'Maunika Gowardhan',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/Thali.jpg',
        url: 'https://www.amazon.com/Thali-Joyful-Celebration-Indian-Cooking/dp/1784884588?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title:
          'Sam the Cooking Guy: Between the Buns: Burgers, Sandwiches, Tacos, Burritos, Hot Dogs & More',
        author: 'Sam Zien',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/sam-the-cooking-guy-between-the-buns-burgers-sandwiches-tacos-burritos-hot-dogs-more-1.jpg',
        url: 'https://www.amazon.com/Sam-Cooking-Guy-Sandwiches-Burritos/dp/1682686884?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Korean American: Food That Tastes Like Home',
        author: 'Eric Kim',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/koreanamerican.jpg',
        url: 'https://www.amazon.com/Korean-American-Food-That-Tastes/dp/0593233492?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Cook You Want to Be: Everyday Recipes to Impress',
        author: 'Andy Baraghani',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/Go-Getter/thecookyouwanttobe.jpg',
        url: 'https://www.amazon.com/Cook-You-Want-Be-Everyday/dp/1984858564?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
  },

  thrillSeeker: {
    a: [
      {
        title: 'The Maid',
        author: 'Prose, Nita',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/themaid.jpg',
        url: 'https://www.amazon.com/Maid-Novel-Nita-Prose/dp/0593356152?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'City on Fire',
        author: 'Winslow, Don',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/cityonfire.jpg',
        url: 'https://www.amazon.com/City-Fire-Novel-Don-Winslow/dp/0062851195?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'One-Shot Harry',
        author: 'Phillips, Gary',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/oneshotharry.jpg',
        url: 'https://www.amazon.com/One-Shot-Harry-Gary-Phillips/dp/1641292911?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Bangalore Detectives Club',
        author: 'Nagendra, Harini',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/TheBangaloreDetectivesClub.jpg',
        url: 'https://www.amazon.com/Bangalore-Detectives-Kaveri-Murder-Mystery/dp/140871518X?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Blood Sugar',
        author: 'Rothchild, Sascha',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/bloodsugar.jpg',
        url: 'https://www.amazon.com/Blood-Sugar-Sascha-Rothchild/dp/0593331540?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Love of My Life',
        author: 'Walsh, Rosie',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/TheLoveOfMyLife.jpg',
        url: 'https://www.amazon.com/Love-My-Life-Novel/dp/0593296990?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Hidden Pictures',
        author: 'Rekulak, Jason',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/HiddenPictures.jpg',
        url: 'https://www.amazon.com/Hidden-Pictures-Novel-Jason-Rekulak/dp/1250819342?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    b: [
      {
        title: 'The Overnight Guest',
        author: 'Gudenkauf, Heather',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/TheOvernightGuest.jpg',
        url: 'https://www.amazon.com/Overnight-Guest-Novel-Heather-Gudenkauf/dp/0778311937?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Violence',
        author: 'Dawson, Delilah S.',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/theviolence.jpg',
        url: 'https://www.amazon.com/Violence-Novel-Delilah-S-Dawson/dp/0593156625?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'A Flicker in the Dark',
        author: 'Willingham, Stacy',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/aflickerinthedark.jpg',
        url: 'https://www.amazon.com/Flicker-Dark-Novel-Stacy-Willingham/dp/1250803829?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Island',
        author: 'McKinty, Adrian',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/theisland.jpg',
        url: 'https://www.amazon.com/Island-Adrian-McKinty/dp/0316531286?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Children on the Hill',
        author: 'McMahon, Jennifer',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/TheChildrenOnTheHill.jpg',
        url: 'https://www.amazon.com/Children-Hill-Jennifer-McMahon/dp/1982153954?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'One of Us Is Dead',
        author: 'Rose, Jeneva',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/oneofusisdead.jpg',
        url: 'https://www.amazon.com/One-Us-Dead-Jeneva-Rose/dp/B09BDRZR42?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Two Nights in Lisbon',
        author: 'Pavone, Chris',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/twonightsinlisbon.jpg',
        url: 'https://www.amazon.com/Two-Nights-Lisbon-Chris-Pavone/dp/0374604762?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Chase',
        author: 'Fox, Candice',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/TheChase.jpg',
        url: 'https://www.amazon.com/Chase-Candice-Fox/dp/1250798833?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
    c: [
      {
        title: 'Carolina Moonset',
        author: 'Goldman, Matt',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/CarolinaMoonset.jpg',
        url: 'https://www.amazon.com/Carolina-Moonset-Matt-Goldman/dp/1250810140?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Christie Affair',
        author: 'Gramont, Nina de',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/TheChristineAffair.jpg',
        url: 'https://www.amazon.com/Christie-Affair-Novel-Nina-Gramont/dp/1250274613?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Once a Thief',
        author: 'Reich, Christopher',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/OnceAThief.jpg',
        url: 'https://www.amazon.com/Once-Thief-Christopher-Reich/dp/0316456098?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'When You Are Mine',
        author: 'Robotham, Michael',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/whenyouaremine.jpg',
        url: 'https://www.amazon.com/When-You-Are-Mine-Novel/dp/1982166452?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Hide',
        author: 'White, Kiersten',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/Hide.jpg',
        url: 'https://www.amazon.com/Hide-Kiersten-White/dp/0593359232?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'Sleepwalk',
        author: 'Dan Chaon',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/sleepwalk.jpg',
        url: 'https://www.amazon.com/Sleepwalk-Novel-Dan-Chaon/dp/1250175216?ref_=abfm_22_q4_us_sc_bksona',
      },
      {
        title: 'The Cartographers',
        author: 'Shepherd, Peng',
        cover:
          'https://amazon-booksona-assets.s3.amazonaws.com/covers/ThrillSeeker/TheCartographers.jpg',
        url: 'https://www.amazon.com/Cartographers-Novel-Peng-Shepherd/dp/0062910698?ref_=abfm_22_q4_us_sc_bksona',
      },
    ],
  },
};
