// After importing a-frame THREE-JS becomes a global var - https://aframe.io/docs/1.3.0/introduction/developing-with-threejs.html
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface Global {
      THREE: any;
      aframe: any;
    }
  }
}

export interface VRSelectorSceneItem {
  component: string;
  value: string;
  message?: string;
}

export const overlay = (id: string) => `overlay-${id.toLowerCase()}`;
export const title = (id: string) => `title-${id.toLowerCase()}`;

export const CAMERA_ELEMENT = 'camera-controller';

export enum Direction {
  TOP = 'TOP',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
}

export interface Boundaries {
  [Direction.TOP]: number;
  [Direction.RIGHT]: number;
  [Direction.BOTTOM]: number;
  [Direction.LEFT]: number;
}

export const MAX_INTERSECTION_DISTANCE = 1.35;
