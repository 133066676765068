import theme from 'utils/styles/theme';

const MultiColorHeadVariantsTransition = { duration: 0.3, ease: 'easeInOut' };
export const MultiColorHeadVariants = {
  headsWrapper: [
    {
      initial: {
        opacity: 1,
        color: theme.colors.pink,
        transition: MultiColorHeadVariantsTransition,
      },
      animate: {
        opacity: 1,
        color: theme.colors.pink,
        transition: MultiColorHeadVariantsTransition,
      },
    },
    {
      initial: {
        opacity: 0,
        color: theme.colors.limeGreen,
        rotate: 0,
        transition: MultiColorHeadVariantsTransition,
      },
      animate: {
        opacity: 1,
        rotate: [null, -90],
        transition: MultiColorHeadVariantsTransition,
      },
    },
    {
      initial: {
        opacity: 0,
        color: theme.colors.yellow,
        rotate: -90,
        transition: MultiColorHeadVariantsTransition,
      },
      animate: {
        opacity: 1,
        rotate: [null, -180],
        transition: MultiColorHeadVariantsTransition,
      },
    },
    {
      initial: {
        opacity: 0,
        color: theme.colors.orange,
        rotate: -180,
        transition: MultiColorHeadVariantsTransition,
      },
      animate: {
        opacity: 1,
        rotate: [null, -270],
        transition: MultiColorHeadVariantsTransition,
      },
    },
  ],
};

const AnswerTransition = { duration: 0.3, ease: 'easeInOut' };
export const MultiColorHeadAnswerVariants = {
  visible: { opacity: 1, transition: AnswerTransition },
  hidden: { opacity: 0, transition: AnswerTransition },
};
