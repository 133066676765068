import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ArrowIcon from 'svgs/interactives/arrow.svg';
import KeyholeSvg from 'svgs/questions/keyhole/keyhole.svg';
import KeyholeClipSvg from 'svgs/questions/keyhole/keyholeClip.svg';
import media from 'utils/styles/mediaQueries';
import {
  mobileQuestionStyles,
  splitQuestionStyles,
  zIndex,
} from 'utils/styles/mixins';

import { QuestionTitle, QuestionWrapper } from '../QuestionCommons.styled';

import BaseButton from '1_components/Buttons/BaseButton/BaseButton';
import ParallaxWrapper from '1_components/ParallaxWrapper/ParallaxWrapper';
import SwiperSelector from '1_components/SwiperSelector/SwiperSelector';
import Typography from '1_components/Typography/Typography';

export const KeyholeQuestionWrapper = styled(QuestionWrapper)`
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

export const KeyholeQuestionTitle = styled(QuestionTitle)`
  ${splitQuestionStyles}
`;

export const KeyholeQuestionTitleMobile = styled(QuestionTitle)`
  ${mobileQuestionStyles};
`;

export const KeyholeWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const keyholeStyle = css`
  position: absolute;
  height: 100%;
  width: 100%;
  clip-path: url(#keyholeClip);
`;

export const ParallaxKeyhole = styled(ParallaxWrapper)`
  ${keyholeStyle}
`;

export const Keyhole = styled(motion.div)`
  ${keyholeStyle}
`;

export const KeyholeInner = styled(motion.img)`
  position: absolute;
  object-fit: cover;
  height: 100%;
  inset: 0;
  clip-path: none;
  background: transparent;
`;

export const KeyholeSwiperWrapper = styled.div`
  position: relative;
  overflow: visible;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  row-gap: 32px;

  height: 100%;
  margin: 0;

  ${media.mobileSmall} {
    row-gap: 24px;
  }

  ${media.desktop} {
    row-gap: 0;
    margin: 0 0 0 50%;
    padding-bottom: 5%;
  }
`;

export const KeyholeSwiperSelector = styled(SwiperSelector)`
  overflow: visible;
  height: 35vh;
  margin-top: -10%;

  ${media.mobileSmall} {
    height: 30vh;
  }

  ${media.desktop} {
    margin-top: 0;
    height: 40vh;
  }
`;

export const KeyholeNavigation = styled.div`
  display: none;

  ${media.desktop} {
    position: absolute;
    display: flex;
    justify-content: space-around;

    height: fit-content;
    z-index: ${zIndex('aboveContent')};

    width: 50vw;
    inset: 50% 0;
    pointer-events: none;
  }
`;

export const KeyholeAnswerWrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
`;

export const KeyholeAnswer = styled(Typography)`
  position: absolute;
  font-size: 4rem;
  line-height: 90%;
  transform: translateX(-50%);

  ${media.desktop} {
    margin-top: 32px;
    font-size: 5.6rem;
  }
`;

export const ArrowButton = styled(BaseButton)<{ disabled: boolean }>`
  cursor: pointer;
  pointer-events: all;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      cursor: auto;
    `}
`;

export const SvgArrowLeft = styled(ArrowIcon)`
  color: ${({ theme }) => theme.colors.white};
  transform: rotate(180deg);
`;

export const SvgArrowRight = styled(ArrowIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export const KeyholeClip = styled(KeyholeClipSvg)`
  position: absolute;
`;

export const KeyholeOverlay = styled(KeyholeSvg)`
  position: relative;
  height: 100%;
`;
