import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const SwiperSelectorSlider = styled(Swiper)`
  width: 100%;
  height: 100%;
  * {
    cursor: grab;
    user-select: none;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
