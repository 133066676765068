import styled from 'styled-components';

import BOTTOM from 'svgs/directionSigns/Bottom.svg';
import LEFT from 'svgs/directionSigns/Left.svg';
import RIGHT from 'svgs/directionSigns/Right.svg';
import TOP from 'svgs/directionSigns/Up.svg';

export const VRSelectorWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const VRSelectorArrowWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  user-select: none;
`;

export const ArrowTop = styled(TOP)`
  transform: scale(0.7);
`;

export const ArrowRight = styled(RIGHT)`
  transform: scale(0.7);
`;

export const ArrowBottom = styled(BOTTOM)`
  transform: scale(0.7);
`;

export const ArrowLeft = styled(LEFT)`
  transform: scale(0.7);
`;
