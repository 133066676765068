import { motion } from 'framer-motion';
import styled from 'styled-components';

import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';
import theme from 'utils/styles/theme';

export const ProgressBarWrapper = styled(motion.div)<{ $color: string }>`
  width: 100vw;
  height: 8px;
  transform-origin: top left;

  background-color: ${({ $color }) => $color};
  transition: background-color 0.3s ease-in-out;
  z-index: ${zIndex('aboveOverlay')};
  opacity: ${({ $color }) =>
    $color === theme.colors.amazonInk ? '0.5' : '0.7'};

  ${media.desktop} {
    height: 12px;
  }
`;
