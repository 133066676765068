import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ArrowIcon from 'svgs/resultPage/arrowIcon.svg';
import media from 'utils/styles/mediaQueries';
import { zIndex } from 'utils/styles/mixins';

import PrimaryButton from '1_components/Buttons/PrimaryButton/PrimaryButton';
import RichTypography from '1_components/RichTypography/RichTypography';
import Typography from '1_components/Typography/Typography';

export const ShareContainerStyles = css`
  position: absolute;
  bottom: -20px;
  right: 0;
  left: 0;
  margin: auto;
  transform: scale(0.45);

  width: fit-content;

  ${media.custom(344)} {
    bottom: -22px;
    transform: scale(0.55);
  }

  ${media.custom(390)} {
    bottom: -24px;
    transform: scale(0.6);
  }

  ${media.custom(450)} {
    transform: scale(0.7);
  }

  ${media.custom(540)} {
    bottom: -22px;
    transform: scale(0.8);
  }

  ${media.tablet} {
    bottom: -16px;
    transform: scale(1);
  }

  ${media.custom(790)} {
    bottom: -15px;
    transform: scale(1.2);
  }
`;

export const GraphicResultsWrapper = styled.section`
  display: grid;
  place-items: center;

  width: 100%;
  text-align: center;
`;

export const ResultsAndDownloadWrapper = styled.span`
  display: grid;
  place-items: center;

  width: 100%;
  height: 100%;
`;

export const ResultsWrapper = styled.div`
  display: grid;
  place-items: center;

  width: 100%;
`;

export const GraphicWithDownloadWrapper = styled.div`
  position: relative;

  display: grid;
  row-gap: 40px;
  place-items: center;

  width: 100%;
`;

export const GraphicWrapper = styled.div`
  position: relative;

  width: 100%;

  overflow: hidden;

  ${media.desktop} {
    width: calc(100% - 80px);
    aspect-ratio: 16/9;
    border-radius: 10px;

    transition: border-color 0.3s ease-in-out;
  }

  ${media.desktopWide} {
    max-width: unset;
  }
`;

export const ResultsWithShareWrapper = styled.div`
  display: grid;
  place-items: center;

  width: 100%;
  margin-bottom: 40px;
`;

export const GraphicWithShareWrapper = styled.div`
  display: grid;
  row-gap: 24px;
  place-items: center;

  width: 100%;
  max-width: 320px;
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 40px;
  }
`;

export const ShareButton = styled(PrimaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;

  width: 144px;
  padding: 16px 32px;

  svg {
    width: 16px;
    height: 16px;
    transform: scale(1.25);
  }
`;

export const ScrollDownMessage = styled.div`
  display: grid;
  place-items: center;
  row-gap: 16px;
  margin: 77px 0 64px;
`;

export const ProfileScrollDownMessage = styled(ScrollDownMessage)<{
  $hidden: boolean;
}>`
  margin: 56px 0 32px;

  ${media.desktop} {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 32px;
    margin: auto;
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;

    ${({ $hidden }) =>
      $hidden &&
      css`
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        user-select: none;
      `}
  }
`;

export const HiddingScrollMessage = styled(motion.div)`
  position: fixed;
  inset: auto 0 0 0;
  display: grid;
  justify-content: center;
  pointer-events: none;
  user-select: none;

  color: ${({ theme }) => theme.colors.white};
  z-index: ${zIndex('aboveOverlay')};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  padding-top: 80px;
`;

export const MessageWrapper = styled.div`
  display: grid;
  place-items: center;
  row-gap: 16px;
`;

export const Message = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 120%;
`;

export const SvgArrow = styled(ArrowIcon)`
  color: ${({ theme }) => theme.colors.limeGreen};
  width: 24px;
`;

export const TextWrapper = styled.div`
  position: absolute;
  display: grid;
  place-content: center;
  grid-template-columns: 1fr;
  color: ${({ theme }) => theme.colors.black};
  row-gap: 5%;

  inset: 0;

  max-width: 100%;
  height: 100%;
  padding: 44px 20% 52px;

  ${media.tablet} {
    padding: 44px 16% 52px;
  }

  ${media.desktop} {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 10%;
    row-gap: unset;
    column-gap: 40px;
  }

  ${media.custom(1600)} {
    column-gap: 80px;
  }
`;

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled(RichTypography)`
  font-size: 2.8rem;
  text-transform: uppercase;
  line-height: 120%;
  letter-spacing: 0.05em;

  ${media.tablet} {
    font-size: 6.2rem;
  }

  ${media.desktop} {
    font-size: 4.6rem;
  }

  ${media.custom(1600)} {
    font-size: 4.8rem;
  }

  ${media.custom(1800)} {
    font-size: 5.1rem;
  }

  ${media.custom(1920)} {
    font-size: 5.6rem;
  }
`;

export const Info = styled(RichTypography)`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  margin: 4px 0 8px;

  ${media.custom(390)} {
    margin: 4px 0 24px;
  }

  ${media.tablet} {
    font-size: 3.8rem;
  }

  ${media.desktop} {
    font-size: 2.2rem;
  }

  ${media.custom(1600)} {
    font-size: 2.7rem;
    margin: 8px 0 34px;
  }

  ${media.custom(1800)} {
    font-size: 2.4rem;
  }

  ${media.custom(1920)} {
    font-size: 3.2rem;
  }
`;

export const Description = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.46rem;
  opacity: 0.8;

  width: fit-content;

  &&& {
    white-space: break-spaces;
  }

  ${media.tablet} {
    font-size: 4.2rem;
    line-height: 4.3rem;
  }

  ${media.desktop} {
    font-size: 2.6rem;
    line-height: 2.8rem;
  }

  ${media.custom(1600)} {
    font-size: 2.8rem;
    line-height: 3.56rem;
  }

  ${media.custom(1800)} {
    font-size: 3.1rem;
    line-height: 3.76rem;
  }

  ${media.custom(1920)} {
    font-size: 3.6rem;
  }
`;

export const ProfileWrapper = styled.section`
  position: relative;

  width: 100%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 9/16;

  img {
    width: 100%;
    height: 100%;
  }

  ${media.desktop} {
    aspect-ratio: unset;
  }
`;

export const GraphicSection = styled.section`
  width: 100%;
  margin: 32px 0 67px;

  ${media.desktop} {
    margin: 94px 0 54px;
  }
`;

export const ShareContainer = styled.div`
  position: absolute;
  top: 22%;
  right: 0;
  left: 0;
  margin: auto;
  transform: scale(0.45);

  width: fit-content;

  ${media.custom(260)} {
    top: 25%;
    transform: scale(0.55);
  }

  ${media.custom(344)} {
    top: 27%;
  }

  ${media.custom(390)} {
    top: 29%;
    transform: scale(0.8);
  }

  ${media.custom(450)} {
    top: 30%;
    transform: scale(1);
  }

  ${media.custom(540)} {
    top: 32%;
  }

  ${media.tablet} {
    top: 34%;
  }

  ${media.custom(790)} {
    top: 35%;
    transform: scale(1.2);
  }

  ${media.desktop} {
    top: 55%;
    left: unset;
    right: calc(63vw * 1.08);
    transform: scale(1);
  }

  ${media.custom(1130)} {
    right: calc(63vw * 1.08);
  }

  ${media.custom(1270)} {
    right: calc(63vw * 1.07);
  }

  ${media.desktopLarge} {
    right: calc(63vw * 1.06);
  }

  ${media.custom(1430)} {
    right: calc(63vw * 1.1);
  }

  ${media.custom(1800)} {
    right: calc(63vw * 1.12);
  }

  ${media.custom(2100)} {
    right: calc(63vw * 1.13);
  }
`;

export const WriteUpsShareContainer = styled.div`
  ${ShareContainerStyles}

  ${media.desktop} {
    transform: scale(1);
  }
`;

export const GenresShareContainer = styled.div`
  ${ShareContainerStyles}

  ${media.desktop} {
    top: 52%;
    left: unset;
    right: 66vw;
    transform: scale(1);
  }

  ${media.custom(1145)} {
    right: calc(63vw * 1.05);
  }

  ${media.desktopLarge} {
    top: 55%;
    transform: scale(1);
  }

  ${media.custom(1420)} {
    right: calc(63vw * 1.06);
  }

  ${media.custom(1620)} {
    right: calc(63vw * 1.08);
  }

  ${media.custom(1920)} {
    right: calc(63vw * 1.09);
  }

  ${media.custom(2000)} {
    right: calc(63vw * 1.105);
  }

  ${media.custom(2400)} {
    right: calc(63vw * 1.12);
  }
`;
