import React, { FC } from 'react';
import LottiePlayer, { LottieProps } from 'react-lottie-player';

type PreserveAspectRatioVariant =
  | 'none'
  | 'xMinYMin meet'
  | 'xMinYMid meet'
  | 'xMinYMax meet'
  | 'xMidYMin meet'
  | 'xMidYMid meet'
  | 'xMidYMax meet'
  | 'xMaxYMin meet'
  | 'xMaxYMid meet'
  | 'xMaxYMax meet'
  | 'xMinYMin slice'
  | 'xMinYMid slice'
  | 'xMinYMax slice'
  | 'xMidYMin slice'
  | 'xMidYMid slice'
  | 'xMidYMax slice'
  | 'xMaxYMin slice'
  | 'xMaxYMid slice'
  | 'xMaxYMax slice';

export interface LottieWrapper {
  preserveAspectRatio?: PreserveAspectRatioVariant;
}

const Lottie: FC<LottieWrapper & LottieProps> = ({
  preserveAspectRatio = 'xMidYMid slice',
  ...rest
}) => {
  return (
    <LottiePlayer
      {...rest}
      rendererSettings={{ preserveAspectRatio: preserveAspectRatio }}
    />
  );
};
export default Lottie;
